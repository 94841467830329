import add from '../assets/add.svg'
import arrowBackIos from '../assets/arrow_back_ios_new.svg'
import arrowForward from '../assets/arrow_forward_ios.svg'
import box from '../assets/box.svg'
import clock from '../assets/clock.svg'
import close from '../assets/close.svg'
import filterAlt from '../assets/filter_alt.svg'
import filterList from '../assets/filter_list.svg'
import moreHoriz from '../assets/more_horiz.svg'
import rocket from '../assets/rocket.svg'
import search from '../assets/search.svg'
import truck from '../assets/truck.svg'
import visibility from '../assets/visibility.svg'
import defaultAvatar from '../assets/Avatar_standard.svg'
import info from '../assets/info.svg'
import error404 from '../assets/image_404.svg'
import union from '../assets/Union.svg'
import download from '../assets/download.svg'
import route from '../assets/route.svg'
import check from '../assets/check.svg'
import deleteImg from '../assets/delete.svg'
import edit from '../assets/edit.svg'
import warning from '../assets/warning.svg'
import userCheck from '../assets/userCheck.svg'
import whiteCheck from '../assets/whiteCheck.svg'
import evidence from '../assets/evidence.svg'
import arrowBack from '../assets/arrow_back.svg'
import expand from '../assets/expand_more.svg'
import tune from '../assets/tune.svg'
import home from '../assets/home.svg'
import ml from '../assets/mercadolibre.svg'
import woo from '../assets/woo.svg'
import shopify from '../assets/shopify.svg'
import falabella from '../assets/falabella.svg'
import prestashop from '../assets/prestashop.svg'
import qrcode from '../assets/qr_code.svg'
import manageAccount from '../assets/manage_accounts.svg'
import account_circle from '../assets/account_circle.svg'
import auto_fix from '../assets/auto_fix.svg'
import logo from '../assets/OTS_logo.png'
import logoBeartrack from '../assets/beartrack/beartrack_logo.png'
import apps from '../assets/beartrack/apps.svg'
import lock from '../assets/lock.svg'
import unlock from '../assets/lock_open.svg'
import redclose from '../assets/redclose.svg'
import sendu from '../assets/sendu.png'

export const iconList = {
    add: add,
    arrowBackIos: arrowBackIos,
    arrowForward: arrowForward,
    box: box,
    clock: clock,
    close: close,
    filterAlt: filterAlt,
    filterList: filterList,
    moreHoriz: moreHoriz,
    rocket: rocket,
    search: search,
    truck: truck,
    visibility: visibility,
    defaultAvatar: defaultAvatar,
    info: info,
    error404: error404,
    actions: union,
    download: download,
    route: route,
    check: check,
    delete: deleteImg,
    edit: edit,
    warning: warning,
    userCheck: userCheck,
    whiteCheck: whiteCheck,
    evidence: evidence,
    arrowBack: arrowBack,
    expand: expand,
    tune: tune,
    home: home,
    ml: ml,
    woo: woo,
    shopify: shopify,
    falabella: falabella,
    prestashop: prestashop,
    qrcode: qrcode,
    manageAccount: manageAccount,
    account_circle: account_circle,
    auto_fix: auto_fix,
    logo: logo,
    logoBeartrack: logoBeartrack,
    apps: apps,
    lock: lock,
    unlock: unlock,
    redclose: redclose,
    sendu
}
import Axios from "../config/axios-config";

export const getActiveFaqs = async () => {
  const faqs = await Axios.get("get-active-faqs").catch((err) => {
    return { error: err.response.data };
  });

  return faqs;
};

export const getAllFaqs = async () => {
  const faqs = await Axios.get("get-all-faqs").catch((err) => {
    return { error: err.response.data };
  });

  return faqs;
};

export const changeFaqStatus = async ({ id, status }) => {
  const faqs = await Axios.put(`status-faq/${id}`, { status }).catch((err) => {
    return { error: err.response.data };
  });

  return faqs;
};

export const editFaq = async ({ values }) => {
  // console.log(values);
  const editedFaq = await Axios.put(`edit-faq/${values.id}`, { ...values }).catch((err) => {
    return { error: err.response.data };
  });

  return editedFaq;
};

export const createFaq = async ({ values }) => {
  const faqs = await Axios.post("create-faq", { ...values }).catch((err) => {
    return { error: err.response.data };
  });

  return faqs;
};

export const deleteFaqApi = async ({ id }) => {
  const faq = await Axios.delete(`delete-faq/${id}`).catch((err) => {
    return { error: err.response.data };
  });

  return faq;
};

import axios from "axios";

const AxiosV2 = axios.create({
  baseURL: process.env.REACT_APP_API_V2_CONNECTION,
  timeout: 30000,
  mode: "no-cors",
  headers: {
    "Access-Control-Allow-Origin": "*"
  },
});

AxiosV2.defaults.headers.common["content-type"] = "application/json";
AxiosV2.defaults.headers.common["application"] = "web";
AxiosV2.defaults.headers.common["authorization"] = `Bearer ${localStorage.getItem("accessToken")}`

export default AxiosV2;

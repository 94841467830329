import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle,
  Button,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Links from "../links";
import Logo from "../../assets/Logo.svg";
import ArrowDown from "../../assets/arrow_forward_ios.svg";
import Divider from "../divider";
import useAuth from "../../hooks/useAuth";
import { iconList } from "../../utils/iconList";
import { logout } from "../../api/token";
import { getUser } from "../../api/user";
import { useSelectedItem } from "./SelectedItemContext";

export default function Menu({ fetch }) {
  const { selectedItem } = useSelectedItem();
  const { user } = useAuth();
  const { pathname } = useLocation();
  const [userData, setUserData] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const toggle = () => setShowDropdown((prevState) => !prevState);

  const logOut = () => {
    logout();
    navigate("/login");
  };

  // DvlprChris
  const [config, setConfig] = useState(null);
  const [storedColor, setStoredColor] = useState(null);
  useEffect(() => {
    getUser({ id: user.id }).then((res) => {
      console.log(res.data.user);
      setUserData(res.data.user);
    });

    try {
      const cachedConfig = localStorage.getItem("appConfig");
      // console.log("cachedConfig admin: ", cachedConfig);
      setConfig(JSON.parse(cachedConfig));
      //obtener color de la API
      const configObject = JSON.parse(cachedConfig);
      const colorFromAPI = String(configObject.color);
      setStoredColor(colorFromAPI);
    } catch (error) {
      console.error(
        "Error al obtener la configuración del localStorage:",
        error
      );
    }
  }, []);

  //mascara SVG
  const UserAvatar = ({ storedColor }) => {
    return (
      <div className="layout-image">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 46 46"
          fill="none"
          width="46"
          height="46"
        >
          <g>
            {/* contenido del SVG defaultAvatar */}
            <rect
              x="0.5"
              y="0.5"
              width="45"
              height="45"
              rx="22.5"
              fill="#F9F5FF"
            />
            <path
              d="M30.6666 31.625V29.7083C30.6666 28.6917 30.2627 27.7166 29.5438 26.9978C28.8249 26.2789 27.8499 25.875 26.8333 25.875H19.1666C18.1499 25.875 17.1749 26.2789 16.456 26.9978C15.7371 27.7166 15.3333 28.6917 15.3333 29.7083V31.625M26.8333 18.2083C26.8333 20.3254 25.117 22.0417 22.9999 22.0417C20.8828 22.0417 19.1666 20.3254 19.1666 18.2083C19.1666 16.0912 20.8828 14.375 22.9999 14.375C25.117 14.375 26.8333 16.0912 26.8333 18.2083Z"
              stroke={storedColor}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="0.5"
              y="0.5"
              width="45"
              height="45"
              rx="22.5"
              stroke="#CCCCCC"
            />
          </g>
        </svg>
      </div>
    );
  };
  //mascara SVG

  return (
    <div className="layoutContainer">
      {/* <img src={Logo} alt="logo" className="layoutContainer__mainLogo" /> */}
      <img
        src={config?.logo} // Usar logo por defecto si no hay respuesta
        alt="logo"
        className="layoutContainer__mainLogo"
        type="image/svg+xml"
      />
      {/* aca neuvo codigo - dvlprchris */}
      {selectedItem === "envios" && (
        <>
          {/* Contenido que se mostrará solo cuando selectedItem sea "envios" */}
          <div className="layoutContainer__dividers">
            <div className="layoutContainer__otsTitle"></div>
            <Divider />
          </div>
          <div className="layoutContainer__titles">
            <span style={{ display: "flex", alignItems: "start" }}>
              <span
                className="material-symbols-outlined text-sm"
                style={{
                  paddingLeft: "0",
                  marginRight: "5px",
                  fontSize: "",
                  // color: "#F8612C",
                  color: "black",
                }}
              >
                package_2
              </span>
              <span
                style={{ fontSize: "" }}
                className="text-black font-bold text-sm"
              >
                PEDIDOS
              </span>
            </span>
          </div>
          <div className="layoutContainer__linkContainer">
            <Links
              content="Resumen"
              className="layoutContainer__linkContainer__link"
              selected={pathname === "/admin"}
              navigating="/admin"
            />
            {/* <Links
              content="Todos los pedidos"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/home")}
              navigating="/home"
            /> */}
          </div>
          <div className="layoutContainer__dividers">
            <Divider />
          </div>
          <div className="layoutContainer__titles">
            <span style={{ display: "flex", alignItems: "start" }}>
              <span
                className="material-symbols-outlined text-sm"
                style={{
                  paddingLeft: "0",
                  marginRight: "5px",
                  fontSize: "",
                  // color: "#F8612C",
                  color: "black",
                }}
              >
                description
              </span>
              <span
                style={{ fontSize: "" }}
                className="text-black font-bold text-sm"
              >
                INFORMES
              </span>
            </span>
          </div>
          <div className="layoutContainer__linkContainer">
            <Links
              content="Crear informe"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/inform")}
              navigating="/admin/inform"
            />
            {/* <Links
              content="Ver estadísticas"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/routssses")}
              navigating="/admin/routssses"
            /> */}
          </div>
          <Divider />
            <div className="layoutContainer__titles">
              <span style={{ display: "flex", alignItems: "start" }}>
                <span
                  className="material-symbols-outlined text-sm"
                  style={{
                    paddingLeft: "0",
                    marginRight: "5px",
                    fontSize: "",
                    // color: "#F8612C",
                    color: "black",
                  }}
                >
                  route
                </span>
                <span
                  style={{ fontSize: "" }}
                  className="text-black font-bold text-sm"
                >
                  RETIROS
                </span>
              </span>
            </div>

            <div className="layoutContainer__linkContainer">
               <Links
                content="Administrar retiros"
                className="layoutContainer__linkContainer__link"
                selected={pathname.includes("/admin/adminWithdrawals")}
                navigating="/admin/adminWithdrawals"
              />
              <Links
                content="Retiros realizados"
                className="layoutContainer__linkContainer__link"
                selected={pathname.includes("/admin/pickups")}
                navigating="/admin/pickups"
              />
            </div>
          <Divider />
          <div className="layoutContainer__titles">
            <span style={{ display: "flex", alignItems: "start" }}>
              <span
                className="material-symbols-outlined text-sm"
                style={{
                  paddingLeft: "0",
                  marginRight: "5px",
                  fontSize: "",
                  // color: "#F8612C",
                  color: "black",
                }}
              >
                add
              </span>
              <span
                style={{ fontSize: "" }}
                className="text-black font-bold text-sm"
              >
                ADICIONALES
              </span>
            </span>
          </div>

          <div className="layoutContainer__linkContainer">
          {/* <Links
              content="Integraciones"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/integrations")}
              navigating="/home/integrations"
            /> */}
             <Links
              content="Preguntas frecuentes"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/faqs")}
              navigating="/admin/faqs"
            />
          </div>

        </>
      )}
      {selectedItem === "usuarios_flota" && (
        <>
          {/* Contenido que se mostrará solo cuando selectedItem sea "envios" */}
          <div className="layoutContainer__dividers">
            <div className="layoutContainer__otsTitle"></div>
            <Divider />
          </div>
          <div className="layoutContainer__titles">
            <span style={{ display: "flex", alignItems: "start" }}>
              <span
                className="material-symbols-outlined text-sm"
                style={{
                  paddingLeft: "0",
                  marginRight: "5px",
                  fontSize: "",
                  // color: "#F8612C",
                  color: "black",
                }}
              >
                person
              </span>
              <span
                style={{ fontSize: "" }}
                className="text-black font-bold text-sm"
              >
                USUARIOS
              </span>
            </span>
          </div>
          <div className="layoutContainer__linkContainer">
            <Links
              content="Usuarios activos"
              className="layoutContainer__linkContainer__link"
              selected={pathname === "/admin/users"}
              navigating="/admin/users"
            />
            <Links
              content="Usuarios inactivos"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/lock")}
              navigating="/admin/lock"
            />
            <Links
              content="Usuarios pendientes"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/pendent")}
              navigating="/admin/pendent"
            />
          </div>
          <div className="layoutContainer__dividers">
            <Divider />
          </div>
          <div className="layoutContainer__titles">
            <span style={{ display: "flex", alignItems: "start" }}>
              <span
                className="material-symbols-outlined text-sm"
                style={{
                  paddingLeft: "0",
                  marginRight: "5px",
                  fontSize: "",
                  // color: "#F8612C",
                  color: "black",
                }}
              >
                local_shipping
              </span>
              <span
                style={{ fontSize: "" }}
                className="text-black font-bold text-sm"
              >
                FLOTA
              </span>
            </span>
          </div>
          <div className="layoutContainer__linkContainer">
            <Links
              content="Listado de vehículos"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/inform")}
              navigating="/admin/inform"
            />
          </div>
   
        </>
      )}
      {selectedItem === "rutas" && (
        <>
          {/* Contenido que se mostrará solo cuando selectedItem sea "rutas" */}
          <div className="layoutContainer__dividers">
            <div className="layoutContainer__otsTitle"></div>
            <Divider />
          </div>
          <div className="layoutContainer__titles">
            <span style={{ display: "flex", alignItems: "start" }}>
              <span
                className="material-symbols-outlined text-sm"
                style={{
                  paddingLeft: "0",
                  marginRight: "5px",
                  fontSize: "",
                  // color: "#F8612C",
                  color: "black",
                }}
              >
                map
              </span>
              <span
                style={{ fontSize: "" }}
                className="text-black font-bold text-sm"
              >
                RUTAS
              </span>
            </span>
          </div>
          <div className="layoutContainer__linkContainer">
            <Links
              content="Crear rutas"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/createRoute")}
              navigating="/admin/createRoute"
            />
            <Links
              content="Administrar flota"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/adminFleet")}
              navigating="/admin/adminFleet"
            />
            <Links
              content="Administrar rutas"
              className="layoutContainer__linkContainer__link"
              selected={pathname.includes("/admin/adminRoute")}
              navigating="/admin/adminRoute"
            />
          </div>
       
        </>
      )}
      {/* aca neuvo codigo - dvlprchris */}
      {/* <div className="layoutContainer__dividers">
        <div className="layoutContainer__otsTitle">
          {user?.businessName
            ? user.businessName
            : `${user?.name} ${user?.lastName}`}
        </div>
        <Divider />
      </div>
      <div className="layoutContainer__titles">PEDIDOS</div>
      <div className="layoutContainer__linkContainer">
        <Links
          content="Resumen"
          className="layoutContainer__linkContainer__link"
          selected={pathname === "/admin"}
          navigating="/admin"
        />
      </div>
      <div className="layoutContainer__dividers">
        <Divider />
      </div>
      <div className="layoutContainer__titles">INFORMES</div>
      <div className="layoutContainer__linkContainer">
        <Links
          content="Crear informe"
          className="layoutContainer__linkContainer__link"
          selected={pathname.includes("/admin/inform")}
          navigating="/admin/inform"
        />
      </div>
      <Divider />
      <div className="layoutContainer__titles">USUARIOS</div>
      <div className="layoutContainer__linkContainer">
        <Links
          content="Listado de usuarios"
          className="layoutContainer__linkContainer__link"
          selected={pathname.includes("/admin/users")}
          navigating="/admin/users"
        />
        <Links
          content="Usuarios inactivos"
          className="layoutContainer__linkContainer__link"
          selected={pathname.includes("/admin/lock")}
          navigating="/admin/lock"
        />
      </div>
      <Divider /> */}
      
      {/* dvlprchris - 2024 */}
      {/* path: "/createRoute"
      path: "/adminFleet"
      path: "/adminRoute" */}
      {/* <div className="layoutContainer__titles">RUTAS y FLOTA</div>
      <div className="layoutContainer__linkContainer">
        <Links
          content="Crear rutas"
          className="layoutContainer__linkContainer__link"
          selected={pathname.includes("/admin/createRoute")}
          navigating="/admin/createRoute"
        />
        <Links
          content="Administrar flota"
          className="layoutContainer__linkContainer__link"
          selected={pathname.includes("/admin/adminFleet")}
          navigating="/admin/adminFleet"
        />
        <Links
          content="Administrar rutas"
          className="layoutContainer__linkContainer__link"
          selected={pathname.includes("/admin/adminRoute")}
          navigating="/admin/adminRoute"
        />
      </div>
      <Divider /> */}
      {/* dvlprchris - 2024 */}

      {/* <div className="layoutContainer__titles">Retiros</div>
      <div className="layoutContainer__linkContainer">
        <Links
          content="Administrar rutas"
          className="layoutContainer__linkContainer__link"
          selected={pathname.includes("/admin/routes")}
          navigating="/admin/routes"
        />
      </div>
      <div className="layoutContainer__linkContainer">
        <Links
          content="Retiros realizados"
          className="layoutContainer__linkContainer__link"
          selected={pathname.includes("/admin/pickups")}
          navigating="/admin/pickups"
        />
      </div>
      <Divider /> */}
      {/* <div className="layoutContainer__titles">PREGUNTAS</div>
      <div className="layoutContainer__linkContainer">
        <Links
          content="Preguntas frecuentes"
          className="layoutContainer__linkContainer__link"
          selected={pathname.includes("/admin/faqs")}
          navigating="/admin/faqs"
        />
      </div> */}
      {/* comentar para pruebas - ksandoval */}
      {/* <div className="d-flex align-items-center justify-content-center">
           <img
          src={iconList["defaultAvatar"]}
          className="layout-image"
          alt="userImg"
        />
        <UserAvatar storedColor={storedColor} className="layout-image" alt="customUserImg" />
        <Dropdown
          isOpen={showDropdown}
          toggle={toggle}
          direction="down"
          className="layout-image-arrow d-flex align-items-center"
        >
          <DropdownToggle>
            <img src={ArrowDown} alt="arrowDown" className="image-arrow" />
          </DropdownToggle>
          <DropdownMenu className="custom-dropdown">
            <DropdownItem className="dropdown-layout-text mb-1">
              <div className="flex items-center gap-1">
                <img
                  src={iconList["info"]}
                  alt="info"
                  className="layout-icon mr-2"
                />{" "}
                Ver mi cuenta
              </div>
            </DropdownItem>
            <Divider />
            <DropdownItem
              className="dropdown-layout-text mt-1"
              onClick={() => logOut()}
            >
              <div className="flex items-center gap-1">
                <img
                  src={iconList["close"]}
                  alt="close"
                  className="layout-icon mr-2"
                />{" "}
                Cerrar sesión
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Button
          block
          color="white"
          className="bg-orange-500"
          onClick={logOut}
        >
          Cerrar sesión
        </Button>
        
      </div> */}

      <div className="custom-layout-button">
        {/* <Button
          block
          color="dark"
          className="bg-black"
          onClick={() => setOpenModal(true)}
        >
          Crear pedido
        </Button> */}
        <Divider></Divider>
        <div className="d-flex align-items-center justify-content-center mt-2">
          <span
            className="material-symbols-outlined"
            style={{
              border: "none",
              marginRight: "0",
              fontSize: "2rem",
              color: "black",
              backgroundColor: "transparent !important",
            }}
          >
            account_circle
          </span>
          <div
            className=""
            style={{
              border: "none",
              margin: "0",
              paddingLeft: "5px",
              fontSize: "10px",
              color: "black",
              backgroundColor: "transparent !important",
            }}
          >
            <h1 className="font-bold">{userData.businessName}</h1>
            <p>{userData.email}</p>
            {/* <h1 className="font-bold">GAMU SPA</h1>
            <p>gamuchilespa@gmail.com</p> */}
          </div>
          <Dropdown
            isOpen={showDropdown}
            toggle={toggle}
            direction="down"
            className=""
          >
            <DropdownToggle className="border-none">
              <span
                className="material-symbols-outlined"
                style={{
                  border: "none",
                  marginRight: "0",
                  fontSize: "1rem",
                  color: "black",
                  backgroundColor: "transparent !important",
                }}
              >
                chevron_right
              </span>
            </DropdownToggle>

            <DropdownMenu className="">
              <DropdownItem
                className="dropdown-layout-text mb-1"
                onClick={() => navigate("/home/my-account")}
              >
                <div className="flex items-center gap-1">
                  <img
                    src={iconList["info"]}
                    alt="info"
                    className="layout-icon mr-2"
                  />{" "}
                  Ver mi cuenta
                </div>
              </DropdownItem>
              <Divider />
              <DropdownItem
                className="dropdown-layout-text mt-1"
                onClick={() => logOut()}
              >
                <div className="flex items-center gap-1">
                  <img
                    src={iconList["close"]}
                    alt="close"
                    className="layout-icon mr-2"
                  />{" "}
                  Cerrar sesión
                </div>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Container, Button } from "reactstrap";
import AddFile from "../../assets/addFile.svg";
import Template from "../../template/template.xlsx";
import useAuth from "../../hooks/useAuth";
import { getUser } from "../../api/user";
import { loadFile } from "../../api/packages";
import { showToast } from "../../utils/showToast";
import { toast } from "react-toastify";
import FileInput from "../fileInput";

export default function LoadFile({
  closeModal,
  fetch,
  setConfirmationModal,
  setPackagesCreated,
}) {
  const [name, setName] = useState();
  const [fileData, setFileData] = useState();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const {
    user: { id },
  } = useAuth();

  const onSelectDocument = (file) => {
    setName(file[0].name);
    setFileData(file[0]);
  };

  const onSendForm = async () => {
    setLoading(true);
    const reader = new FileReader();
    reader.onload = async function (evt) {
      const parsedDocument = evt.target.result;
      try {
        const { data, error } = await loadFile({
          file: parsedDocument,
          user: userData,
        });

        console.log(error);

        if (data) {
          closeModal(false);
          setConfirmationModal(true);
          setPackagesCreated(data?.packages);
          fetch(true);
        }

        if (error.uncompletedCells) {
          setLoading(false);

          toast.info(
            `Faltan por completar los siguiente campos: ${error.uncompletedCells.join(
              ", "
            )}`,
            { autoClose: 10000 }
          );

          return;
        }

        if (error) {
          toast.error("Error al cargar los paquetes.");
          closeModal(false);
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    reader.readAsBinaryString(fileData);
  };

  useEffect(() => {
    getUser({ id })
      .then(({ data: { user } }) => setUserData(user))
      .catch((err) => console.error(`This error ${err}`));
  }, [id]);

  return (
    <Container className="mt-3 mb-3 w-100 d-grid justify-content-center justify-items-center">
      <FileInput onSelectDocument={onSelectDocument} />
      <div className="d-flex justify-content-center mt-3">
        <Button
          color="dark"
          className="custom-modal-btn bg-black"
          disabled={!name ? true : loading ? true : false}
          onClick={() => onSendForm()}
        >
          {name || "Sin documento"}
        </Button>
      </div>
      <a
        href={Template}
        className="custom-link-modal"
        download={"template.xlsx"}
      >
        Descargar plantilla
      </a>
    </Container>
  );
}

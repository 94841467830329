import Axios from "../config/axios-config";

export const downloadPdf = async ({ id, packages }) => {
    const downloadPdf = await Axios.post('pendingPackagesPdf', {
        clientId: id, index: 0, packages: packages
    }).catch((err) => {
        return { error: err.response.data };
    })

    return downloadPdf
}
import { useEffect, useState } from "react";
import { Badge } from "reactstrap";
import { useNavigate } from "react-router-dom";

export default function Link({ content, selected, className, navigating }) {
  // DvlprChris
  const [config, setConfig] = useState(null);
  const [storedColor, setStoredColor] = useState(null);

  useEffect(() => {
    try {
      const cachedConfig = localStorage.getItem("appConfig");
      setConfig(JSON.parse(cachedConfig));
      //obtener color de la API
      const configObject = JSON.parse(cachedConfig);
      const colorFromAPI = String(configObject.color);
      setStoredColor(colorFromAPI);

    } catch (error) {
      console.error(
        "Error al obtener la configuración del localStorage:",
        error
      );
    }
  }, []);
    // DvlprChris

  const navigate = useNavigate();
  const goTo = () => {
    navigate(navigating);
  };



  return (
    <Badge
      style={{ '--color-dinamico': storedColor }}
      className={`custom-link-style ${
        selected && "custom-link-selected"
      } ${className}`}
      onClick={goTo}
    >
      {content}
    </Badge>
  );
}

/* eslint-disable */
import React, { useCallback, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import jwtDecode from "jwt-decode";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { recoveryPassword } from "../../api/auth";
import { showToast } from "../../utils/showToast";
import { iconList } from "../../utils/iconList";
import { getConfig } from "../../api/configService";

export default function Login() {
  const methods = useForm({ mode: "onSubmit" });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const submitValues = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const { data, error } = await recoveryPassword(values);
        if (error) {
          showToast(error.message, false);
          return;
        }
        if (data.message === "Error: User not found") {
          showToast("El correo no está registrado.", false);
        }
        if (data.message === "Password reset request received") {
          showToast(
            "Te enviamos un correo electrónico, revisalo para continuar.",
            true
          );
        }
      } catch (error) {
        showToast(error, false);
      } finally {
        setLoading(false);
      }
    },
    [navigate]
  );

  // DvlprChris
  const [config, setConfig] = useState(null);
  const [storedColor, setStoredColor] = useState(null);
  const [configLoaded, setConfigLoaded] = useState(false);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
  
        const serviceConfig = await getConfig();
        console.log("serviceConfig from API: ",serviceConfig );
        //console.log("color from API: ",serviceConfig.color );
        setConfig(serviceConfig);
        setStoredColor(serviceConfig.color);
        localStorage.setItem("appConfig", JSON.stringify(serviceConfig));
        // console.log("Contenido del localStorage :", localStorage);
        setConfigLoaded(true);

      } catch (error) {
        console.log(error)
      }
    }
    fetchConfig();
  }, []);

  // if (!configLoaded) {
  //   return <div>Loading...</div>;
  // }
  
  // useEffect(() => {
  //   const fetchConfig = async () => {
  //     try {
  //       console.log("localStorage al inicio de forgotPassword:", localStorage);

  //       const localCachedConfig = localStorage.getItem("appConfig");

  //       if (localCachedConfig) {
  //         // Si hay configuración en el almacenamiento local, utiliza esa configuración.

  //         const configData = JSON.parse(localCachedConfig);
  //         console.log("console.log(configData): ", configData);
  //         const serviceConfig = await getConfig();
  //         console.log(
  //           "Se obtienen datos del servicio serviceConfig:",
  //           serviceConfig
  //         );

  //         const json1 = JSON.stringify(configData);
  //         const json2 = JSON.stringify(serviceConfig);

  //         if (json1 === json2) {
  //           console.log("Las constantes son iguales.");
  //           setConfig(configData);
  //         } else {
  //           console.log("Las constantes son diferentes.");
  //           setConfig(serviceConfig);
  //           console.log("console.log(config)2: ", config);
  //           localStorage.setItem("appConfig", JSON.stringify(serviceConfig));
  //         }
  //       } else {
  //         console.log("No hay configuración en el almacenamiento local");
  //         // Si no hay configuración en el almacenamiento local, obtén la configuración del servicio.
  //         const configData = await getConfig();
  //         console.log(
  //           "ELSE: Se obtienen datos del servicio configData: ",
  //           configData
  //         );
  //         setConfig(configData);
  //         console.log("console.log(config)3: ", config);
  //         // Almacena la configuración en el almacenamiento local para futuras consultas.
  //         localStorage.setItem("appConfig", JSON.stringify(configData));
  //       }

  //       const colorFromAPI = config?.color || ""; // Si config es nulo, asigna una cadena vacía
  //       setStoredColor(colorFromAPI);
  //     } catch (error) {
  //       console.error("Error al obtener la configuración:", error);
  //     }
  //   };

  //   fetchConfig();
  // }, []);
  // DvlprChris

  return (
    <Container className="vh-100">
      <Row
        className="d-flex justify-content-center align-items-center vh-100"
        style={{ overflow: "hidden" }}
      >
        <Col xs="6" md="6" xl="6">
          <div className="login-card-style mt-5">
            <div className="w-100 d-flex justify-content-center">
              <Card className="m-0 login-card-over">
                <CardBody className="p-4">
                  <a
                    href="#"
                    className="d-flex justify-content-center mt-3 mb-4"
                  >
                    <img
                      src={config?.logo}
                      alt="logo"
                      style={{ width: "70%" }}
                    />
                  </a>

                  <h3 className="mb-3 h3-custom-size">
                    Bienvenido a BearTrack!
                  </h3>
                  <h3 className="mb-3 login-custom-size">
                    Perdiste el accesso a tu cuenta? Recuperalo!
                  </h3>
                  <Form onSubmit={methods.handleSubmit(submitValues)}>
                    <FormGroup>
                      <div>
                        <Label className="mt-3">Email</Label>
                        <Controller
                          name="email"
                          control={methods.control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="email"
                              className="p-2 px-3"
                            />
                          )}
                          rules={{ required: "Ingrese correo" }}
                        />
                        {methods?.formState?.errors?.email && (
                          <Label className="text-danger">
                            * {methods?.formState?.errors?.email?.message}
                          </Label>
                        )}
                      </div>
                    </FormGroup>
                    <div className="w-100">
                      {loading ? (
                        <Button className="login-btn" block>
                          <Spinner size="sm" />
                        </Button>
                      ) : (
                        <Button
                          style={{
                            color:"black",
                            backgroundColor: storedColor,
                            borderColor: storedColor,
                          }}
                          block
                        >
                          Recuperar cuenta
                        </Button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

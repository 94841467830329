import React, { useState, useEffect } from "react";
import {
  Table,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Col,
  Input,
} from "reactstrap";
import Pagination from "../pagination";
import Spinner from "../spinner";
import { iconList } from "../../utils/iconList";
import Divider from "../../components/divider";
import Modal from "../modal";
import CreatePackage from "../createPackage";
import DownloadVoucher from "../downloadVoucher";
import CancelPackage from "../cancelPackage";
import FilterModal from "../filterModal";
import useAuth from "../../hooks/useAuth";
import ReasignModal from "../reasignModal";
import ChangeStatusModal from "../changeStatusModal";
import { downloadPdf } from "../../api/downloadPdf";
import { multipleDownloadZPLFile } from "../../utils/downloadZebra";
import { showToast } from "../../utils/showToast";

export default function CustomTable({
  title,
  headers,
  data,
  actions,
  totalDocs,
  page,
  completeData,
  updateData,
  docsPerPage,
  loadingTable,
  pageSelected,
  setViewEvidence,
  setPackageEvidence,
  filters,
  setFilters,
  statusDisabled,
  admin,
}) {
  const { user } = useAuth();
  const [showActions, setShowActions] = useState([]);
  const [checkedPackage, setCheckedPackage] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [detailModal, setDetailModal] = useState(false);
  const [voucherModal, setVoucherModal] = useState(false);
  const [cancelPackage, setCancelPackage] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [assignDelivery, setAssignDelivery] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const filtersCount = Object.keys(filters).length;

  //console.log(totalDocs)

  useEffect(() => {
    const actionsData = data.map((el) => {
      return { ...el, actions: false };
    });
    setShowActions(actionsData);
  }, [data]);

  const toggle = (value) => {
    const applyChanges = showActions?.map((el) => {
      if (el.correlative === value?.correlative)
        return { ...el, actions: !el.actions };
      return { ...el };
    });
    setShowActions(applyChanges);
  };

  const addingPackage = (value) => {
    const packageToAdd = completeData?.find(
      (f) => f.followingNumber === value.correlative?.comparative
    );
    const alreadySelected = checkedPackage?.find(
      (f) => f.followingNumber === packageToAdd.followingNumber
    );

    if (!alreadySelected)
      setCheckedPackage((oldArr) => [...oldArr, packageToAdd]);

    if (alreadySelected) {
      const newSelected = checkedPackage?.filter(
        (f) => f.followingNumber !== packageToAdd.followingNumber
      );
      setCheckedPackage(newSelected);
    }
  };

  const addAllPackges = (checked) => {
    if (checked) setCheckedPackage(completeData);
    if (!checked) setCheckedPackage([]);
  };

  const downloadPdfZebra = async () => {
    if (checkedPackage.length > 0) {
      const { data, error } = await downloadPdf({
        id: user?.id,
        packages: checkedPackage,
      });

      if (data) {
        const element = document.createElement("a");
        const zplFinal = await multipleDownloadZPLFile({
          packages: checkedPackage,
        });

        element.setAttribute(
          "href",
          `data:text/plain;charset=utf-8,${encodeURIComponent(zplFinal)}`
        );
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, "0");
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const year = currentDate.getFullYear();
        const hours = String(currentDate.getHours()).padStart(2, "0");
        const minutes = String(currentDate.getMinutes()).padStart(2, "0");

        const fileName = `EtiquetasOTS-${day}-${month}-${year}-${hours}${minutes}.zpl`;
        element.setAttribute("download", fileName);

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
        window.open(data?.url);
      }

      if (error) {
        showToast("Error al generar PDF", false);
      }
    }

    if (checkedPackage.length === 0)
      showToast("Debe seleccionar al menos 1 paquete.", false);
  };

  const tableContainer = document.getElementById("table-container");

  useEffect(() => {
    setCheckedPackage([]);
    setAllChecked(false);
  }, [pageSelected]);

  return (
    <div className="table-container" id="table-container">
      {loadingTable && (
        <div
          className="d-flex align-items-center justify-content-center position-absolute"
          style={{
            width: tableContainer?.clientWidth,
            height: tableContainer?.clientHeight - 80,
          }}
        >
          <Spinner />
          <div
            className="d-flex custom-loading-table-per-page"
            style={{
              width: tableContainer?.clientWidth,
              height: tableContainer?.clientHeight - 80,
            }}
          />
        </div>
      )}
      <Modal
        open={detailModal}
        setOpen={() => setDetailModal()}
        body={
          <CreatePackage
            edit
            defaultData={completeData?.find(
              (f) =>
                f.followingNumber === selectedPackage.correlative?.comparative
            )}
          />
        }
      />
      <Modal
        open={voucherModal}
        setOpen={() => setVoucherModal()}
        body={
          <DownloadVoucher
            defaultData={completeData?.find(
              (f) =>
                f.followingNumber === selectedPackage.correlative?.comparative
            )}
          />
        }
      />
      <Modal
        open={cancelPackage}
        setOpen={() => setCancelPackage()}
        centered
        size={"md"}
        body={
          <CancelPackage
            defaultData={completeData?.find(
              (f) =>
                f.followingNumber === selectedPackage.correlative?.comparative
            )}
            closeModal={setCancelPackage}
            updateData={updateData}
          />
        }
      />
      <Modal
        open={openFilters}
        setOpen={() => setOpenFilters()}
        bodyClassName="p-0"
        body={
          <FilterModal
            open={openFilters}
            activeFilters={filters}
            callback={setFilters}
            closeModal={setOpenFilters}
            statusDisabled={statusDisabled}
            admin={admin}
          />
        }
      />
      <Modal
        open={assignDelivery}
        setOpen={() => setAssignDelivery()}
        size="md"
        bodyClassName="p-0"
        body={
          <ReasignModal
            data={completeData?.find(
              (f) =>
                f.followingNumber === selectedPackage.correlative?.comparative
            )}
            closeModal={setAssignDelivery}
            updateData={updateData}
          />
        }
      />
      <Modal
        open={changeStatusModal}
        setOpen={() => setChangeStatusModal()}
        size="md"
        bodyClassName="p-0"
        body={
          <ChangeStatusModal
            data={completeData?.find(
              (f) =>
                f.followingNumber === selectedPackage.correlative?.comparative
            )}
            closeModal={setChangeStatusModal}
            updateData={updateData}
          />
        }
      />
      <div className="d-flex w-100 justify-content-betweeen">
        <div className="w-100 table-container__table-title">{title}</div>
        <div className="d-flex align-items-center justify-content-end w-100">
          <div
            className="d-flex align-items-center table-container__table-filters mr-4"
            onClick={() => downloadPdfZebra()}
            onKeyDown={() => downloadPdfZebra()}
          >
            <img src={iconList.tune} alt="filters" />
            <span className="ml-2">
              Descargar etiquetas ({checkedPackage?.length})
            </span>
          </div>
          <div
            className="d-flex align-items-center table-container__table-filters"
            onClick={() => setOpenFilters(true)}
            //onKeyDown={() => setOpenFilters(true)}
          >
            <img src={iconList.tune} alt="filters" />
            <span className="ml-2">Filtrar y ordenar</span>
            {filtersCount > 0 && (
              <span className="ml-2 custom-filters-count">{filtersCount}</span>
            )}
          </div>
        </div>
      </div>
      <Col
        xs="12"
        md="12"
        xl="12"
        className="d-flex justify-content-center custom-table-data w-100"
        style={{ minHeight: `${docsPerPage * 65}px` }}
      >
        <Table className="w-100" responsive>
          <thead className="table-container__table-headers">
            <tr>
              <th className="table-container__table-headers__header text-center">
                <span>
                  <Input
                    type="checkbox"
                    className="clickable-correlative checkbox-custom-style"
                    onClick={(e) => addAllPackges(e.target.checked)}
                    onChange={(e) => setAllChecked(e.target.checked)}
                    checked={allChecked}
                  />
                </span>
              </th>
              {headers?.map((header, index) => (
                <th
                  key={index}
                  className="table-container__table-headers__header"
                >
                  <span>{header}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              const createData = Object.values(item);
              return (
                <tr key={index} className="table-container__table-data">
                  <td className="align-items-center h-100 text-center custom-table-checkbox">
                    <span>
                      <Input
                        type="checkbox"
                        className="clickable-correlative checkbox-custom-style"
                        onClick={() => addingPackage(item)}
                        checked={checkedPackage?.find(
                          (f) =>
                            f.followingNumber === item.correlative?.comparative
                        )}
                      />
                    </span>
                  </td>
                  {createData?.map((i, v) => {
                    if (i?.type === "correlative") {
                      return (
                        <td
                          className="clickable-correlative align-items-center h-100"
                          key={v}
                          onClick={() => {
                            setPackageEvidence(item);
                            setViewEvidence(true);
                          }}
                          onKeyDown={() => {
                            setPackageEvidence(item);
                            setViewEvidence(true);
                          }}
                        >
                          {i?.value}
                        </td>
                      );
                    }
                    if (i?.name) {
                      return (
                        <td
                          key={v}
                          className="truncate-text align-items-center h-100"
                        >
                          <div className="custom-center-inner-td w-36">
                            <span className="truncate-text flex items-center gap-1">
                              <img
                                src={
                                  i?.icon ? i?.icon : iconList["defaultAvatar"]
                                }
                                className="table-container__table-data__delivery-icon"
                                alt="icon"
                              />
                              {i?.name}
                            </span>
                          </div>
                        </td>
                      );
                    }
                    if (i?.statusPill) {
                      return (
                        <td
                          key={v}
                          className="td-custom-status-pill truncate-text align-items-center h-100"
                        >
                          <span className="text-truncate w-100">
                            {i?.statusPill}
                          </span>
                        </td>
                      );
                    }
                    return (
                      <td key={v} className="td-custom-width truncate-text">
                        <span className="text-truncate">{i}</span>
                      </td>
                    );
                  })}
                  {actions && (
                    <td className="td-custom-actions">
                      <div className="custom-center-inner-td">
                        {user?.role === "client" ? (
                          <Dropdown
                            isOpen={
                              showActions?.find(
                                (el) =>
                                  el.correlative?.comparative ===
                                  item?.correlative?.comparative
                              )?.actions
                            }
                            toggle={() => toggle(item)}
                            direction="down"
                            data-toggle="dropdown"
                            tag="div"
                            className="dropdown-new-style"
                          >
                            <DropdownToggle>
                              <img src={iconList["actions"]} alt="actions" />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                className="dropdown-font mb-1"
                                onClick={() => {
                                  setSelectedPackage(item);
                                  setVoucherModal(true);
                                }}
                              >
                                <div className="flex items-center">
                                <img
                                  className="dropdown-font__image mr-2"
                                  alt="detail"
                                  src={iconList.qrcode}
                                />{" "}
                                Ver detalle
                                </div>
                              </DropdownItem>
                              <Divider />
                              <DropdownItem
                                className="dropdown-font mt-1"
                                disabled={item?.status?.status !== 0}
                                onClick={() => {
                                  setSelectedPackage(item);
                                  setCancelPackage(true);
                                }}
                              >

                                <div className="flex items-center">
                                <img
                                  className="dropdown-font__image mr-2"
                                  alt="cancel"
                                  src={iconList.close}
                                />{" "}
                                Cancelar
                                </div>
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        ) : (
                          <Dropdown
                            isOpen={
                              showActions?.find(
                                (el) =>
                                  el.correlative?.comparative ===
                                  item?.correlative?.comparative
                              )?.actions
                            }
                            toggle={() => toggle(item)}
                            direction="down"
                            data-toggle="dropdown"
                            tag="div"
                            className="dropdown-new-style"
                          >
                            <DropdownToggle>
                              <img src={iconList["actions"]} alt="actions" />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                className="dropdown-font mb-1"
                                onClick={() => {
                                  setSelectedPackage(item);
                                  setVoucherModal(true);
                                }}
                              >
                                <div className="flex items-center gap-1">
                                  <img
                                    className="dropdown-font__image mr-2"
                                    alt="detail"
                                    src={iconList.qrcode}
                                  />{" "}
                                  Ver etiqueta
                                </div>
                              </DropdownItem>
                              <Divider />
                              <DropdownItem
                                className="dropdown-font mt-1"
                                disabled={
                                  item?.status?.value === 4 ||
                                  item?.status?.value === 5
                                }
                                onClick={() => {
                                  setSelectedPackage(item);
                                  setAssignDelivery(true);
                                }}
                              >
                                <div className="flex items-center gap-1">
                                  <img
                                    className="dropdown-font__image mr-2"
                                    alt="cancel"
                                    src={iconList.manageAccount}
                                  />{" "}
                                  Asignar repartidor
                                </div>
                              </DropdownItem>
                              <Divider />
                              <DropdownItem
                                className="dropdown-font mt-1"
                                disabled={
                                  item?.status?.value === 4 ||
                                  item?.status?.value === 5
                                }
                                onClick={() => {
                                  setSelectedPackage(item);
                                  setChangeStatusModal(true);
                                }}
                              >
                                <div className="flex items-center gap-1">
                                  <img
                                    className="dropdown-font__image mr-2"
                                    alt="cancel"
                                    src={iconList.edit}
                                  />{" "}
                                  Cambiar estado
                                </div>
                              </DropdownItem>
                              <Divider />
                              <DropdownItem
                                className="dropdown-font mt-1"
                                disabled={item?.status?.value !== 0}
                                onClick={() => {
                                  setSelectedPackage(item);
                                  setCancelPackage(true);
                                }}
                              >
                                <div className="flex items-center gap-1">
                                  <img
                                    className="dropdown-font__image mr-2"
                                    alt="cancel"
                                    src={iconList.close}
                                  />{" "}
                                  Cancelar
                                </div>
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        )}
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Col>
      <div className="w-100 d-flex justify-content-center">
        {totalDocs !== 0 ? <><span className="w-50 table-counter">
          Mostrando {docsPerPage * (pageSelected + 1) - (docsPerPage - 1)} -{" "}
          {docsPerPage * (pageSelected + 1) > totalDocs
            ? totalDocs
            : docsPerPage * (pageSelected + 1)}{" "}
          de {totalDocs} pedidos
        </span>
        <Pagination
          documents={totalDocs || 1}
          pagination={docsPerPage || 0}
          page={page || 1}
          pageSelected={pageSelected}
        /></> : <>Cargando</>}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";

import Search from '../../assets/search.svg'

const SEARCH_TEXT = {
  order_id: "Buscar por número de venta",
  followingNumber: "Buscar por número de correlativo",
  "shipping_address.address_line": "Buscar por dirección de destino",
  "shipping_address.city.name": "Buscar por comuna de destino",
}

export default function SearchBar({ message, onChange, loading, searchButton = false, onSearchButton = () => { } }) {
  

  const [queryType, setQueryType] = useState("followingNumber")
  const [query, setQuery] = useState("")

  useEffect(() => {
    onChange && onChange(query, queryType)

    
  }, [queryType, query])

  return (
    <div className="flex">
      <select
        className="text-center !border-r-0 px-2 border border-[#ced4da] z-10 translate-x-3 rounded-md"
        onChange={(e) => setQueryType(e.target.value)}
        value={queryType}
        defaultValue={queryType}
        disabled={loading}
      >
        <option value="followingNumber">Correlativo</option>
        <option value="order_id">N° Venta</option>
        <option value="shipping_address.address_line">Dirección</option>
        <option value="shipping_address.city.name">Comuna</option>
      </select>

      <Input
      className="customSearchBar !shadow-none "
      placeholder={
        message ?? SEARCH_TEXT[queryType]
      }
      bsSize="lg"
      onChange={(e) => setQuery(e.target.value)}
      value={query}
        disabled={loading}
        onKeyDown={(e) => { 
          e.key === 'Enter' && searchButton && onSearchButton()
        }}
      />
      {searchButton && 
        <img src={Search} onClick={onSearchButton} className="-translate-x-8 hover:-rotate-6 cursor-pointer hover:scale-[1.15] transition" alt="" />
      }
    </div>
  );
}

import React from "react";
import { iconList } from "../../utils/iconList";

export default function Stepper({ stepStatus }) {
  return (
    <div className="p-4 d-flex align-items-center">
      <div
        className={`rounded-circle ${
          stepStatus?.step1?.value === 0
            ? "process-rounded"
            : "complete-rounded"
        } d-flex justify-content-center align-items-center`}
      >
        {stepStatus?.step1?.value > 0 && stepStatus?.step1?.value !== 5 && <img src={iconList.check} alt="img" />}
        {stepStatus?.step1?.value === 0 && (
          <div className="rounded-circle bg-primary rounded-process" />
        )}
      </div>
      <div className="d-grid ml-3">
        <span className="custom-status-following">Paquete ingresado</span>
        {/*<span className="custom-date-following">Fecha</span>*/}
      </div>
      <div className="line-flow ml-1"></div>
      <div
        className={`rounded-circle ${
          stepStatus?.step1?.value === 1 || stepStatus?.step1?.value === 2
            ? "process-rounded"
            : stepStatus?.step1?.value < 1
            ? "pending-rounded"
            : "complete-rounded"
        } d-flex justify-content-center align-items-center`}
      >
        {stepStatus?.step1?.value > 2 && stepStatus?.step1?.value !== 5 && <img src={iconList.check} alt="img" />}
        {(stepStatus?.step1?.value === 1 ||
          stepStatus?.step1?.value === 2) && (
            <div className="rounded-circle bg-primary rounded-process" />
          )}
        {stepStatus?.step1?.value < 1 && (
          <div className="rounded-circle rounded-pending-process" />
        )}
      </div>
      <div className="d-grid ml-3">
        <span className="custom-status-following">Paquete recolectado</span>
        {/*<span className="custom-date-following">Fecha</span>*/}
      </div>
      <div className="line-flow ml-1"></div>
      <div
        className={`rounded-circle ${
          stepStatus?.step1?.value === 3
            ? "process-rounded"
            : stepStatus?.step1?.value < 3
            ? "pending-rounded"
            : "complete-rounded"
        } d-flex justify-content-center align-items-center`}
      >
        {stepStatus?.step1?.value > 3 && stepStatus?.step1?.value !== 5 && <img src={iconList.check} alt="img" />}
        {stepStatus?.step1?.value === 3 && (
          <div className="rounded-circle bg-primary rounded-process" />
        )}
        {stepStatus?.step1?.value < 3 && (
          <div className="rounded-circle rounded-pending-process" />
        )}
      </div>
      <div className="d-grid ml-3">
        <span className="custom-status-following">Paquete en ruta</span>
        {/*<span className="custom-date-following">Fecha</span>*/}
      </div>
      <div className="line-flow ml-1"></div>
      <div
        className={`rounded-circle ${
          stepStatus?.step1?.value < 4
            ? "pending-rounded"
            : "complete-rounded"
        } d-flex justify-content-center align-items-center`}
      >
        {stepStatus?.step1?.value === 4 && <img src={iconList.check} alt="img" />}
        {stepStatus?.step1?.value < 4 && (
          <div className="rounded-circle rounded-pending-process" />
        )}
      </div>
      <div className="d-grid ml-3">
        <span className="custom-status-following">Paquete entregado</span>
        {/*<span className="custom-date-following">Fecha</span>*/}
      </div>
    </div>
  );
}

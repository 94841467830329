import React, { useState } from "react";
import { Container, Row, Col, Button, Spinner } from "reactstrap";
import { updatePackageStatus } from "../../api/packages";
import { iconList } from '../../utils/iconList'

export default function CancelPackage({ defaultData, closeModal, updateData }) {
    const [loading, setLoading] = useState(false)

  const cancelPackage = async () => {
    setLoading(true)
    const { data, error } = await updatePackageStatus({
      status: 5,
      id: defaultData?._id,
    });
    if (data) {
      closeModal(false);
      updateData(true)
      setLoading(false)
    }

    if (error) {
      closeModal(false);
      setLoading(false)
    }
  };

  return (
    <Container>
      <Row>
        <Col xs="12" md="12" xl="12">
          <div className="w-100 d-flex align-items-center">
          <img src={iconList.warning} alt="warning" />
          <span className="custom-cancel-message">Advertencia</span>
          </div>
          <div className="custom-dividers-modal"></div>
          <span className="custom-content-description">Esto borrará el pedido. Ten en cuenta que si decides cancelar el envío, no se realizará la entrega de tu paquete.</span>
        </Col>
        <Col
          xs="12"
          md="12"
          xl="12"
          className="mt-4 d-flex justify-content-end"
        >
          <Button block onClick={() => cancelPackage()} disabled={loading} className="custom-danger-button">
            Cancelar {loading && <Spinner size="sm" />}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

const monthNames = {
    'January': 'Enero',
    'February': 'Febrero',
    'March': 'Marzo',
    'April': 'Abril',
    'May': 'Mayo',
    'June': 'Junio',
    'July': 'Julio',
    'August': 'Agosto',
    'September': 'Septiembre',
    'October': 'Octubre',
    'November': 'Noviembre',
    'December': 'Diciembre'
  };

  function resetDate(fecha) {
    // Establecer la hora de la fecha en 00:00:00
    fecha.setHours(0);
    fecha.setMinutes(0);
    fecha.setSeconds(0);
    fecha.setMilliseconds(0);
  
    // Devolver la fecha actualizada
    return fecha;
  }

export default function getNextWeeks() {
    const now = new Date();
    const maxDate = new Date(now.getFullYear(), now.getMonth() + 3, 0); // último día del tercer mes adelante
    const weeks = [];
  
    while (now < maxDate) {
      const startOfWeek = new Date(now);
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // retrocede al domingo de la semana
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(endOfWeek.getDate() + 6); // avanza al sábado de la semana
      const monthName = monthNames[startOfWeek.toLocaleString('default', { month: 'long' })];
      weeks.push({ text: `${startOfWeek.getDate()} al ${endOfWeek.getDate()} de ${monthName}`, startDate: startOfWeek, endDate: endOfWeek });
      now.setDate(now.getDate() + 7); // avanza una semana
    }
  
    return weeks.map(e => ({...e, startDate: resetDate(e.startDate), endDate: resetDate(e.endDate)}))
  }
import React, {createContext, useEffect, useState} from "react";
import {getAccessTokenApi, getRefreshTokenApi, logout, refreshAccessToken,} from "../api/token";
import {showToast} from '../utils/showToast'
import {useNavigate} from 'react-router-dom'
import jwtDecode from "jwt-decode";

export const AuthContext = createContext();

export default function AuthProvider(props) {
  const { children } = props;
  const [user, setUser] = useState({
    user: null,
    isLoading: true,
  });
  const navigate = useNavigate()

  useEffect(() => {
    checkUserLogin(setUser);
  }, [children]);

  if(user.user && user.user?.role === 'delivery') {
    showToast('Sólo los usuarios de tipo cliente cuentan con acceso a este sistema', false)
    logout()
    navigate("/login")
  }

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
}

function checkUserLogin(setUser) {
  const accessToken = getAccessTokenApi();

  if (!accessToken) {
    const refreshToken = getRefreshTokenApi();

    if (!refreshToken) {
      logout();
      setUser({
        user: null,
        isLoading: false,
      });
    } else {
      refreshAccessToken(refreshToken);
    }
  } else {
    setUser({
      isLoading: false,
      user: jwtDecode(accessToken),
    });
  }
}

/**
 * Returns an object containing information about the authentication status and role of the current user.
 *
 * @returns {Object} An object with the following properties:
 * - isAuthenticated: A boolean indicating whether the user is authenticated or not.
 * - role: The role of the authenticated user.
 */
export function useAuthToken() {
  const getToken = () => localStorage.getItem("accessToken");
  const getUserData = () => {
    const token = getToken();
    if (!token) return null;
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error("Failed to decode token", error);
      return null;
    }
  };

  const userData = getUserData();

  return {
    isAuthenticated: !!userData,
    role: userData?.role,
  };
}
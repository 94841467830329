import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import { getActiveFaqs } from "../../../api/faqs";

export default function Faqs() {
  const [open, setOpen] = useState(0);
  const [faqs, setFaqs] = useState([]);

  const toggle = (id) => {
    setOpen(id);
  };

  useEffect(() => {
    getActiveFaqs()
      .then((data) => setFaqs(data?.data?.faq))
      .catch((err) => console.error(err));
  }, []);

  return (
     // se cambia componente section por div con estilos segun standar - ksandoval - 10-05
    <div className="w-100 all-users-container mt-6">
      <div className="d-flex justify-content-between">
        <span className="d-flex">
          <h2 className="h2-custom-size all-users-container__title">
            Preguntas frecuentes
          </h2>
        </span>
      </div>
      {faqs.length > 0 ? (
        <div className="custom-accordion-container px-3">
        <Accordion open={open} toggle={toggle} flush>
          {faqs.map((faq, index) => (
            <AccordionItem className="p-3">
              <AccordionHeader targetId={index}>
                <span className="custom-header-text-form">{faq.title}</span>
              </AccordionHeader>
              <AccordionBody accordionId={index}>
                <span className="custom-body-text-form">{faq.content}</span>
              </AccordionBody>
            </AccordionItem>
          ))}
        </Accordion>
        </div>
      ) : (
        <span>No se han encontrado preguntas.</span>
      )}
    </div>
  );
}

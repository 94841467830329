import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { Routes, Route, useNavigate } from "react-router-dom";
import AuthProvider from "../../providers/authProviders";
import useAuth from "../../hooks/useAuth";

export default function LoginLayout({ routes }) {
  const navigate = useNavigate();
  const { user, isLoading } = useAuth();

  useEffect(() => {
    if (user && !isLoading) {
      user.role === 'admin' && navigate("/admin");
      user.role === 'client' && navigate("/home");
    }
  }, [user, isLoading, navigate]);

  useEffect(() => {
    if (!user && !isLoading && !window.location.pathname.startsWith("/login")) {
      navigate("/login")
    }
  }, [user, isLoading, navigate]);

  return (
    <Container
      className="w-100 m-0 p-0"
      style={{ maxWidth: "100%", backgroundColor: "none" }}
    >
      <ToastContainer />
      <Row>
        <Col className="w-100 vh-100">
          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <AuthProvider>
                    <route.element />
                  </AuthProvider>
                }
              />
            ))}
          </Routes>
        </Col>
      </Row>
    </Container>
  );
}

import JSzip from "jszip";
import { saveAs } from "file-saver";
import { ZplTemplate } from "../config/zplTemplate";

function getFormattedDate() {
  const months = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];
  const date = new Date();
  const day = String(date.getDate()).padStart(2, '0');
  const month = months[date.getMonth()];
  return `${day} - ${month}`;
}

export const downloadZplFormat = async ({ defaultData }) => {
  let zplFormat = ZplTemplate;

  const businessName = defaultData?.senderUser?.senderBusinessName;
  const senderName = defaultData?.senderUser?.senderName ?? "";
  const senderLastName = defaultData?.senderUser?.senderLastName ?? "";
  const nameToUse = businessName || (senderName + " " + senderLastName);
  const receiverName = defaultData?.receiver_name ?? "";
  const receiverLastName = defaultData?.receiver_lastName ?? "";
  const receiverNameToUse = (receiverName + " " + receiverLastName);
  const fechaActual = getFormattedDate();
  const comunaVendedor = defaultData?.senderUser?.senderNeighborhood || "Metropolitana"

  zplFormat = zplFormat.replace(
    "{{address}}",
    `${defaultData?.senderUser?.senderAddress?.slice(0, 60) ?? ""}`
  );
  zplFormat = zplFormat.replace(
    "{{address2}}", comunaVendedor
  );
  zplFormat = zplFormat.replace(
    "{{city}}",
    `${defaultData?.shipping_address?.city?.name ?? "Ciudad no asignada"}`
  );
  zplFormat = zplFormat.replace(
    "{{emitter.name}}",nameToUse
  );
  zplFormat = zplFormat.replace(
    "{{emitter.phone}}",
    `${!!defaultData?.senderUser?.phone ? defaultData?.senderUser?.phone : ""}`
  );
  zplFormat = zplFormat.replace(
    "{{receiver.name}}", receiverNameToUse
  );
  zplFormat = zplFormat.replace(
    "{{receiver.phone}}",
    `${defaultData?.receiver_phone ?? "Teléfono no asignado"}`
  );

  zplFormat = zplFormat.replace(
    "{{receiver.address_line}}",
    `${defaultData?.shipping_address?.address_line?.slice(0, 50) ?? ""}`
  );
  zplFormat = zplFormat.replace(
    "{{receiver.address_line2}}",
    `${defaultData?.shipping_address?.address_line?.slice(50) ?? ""} ${
      defaultData?.shipping_address?.city?.name ?? ""
    }`
  );
  zplFormat = zplFormat.replace(
    "{{receiver.comment}}",
    `${defaultData?.shipping_address?.comment ?? "Sin comentarios"}`
  );

  zplFormat = zplFormat.replace("{{packageKey}}", `${defaultData?._id}`);
  zplFormat = zplFormat.replace(
    "{{followingNumber}}",
    `${defaultData?.followingNumber}`
  );
  zplFormat = zplFormat.replace(
    "{{comments}}",
    `${defaultData?.comments ?? "Sin comentarios"}`
  );
  zplFormat = zplFormat.replace(
    "{{order_id}}",
    `${defaultData?.order_id ?? ""}`
  );
  zplFormat = zplFormat.replace(
    "{{fecha}}", fechaActual
  );

  zplFormat = zplFormat.replaceAll("ñ", "n");
  zplFormat = zplFormat.replaceAll("Ñ", "N");

  const zip = new JSzip();
  zip.file(`Paquete OTS - ${defaultData._id}.zpl`, zplFormat);
  zip.generateAsync({ type: "blob" }).then(async function (content) {
    await saveAs(content, "Paquetes OTS.zip");
  });

  return true;
};

export const multipleDownloadZPLFile = ({ packages }) => {
  
  let zplFormatAllPackages = "";

  for (const packageData of packages) {
    let zplFormat = ZplTemplate;

    const businessName = packageData?.senderUser?.senderBusinessName;
    const senderName = packageData?.senderUser?.senderName ?? "";
    const senderLastName = packageData?.senderUser?.senderLastName ?? "";
    const nameToUse = businessName || (senderName + " " + senderLastName);
    const receiverName = packageData?.receiver_name ?? "";
    const receiverLastName = packageData?.receiver_lastName ?? "";
    const receiverNameToUse = receiverName + " " + receiverLastName;
    const fechaActual = getFormattedDate();
    const comunaVendedor = packageData?.senderUser?.senderNeighborhood || "Metropolitana"

    zplFormat = zplFormat.replace(
      "{{address}}",
      `${packageData?.senderUser?.senderAddress?.slice(0, 60) ?? ""}`
    );
    zplFormat = zplFormat.replace(
      "{{address2}}", comunaVendedor
    );
    zplFormat = zplFormat.replace(
      "{{city}}",
      `${packageData?.shipping_address?.city?.name ?? "Ciudad no asignada"}`
    );
    zplFormat = zplFormat.replace(
      "{{emitter.name}}",nameToUse
    );
    zplFormat = zplFormat.replace(
      "{{emitter.phone}}",
      `${
        !!packageData?.senderUser?.phone ? packageData?.senderUser?.phone : ""
      }`
    );
    zplFormat = zplFormat.replace(
      "{{receiver.name}}", receiverNameToUse
    );
    zplFormat = zplFormat.replace(
      "{{receiver.phone}}",
      `${packageData?.receiver_phone ?? "Teléfono no asignado"}`
    );

    zplFormat = zplFormat.replace(
      "{{receiver.address_line}}",
      `${packageData?.shipping_address?.address_line?.slice(0, 50) ?? ""}`
    );
    zplFormat = zplFormat.replace(
      "{{receiver.address_line2}}",
      `${packageData?.shipping_address?.address_line?.slice(50) ?? ""} ${
        packageData?.shipping_address?.city?.name ?? ""
      }`
    );
    zplFormat = zplFormat.replace(
      "{{receiver.comment}}",
      `${packageData?.shipping_address?.comment ?? "Sin comentarios"}`
    );

    zplFormat = zplFormat.replace("{{packageKey}}", `${packageData?._id}`);
    zplFormat = zplFormat.replace(
      "{{followingNumber}}",
      `${packageData?.followingNumber}`
    );
    zplFormat = zplFormat.replace(
      "{{comments}}",
      `${packageData?.comments ?? "Sin comentarios"}`
    );
    zplFormat = zplFormat.replace(
      "{{order_id}}",
      `${packageData?.order_id ?? ""}`
    );
    zplFormat = zplFormat.replace(
      "{{fecha}}", fechaActual
    );

    zplFormat = zplFormat.replaceAll("ñ", "n");
    zplFormat = zplFormat.replaceAll("Ñ", "N");

    zplFormatAllPackages += zplFormat;
  }

  return zplFormatAllPackages;
};

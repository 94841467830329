import React, { useEffect, useState } from 'react'
import { getRoute, updateRoute } from '../../api/routes'
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from 'lucide-react'
import useRoutes from "../../store/routes";
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

const ClientList = ({ title, clients, selectedClients, handleCheckboxChange }) => {
    const [query, setQuery] = useState("")

    const filteredClients = query !== "" ? clients.filter(client => (client.businessName?.toLowerCase().includes(query) || client.address?.toLowerCase().includes(query))) : clients
    return (
        <div className='w-[45%] rounded-lg p-2 border border-gray-400 h-full '>
            <h3 className='text-xl font-semibold whitespace-nowrap'>{title} <span className='text-sm px-2 font-light text-orange-800 bg-orange-200 border-2 border-orange-500 rounded-full'>{clients.length}</span></h3>
            <input value={query} onChange={e => {setQuery(e.target.value)}} className='border font-light placeholder:font-light w-full mt-1 rounded-lg px-3 py-2 border-gray-400' type="text" placeholder='Nombre o direccion' />
            <ul className='flex flex-col overflow-x-hidden overflow-y-auto max-h-[87%]'>
                {filteredClients.map(client => (
                    <li key={client._id} className='flex gap-2 w-full px-2 items-center py-1 border-b-2'>
                        <input
                            type="checkbox"
                            checked={selectedClients.includes(client._id)}
                            onChange={() => handleCheckboxChange(client._id)}
                            className="form-checkbox h-5 w-5 text-orange-600 border-gray-300 rounded accent-orange-600 transition duration-150 ease-in-out"
                        />
                        <div className='flex flex-col max-w-[80%]'>
                            <h1 className='text-lg font-semibold whitespace-nowrap capitalize'>{client.businessName.length >= 20 ? client.businessName.slice(0,20) + "..." : client.businessName}</h1>
                            <span className='text-sm font-light whitespace-nowrap'>{client.address}, {client.municipality}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const ActionButtons = ({ moveClientsToAssigned, moveClientsToAvailable }) => {
    return (
        <div className='flex flex-col h-full justify-center items-center gap-4 '>
            <span onClick={moveClientsToAssigned}><ArrowRightCircleIcon className='w-16 h-16 stroke-[0.5px] text-gray-500 hover:stroke-[0.8px] transition-all cursor-pointer'></ArrowRightCircleIcon></span>
            <span onClick={moveClientsToAvailable}><ArrowLeftCircleIcon className='w-16 h-16 stroke-[0.5px] text-gray-500 hover:stroke-[0.8px] transition-all cursor-pointer'></ArrowLeftCircleIcon></span>
            <span></span>
        </div>
    );
};

export default function EditRouteClients({ routeId, closeModal }) {
    const clients = useRoutes(state => state.clients)
    const [routeData, setRouteData] = useState()
    const [availableClients, setAvailableClients] = useState([])
    const [assignedClients, setAssignedClients] = useState([])
    const [selectedClients, setSelectedClients] = useState([]);

    const fetchRoutes = useRoutes((state) => state.fetchRoutesBeartrack);


    const submitChange = async () => {
        toast.promise(
            updateRoute(routeId, { clients: assignedClients }),
            {
                pending: {
                    render() {
                        return 'Guardando cambios...';
                    },
                    icon: '🔄',
                },
                success: {
                    render() {
                        return `Ruta actualizada exitosamente!`;
                    },
                    icon: '✅',
                    autoClose: 3000, // Auto-close after 3 seconds
                },
                error: {
                    render() {
                        return `Hubo un error: Inténtalo de nuevo más tarde.`;
                    },
                    icon: '❌',
                    autoClose: 5000, // Auto-close after 5 seconds
                }
            }
        ).then(() => {
            fetchRoutes().finally(() => {
                closeModal()
            })
        })
    };

    const handleCheckboxChange = (clientId) => {
        setSelectedClients((prevSelected) => {
            if (prevSelected.includes(clientId)) {
                return prevSelected.filter(id => id !== clientId);
            } else {
                return [...prevSelected, clientId];
            }
        });
    };

    useEffect(() => {
        if (routeData) {
            setAvailableClients(clients.filter(client => !routeData.clients.some(_client => _client._id === client._id)))
            setAssignedClients(routeData.clients)
        } else {
            setAvailableClients(clients)
            setAssignedClients([])
        }
    }, [routeData, clients])

    useEffect(() => {
        (async () => {
            //@ts-ignore
            const { data, error } = await getRoute(routeId)
            if (!error) {
                setRouteData(data.data)
            }
        })()
    }, [routeId])

    const moveClientsToAssigned = () => {
        setAssignedClients([...assignedClients, ...availableClients.filter(client => selectedClients.includes(client._id))]);
        setAvailableClients(availableClients.filter(client => !selectedClients.includes(client._id)));
        setSelectedClients([]);
    };

    const moveClientsToAvailable = () => {
        setAvailableClients([...availableClients, ...assignedClients.filter(client => selectedClients.includes(client._id))]);
        setAssignedClients(assignedClients.filter(client => !selectedClients.includes(client._id)));
        setSelectedClients([]);
    };

    if (!routeData) return <div><Spinner/> <span>Cargando datos de la ruta...</span></div>

    return (
        <section>
            <h2 className='text-2xl'>Agregar cliente a la ruta <span className='font-semibold'>{routeData.routeName}</span></h2>
            <div className='w-full flex justify-between mt-3 mx-auto h-[80vh] '>
                <ClientList clients={availableClients} title="Clientes disponibles" selectedClients={selectedClients} handleCheckboxChange={handleCheckboxChange} />
                <ActionButtons moveClientsToAssigned={moveClientsToAssigned} moveClientsToAvailable={moveClientsToAvailable} />
                <ClientList clients={assignedClients} title="Clientes en la ruta" selectedClients={selectedClients} handleCheckboxChange={handleCheckboxChange} />
            </div>
            <div className='flex gap-2 mt-2'>
                <button onClick={submitChange} className='w-1/2 rounded-lg py-2 bg-orange-600 text-white font-semibold'>Guardar Ruta</button>
                <button onClick={closeModal} className='w-1/2 rounded-lg py-2 border-2 border-orange-600 text-xl font-extralight text-orange-600'>Cancelar</button>
            </div>
        </section>
    )
}

import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane, Col } from "reactstrap";

export default function TabsComponent({ tabsOptions, tabContent }) {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <Nav className="custom-tabs-container">
        {tabsOptions &&
          tabsOptions.map((element, index) => (
            <Col
              xs={`${parseInt(12 / parseInt(tabsOptions.length))}`}
              md={`${parseInt(12 / parseInt(tabsOptions.length))}`}
              xl={`${parseInt(12 / parseInt(tabsOptions.length))}`}
            >
              <NavItem className="custom-tabs" key={index}>
                <NavLink
                  className={`custom-tabs ${
                    (selectedTab === index && index === 0) && "custom-selected-tab"
                  } ${
                    (selectedTab === index && index === tabsOptions?.length - 1) && "custom-last-selected-tab"
                  }`}
                  active={selectedTab === index}
                  onClick={() => setSelectedTab(index)}
                >
                  {element.title}
                </NavLink>
              </NavItem>
            </Col>
          ))}
      </Nav>
      <TabContent activeTab={selectedTab}>
        {tabContent &&
          tabContent?.map((content, index) => (
            <TabPane tabId={index} key={index}>
              {content}
            </TabPane>
          ))}
      </TabContent>
    </>
  );
}

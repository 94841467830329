import {useAuthToken} from "../../providers/authProviders";
import {Navigate} from "react-router-dom";

/**
 * Checks if the user is authenticated and has the role of admin.
 * If not, it redirects the user to the homepage.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The children components to render if the user is authenticated and has the role of admin.
 *
 * @return {React.ReactNode} - The children components if the user is authenticated and has the role of admin, or a <Navigate> component that redirects to the homepage.
 */
export default function RequireAdmin({ children })  {
  const { isAuthenticated, role } = useAuthToken();

  if (!isAuthenticated || role !== "admin") {
    return <Navigate to="/" />;
  }

  return children;
};
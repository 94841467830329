import React, { useState } from "react";
import { ActionButton } from "..";
import useRoutes from "../../../store/routes";
import useModal from "../../../store/modal";
import { hourList } from "../constants";


const PreferedHour = ({ clientId }) => {
    const [newHour, setNewHour] = useState("08:00");
    const closeModal = useModal((state) => state.closeModal);

    const changeClientPreferedHour = useRoutes(
        (state) => state.changeClientPreferedHour
    );
    return (
        <>
            <div>Selecciona el horario preferido para el cliente.</div>
            <select
                value={newHour}
                onChange={(e) => {
                    setNewHour(e.target.value);
                }}
                className="mt-2 cursor-pointer bg-gray-50 !outline-none py-2 px-3 border border-gray-600 rounded-md"
                name="preferedHour"
                id=""
            >
                {hourList.map((hour) => {
                    return (
                        <option key={hour} value={hour}>
                            {hour}
                        </option>
                    );
                })}
            </select>
            <ActionButton
                text={"Asignar"}
                primary="#FEF3D4"
                secondary="#FFC107"
                onClick={() => {
                    changeClientPreferedHour(clientId, newHour);
                    closeModal();
                }}
            />
        </>
    );
};

export default PreferedHour;
/* eslint-disable */
import React, { useCallback, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams} from 'react-router-dom'
import { confirmRecovery, recoveryPassword } from "../../api/auth";
import { showToast } from "../../utils/showToast";
import { iconList } from '../../utils/iconList'

export default function SetNewPassword() {
  const methods = useForm({ mode: "onSubmit" });
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);

    const params = useParams()
    const submitValues = useCallback(async (values) => {
        if (values.password.length < 6) { 
            showToast("La contraseña debe tener al menos 6 caracteres.", false);
            return
        }
    setLoading(true);
      try {
    const { data, error } = await confirmRecovery({...values, token: params.token});
    if (error) {
      showToast(error.message, false);
      return
    }
      if (data.message === "Token invalido") {
        showToast("Token invalido", false);
      }
      if (data.message === "Contraseña actualizada") {
          showToast("Listo!", true);
            navigate("/login")
      }
      
    } catch (error) {
      showToast(error, false);
      
    } finally {
      setLoading(false);

    }
    
  }, [navigate]);

  return (
    <Container className="vh-100">
      <Row
        className="d-flex justify-content-center align-items-center vh-100"
        style={{ overflow: "hidden" }}
      >
        <Col xs="6" md="6" xl="6">
          <div className="login-card-style mt-5">
            <div className="w-100 d-flex justify-content-center">
              <Card className="m-0 login-card-over">
                <CardBody className="p-4">
                  <a
                    href="#"
                    className="d-flex justify-content-center mt-3 mb-4"
                  >
                    <img
                      src={iconList.logo}
                      alt="logo"
                      style={{ width: "70%" }}
                    />
                  </a>
                  <h3 className="mb-3 login-custom-size">
                    Escoja su nueva contraseña.
                  </h3>
                  <Form onSubmit={methods.handleSubmit(submitValues)}>
                    <FormGroup>
                      <div>
                        <Label className="mt-3">Nueva Contraseña</Label>
                        <Controller
                          name="password"
                          control={methods.control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="password"
                              className="p-2 px-3"
                            />
                          )}
                          rules={{ required: "Ingrese contraseña" }}
                        />
                        {methods?.formState?.errors?.email && (
                          <Label className="text-danger">
                            * {methods?.formState?.errors?.email?.message}
                          </Label>
                        )}
                      </div>

                    </FormGroup>
                    <div className="w-100">
                      {loading ? (
                        <Button className="login-btn" block>
                          <Spinner size="sm" />
                        </Button>
                      ) : (
                        <Button className="login-btn" block>
                          Confirmar
                        </Button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

import React, { useState, useEffect } from "react";
import { Button, Spinner } from "reactstrap";
import { iconList } from "../../utils/iconList";
import { downloadPdf } from "../../api/downloadPdf";
import { getUser } from "../../api/user";
import { showToast } from "../../utils/showToast";
import { multipleDownloadZPLFile } from "../../utils/downloadZebra";
// import { multipleDownloadZPLFile } from '../../utils/newDownloadZebra'
import useAuth from "../../hooks/useAuth";

import downloadTxt from "../../utils/downloadTxt";

import fileName from "../../utils/fileName";

export default function Confirmation({ mssg, packages, setConfirmationModal }) {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const {
    user: { id },
  } = useAuth();

  const pdf = async () => {
    setLoading(true);
    const { data, error } = await downloadPdf({
      id: userData?._id,
      packages: packages,
    });

    if (data) {
      window.open(data?.url);
      setLoading(false);
      setConfirmationModal(false);
    }

    if (error) {
      showToast("Error al generar PDF", false);
      setLoading(false);
    }
  };

  const zebra = async () => {
    const element = document.createElement("a");
    const zplFinal = multipleDownloadZPLFile({ packages });
    // const zplFinal = await multipleDownloadZPLFile({ packages });
    //console.log("el contenido del zebra:::", zplFinal);
    element.setAttribute(
      "href",
      `data:text/plain;charset=utf-8,${encodeURIComponent(zplFinal)}`
    );

    element.setAttribute("download", fileName("zpl"));

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    setConfirmationModal(false);
  };

  const txt = () => {
    // const element = document.createElement('a')

    // element.setAttribute(
    //   'href',
    //   `data:text/plain;charset=utf-8,${downloadTxt(packages[0])}`
    // );
    // element.setAttribute('download', fileName("txt"));

    // element.style.display = 'none';
    // document.body.appendChild(element);

    // element.click();

    // document.body.removeChild(element);
    // setConfirmationModal(false)
    const element = document.createElement("a");
    const zplFinal = multipleDownloadZPLFile({ packages });

    element.setAttribute(
      "href",
      `data:text/plain;charset=utf-8,${encodeURIComponent(zplFinal)}`
    );

    element.setAttribute("download", fileName("txt"));

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    setConfirmationModal(false);
  };

  useEffect(() => {
    getUser({ id })
      .then(({ data: { user } }) => setUserData(user))
      .catch((err) => console.error(`This error ${err}`));
  }, [id]);

  return (
    <div className="confirmation-modal-contianer px-0 py-2">
      <div className="d-flex align-items-center">
        <img
          src={iconList.whiteCheck}
          alt="check"
          className="confirmation-modal-contianer__img"
        />
        <span className="confirmation-modal-contianer__mssg ml-3">{mssg}</span>
      </div>
      <div className="d-flex">
        <Button
          color="dark"
          block
          className="confirmation-modal-contianer__btn mr-2 mt-4 bg-black"
          onClick={() => zebra()}
          disabled={loading}
        >
          Descargar etiqueta Zebra (.zpl) {loading && <Spinner size="sm" />}
        </Button>
        <Button
          color="dark"
          outline
          block
          className="confirmation-modal-contianer__btn mr-2 mt-4"
          onClick={() => pdf()}
          disabled={loading}
        >
          Descargar etiqueta PDF (.pdf) {loading && <Spinner size="sm" />}
        </Button>
        <Button
          color="dark"
          outline
          block
          className="confirmation-modal-contianer__btn mr-2 mt-4 bg-black text-white"
          onClick={() => txt()}
          disabled={loading}
        >
          Descargar etiqueta TXT (.txt) {loading && <Spinner size="sm" />}
        </Button>
      </div>
      <div className="w-100 d-flex justify-content-center mt-3">
        <span
          className="confirmation-modal-contianer__second-option"
          onClick={() => setConfirmationModal(false)}
        >
          También lo puedes hacer más tarde
        </span>
      </div>
    </div>
  );
}

import React, { useCallback, useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Container,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { createPackage } from "../../api/packages";
import { getUser } from "../../api/user";
import { packageStatus } from "../../utils/packageStatus";
import { posibleComs } from "../../utils/coms";
import { showToast } from "../../utils/showToast";
import SearchDropdown from "../searchDropdown";
import useAuth from "../../hooks/useAuth";
import FormDivider from "../formsDivider";
import Stepper from "../stepper";
import ErrorMessage from "../errorMessage";
import { vehiclesTypes } from "../../data/index";
import { updateVehicle, createVehicle } from "../../api/vehicles";

export default function CreateVehicle({
  edit,
  defaultData,
  closeModal,
  fetch,
  setConfirmationModal,
  setPackagesCreated,
  vehicle,
  onCancel, // Propiedad para manejar la acción de cancelación
}) {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const methods = useForm({
    mode: "onSubmit",
    defaultValues: edit ? vehicle : {},
  });

  const submitValues = useCallback(
    async (values) => {
      setLoading(true);
      const parsedValues = {
        ...values,
        type: values.type?.name, // Assuming "type" is an object with a "name" property
      };

      try {
        console.log("el valor de edit : ", edit);
        if (edit) {
          await updateVehicle(vehicle.id, parsedValues);
          console.log("Vehículo actualizado:", parsedValues);
        } else {
          await createVehicle(parsedValues);
          console.log("Vehículo creado:", parsedValues);
        }
        setLoading(false);
        closeModal(false);
        fetch(true);
      } catch (error) {
        console.error("Error al guardar los cambios:", error);
        setLoading(false);
        // Aquí podrías mostrar un mensaje de error al usuario, por ejemplo, usando un toast
      }
    },
    [closeModal, edit, fetch, vehicle]
  );

  //console.log("datos del vehiculo para editar: ", vehicle);

  return (
    <Container className="mt-3">
      <Form onSubmit={methods.handleSubmit(submitValues)}>
        <Row>
          <Col xs="12" md="12" xl="12">
            <Label className="forms-input-title">NOMBRE DEL VEHÍCULO</Label>
            <Controller
              name="name"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="name"
                  className="p-2 px-3 forms-custom-input"
                  placeholder="Furgón Dodge"
                  disabled={edit}
                  defaultValue={defaultData?.name}
                />
              )}
              rules={{ required: "Ingrese el nombre del vehículo" }}
            />
            {methods.formState.errors?.name && (
              <ErrorMessage msg={methods.formState.errors?.name?.message} />
            )}
          </Col>
          <Col xs="12" md="12" xl="12">
            <Label className="forms-input-title">PATENTE</Label>
            <Controller
              name="plate"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="plate"
                  className="p-2 px-3 forms-custom-input"
                  placeholder="ABCD12"
                  disabled={edit}
                  defaultValue={defaultData?.plate}
                />
              )}
              rules={{ required: "Ingrese la patente del vehículo" }}
            />
            {methods.formState.errors?.plate && (
              <ErrorMessage msg={methods.formState.errors?.plate?.message} />
            )}
          </Col>

          <Col xs="6" md="6" xl="6" className="mt-3">
            <Label className="forms-input-title">TIPO DE VEHÍCULO</Label>
            <Controller
              name="type"
              control={methods.control}
              render={({ field }) => (
                <SearchDropdown
                  {...field}
                  options={vehiclesTypes}
                  placeholder="Selecciona"
                  selected={edit ? vehicle.type : field.value}
                  onChange={field.onChange}
                  disabled={edit}
                />
              )}
            />
          </Col>
          <Col xs="6" md="6" xl="6" className="mt-3">
            <Label className="forms-input-title">PUNTOS MÁXIMOS</Label>
            <Controller
              name="maxPoints"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  className="p-2 px-3 forms-custom-input"
                  placeholder="25"
                  disabled={edit}
                  defaultValue={defaultData?.maxPoints}
                />
              )}
              rules={{ required: "Ingrese los puntos máximos" }}
            />
            {methods.formState.errors?.maxPoints && (
              <ErrorMessage
                msg={methods.formState.errors?.maxPoints?.message}
              />
            )}
          </Col>

          <Col xs="6" md="6" xl="6" className="mt-3">
            <Label className="forms-input-title">
              PESO MÁXIMO (KILOGRAMOS)
            </Label>
            <Controller
              name="maxWeight"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="maxWeight"
                  className="p-2 px-3 forms-custom-input"
                  placeholder="1500"
                  disabled={edit}
                />
              )}
              rules={{ required: "Ingrese el peso máximo" }}
            />
            {methods.formState.errors?.maxWeight && (
              <ErrorMessage
                msg={methods.formState.errors?.maxWeight?.message}
              />
            )}
          </Col>
          <Col xs="6" md="6" xl="6" className="mt-3">
            <Label className="forms-input-title">VOLUMEN MÁXIMO (LITROS)</Label>
            <Controller
              name="maxVolume"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="maxVolume"
                  className="p-2 px-3 forms-custom-input"
                  placeholder="300"
                  disabled={edit}
                />
              )}
              rules={{ required: "Ingrese el volumen máximo" }}
            />
            {methods.formState.errors?.maxVolume && (
              <ErrorMessage
                msg={methods.formState.errors?.maxVolume?.message}
              />
            )}
          </Col>
        </Row>

        {/* Botones de acción  - ksandoval 16-05*/}
        <Row className="mt-4">
          <Col xs="6">
            <Button
              color="orange"
              className="w-100 bg-[#F8612C] text-white hover:bg-orange-500"
              type="submit"
              disabled={loading}
            >
              {edit ? "Guardar cambios" : "Guardar"}
              {loading && <Spinner size="sm" />}
            </Button>
          </Col>
          <Col xs="6">
            <Button
              color="white"
              className="w-100 text-black border-black"
              onClick={onCancel} // Maneja la acción de cancelar
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

import React, {useState, useEffect, useCallback} from "react";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {getUser, flexToken} from "../../../api/user";
import {iconList} from "../../../utils/iconList";
import useAuth from "../../../hooks/useAuth";
import {showToast} from "../../../utils/showToast";
import {posibleIntegrations} from "../../../utils/integrations";
import useModal from "../../../store/modal";

const CLIENT_ID_FLEX = process.env.REACT_APP_MELI_CLIENT_ID_FLEX;
const REDIRECT_URI = process.env.REACT_APP_MELI_REDIRECT_URI;
const ML_URL = `https://auth.mercadolibre.cl/authorization?response_type=code&client_id=${CLIENT_ID_FLEX}&redirect_uri=${REDIRECT_URI}`;

const ACTIONS = {
  MercadoLibre: () => {
    window.location.assign(ML_URL);
  }
};
const AddIntegrationButton = ({active, onClick}) => (
    <div
        onClick={active ? onClick : () => {
        }}
        className={`mt-2 w-full border-[2px] rounded-lg   text-center ${
            active
                ? "bg-[#FEF3D4] border-[#EFC02D] text-sm py-[14px] cursor-pointer font-bold text-black"
                : "text-gray-500 py-[12px] cursor-not-allowed"
        }`}
    >
      {active ? "VINCULAR NUEVA CUENTA" : "Próximamente"}
    </div>
);

const Integration = ({integration, deleteIntegration, changeAlias, clientId, handleModalClose}) => {
  const openModal = useModal(state => state.openModal);
  return (
      <>
        <div className="w-full flex flex-col relative bg-[#F9F9F9] rounded-lg py-1 px-2 font-bold">
        <span
            onClick={() => openModal("changeAlias", {...integration, changeAlias, clientId})}
            className="text uppercase text-black cursor-pointer hover:scale-[1.02] w-fit transition"
        >
          {integration?.sender_id ? integration?.sender_id : integration?.url ? integration?.url.slice(8) : integration.alias}
        </span>
          <span className="text-sm text-green-500">vinculado</span>
          <button
              onClick={() => openModal("deleteIntegration", {...integration, deleteIntegration, clientId, handleModalClose})}
              className="bg-gray-950 hover:bg-red-500 block px-2 rounded-lg absolute -right-2 -top-2 hover:scale-105 transition text-white w-fit"
          >
            -
          </button>
        </div>
      </>
  );
};

export default function Integrations() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [updateUser, setUpdateUser] = useState(true);
  const {user} = useAuth();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code");

  const deleteIntegration = useCallback((alias, integrationName) => {
    setUserData(prevUserData => ({
      ...prevUserData,
      integrations: prevUserData.integrations.filter(e => e.alias !== alias || e.integrationName !== integrationName),
    }));
  }, []);

  const changeAlias = useCallback((oldAlias, newAlias) => {
    setUserData(prevUserData => ({
      ...prevUserData,
      integrations: prevUserData.integrations.map(e => (e.alias === oldAlias ? {...e, alias: newAlias} : e)),
    }));
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (updateUser && user) {
        try {
          const {data: {user: userData}} = await getUser({id: user?.id});
          setUserData(userData || {});
          setUpdateUser(false);
        } catch (err) {
          console.error(`Error: ${err}`);
        }
      }
    };
    fetchUserData();
  }, [user, updateUser]);

  useEffect(() => {
    const verifyMLCode = async () => {
      if (!code || !!user.token) return;

      try {
        const resp = await flexToken({code, clientId: user.id});
        const {accessToken} = resp.data;
        console.log("accessToken completo: ", accessToken);
        console.log("resp", resp);

        if (!resp?.data?.ok) {
          showToast(resp?.data?.message, false);
          return;
        }

        setUpdateUser(true);
        showToast("Cuenta vinculada con Mercado Libre", true);
        navigate("/home/integrations");
      } catch (err) {
        showToast("Ha ocurrido un error al vincular su cuenta con Mercado Libre", false);
      }
    };

    verifyMLCode();
  }, [code, user, navigate]);

  const openModal = useModal(state => state.openModal);
  const handleIntegrationClick = (name) => {
    console.log(name)
    if (name === "MercadoLibre") {
      window.location.assign(ML_URL);

    } else if (name === "WooCommerce") {
      console.log('WooCommerce');
      openModal("AddWoocommerce", {handleModalClose});
    } else if (name === "Falabella") {
      console.log('falabella');
      openModal("AddFalabella", {handleModalClose});
    }  else if (name === "Sendu") {
    console.log('Sendu');
    openModal("AddSendu", {handleModalClose});
    }
  };

  const handleModalClose = () => {
    setUpdateUser(true);
  };

  return (
      <div className="w-100 all-users-container">
        <Breadcrumb className="custom-breadcrumb-container">
          <BreadcrumbItem className="d-flex align-items-center cursor-pointer" onClick={() => navigate("/home")}>
            <img src={iconList.home} alt="home" className="mr-2"/>
            <span>Inicio</span>
          </BreadcrumbItem>
          <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
            <span>Integraciones</span>
          </BreadcrumbItem>
        </Breadcrumb>
        <div className="d-flex justify-content-between">
        <span className="d-flex">
          <h2 className="h2-custom-size all-users-container__title">Integraciones</h2>
        </span>
        </div>


        <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4">
          {posibleIntegrations?.map((integration, index) => (
              <div key={index} className="col-span-1">
                <div className='card-custom'>
                  <div className="text-center mb-2 space-y-2">
                    <img src={iconList[integration.icon]} alt={integration.name}
                         className="bg-gray-50 p-5 w-full aspect-square rounded-3xl"/>
                    <h5 className="text-lg font-bold">{integration.name}</h5>
                    <AddIntegrationButton active={integration.value} onClick={() => handleIntegrationClick(integration.name)}/>
                  </div>
                  <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-5">
                    <ul className="list-none p-0 m-0">
                      {userData.integrations?.filter(i => i.integrationName === integration.name).map((integ, i) => (
                          <li key={i} className="border-b border-gray-200 last:border-0">
                            <Integration
                                integration={integ}
                                deleteIntegration={deleteIntegration}
                                changeAlias={changeAlias}
                                clientId={user.id}
                                handleModalClose={handleModalClose}
                            />
                          </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
          ))}
        </div>
      </div>
  );
}
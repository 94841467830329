import React, { useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { Form, Button } from "reactstrap";
import { iconList } from "../../utils/iconList";
import SearchDropdown from "../searchDropdown";
import ErrorMessage from "../errorMessage";
import { updatePackage } from "../../api/packages";
import { showToast } from "../../utils/showToast";

export default function ChangeStatusModal({ data, closeModal, updateData }) {
  const methods = useForm({ mode: "onSubmit" });
  const statusList = [
    { label: "Pendiente", value: '0' },
    { label: "Recogido", value: '1' },
    { label: "En bodega", value: '2' },
    { label: "En ruta", value: '3' },
    { label: "Entregado", value: '4' },
    { label: "Cancelado", value: '5' },
  ];

  const submitValues = useCallback(
    async (values) => {
      const packageUpdated = await updatePackage({
        id: data?._id,
        values: values,
      });

      if (packageUpdated?.error) {
        showToast("Error al actualizar el paquete", false);
      }

      if (packageUpdated?.data) {
        showToast("Paquete actualizado correctamente.", true);
        closeModal(false);
        updateData(true);
      }
    },
    [data, closeModal, updateData]
  );

  return (
    <div className="p-4">
      <div className="d-flex align-items-center">
        <span className="custom-asign-icon-container d-flex justify-content-center align-items-center">
          <img src={iconList.auto_fix} alt="auto_fix" />
        </span>
        <span className="ml-2 custom-asign-title">Cambiar estado</span>
      </div>
      <div className="custom-asign-divider" />
      <Form className="mt-3" onSubmit={methods.handleSubmit(submitValues)}>
        <Controller
          name="status"
          control={methods.control}
          render={({ field }) => (
            <SearchDropdown
              {...field}
              options={statusList}
              selected={
                statusList?.find(
                  (f) => f?.value === methods?.getValues("status")
                )?.label
              }
              placeholder="Seleccione el nuevo estado"
              onChange={field.onChange}
            />
          )}
          rules={{ required: "Seleccione el nuevo estado" }}
        />
        {methods.formState.errors?.status && (
          <ErrorMessage msg={methods.formState.errors?.status?.message} />
        )}
        <Button block className="custom-asign-btn">
          Cambiar estado
        </Button>
      </Form>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

export default function PaginationComponent({
  documents,
  pagination,
  page,
  pageSelected,
}) {
  const totalPages = Math.ceil(documents / pagination);
  const arr = [...Array(totalPages).keys()];

  // DvlprChris
  const [config, setConfig] = useState(null);
  const [storedColor, setStoredColor] = useState(null);
  useEffect(() => {
    try {
      const cachedConfig = localStorage.getItem("appConfig");
      setConfig(JSON.parse(cachedConfig));
      //obtener color de la API
      const configObject = JSON.parse(cachedConfig);
      const colorFromAPI = String(configObject.color);
      setStoredColor(colorFromAPI);
    } catch (error) {
      console.error(
        "Error al obtener la configuración del localStorage:",
        error
      );
    }
  }, []);

  const customColorFromAPI = { backgroundColor: storedColor };
  // DvlprChris

  let pageLimit = 7; // number of page links in pagination
  let start = 0; // starting page
  let end = pageLimit; // ending page

  // increment start page when current page is greater than 5
  if (pageSelected - 1 >= 0) {
    start = pageSelected - 3;
  }

  // if reaching end of pagination stop increment
  if (start + pageLimit >= totalPages) {
    start = totalPages - pageLimit;
  }

  // increment end page when current + 5 exceeds page limit
  if (pageSelected + 3 >= pageLimit) {
    end = pageSelected + 4;
    pageLimit = end;
    if (totalPages <= pageLimit) {
      pageLimit = totalPages;
    }
  }

  if (documents === 0) {
    return null;
  }

  return (
    <Pagination className="w-100">
      <PaginationItem disabled={pageSelected === 0}>
        <PaginationLink
          style={{ color: pageSelected !== 0 && "black" }}
          onClick={() => page(parseInt(pageSelected) - 1)}
        >
          Anterior
        </PaginationLink>
      </PaginationItem>
      {arr.map((e) => {
        if (e >= start && e < end && totalPages > 7 && pageSelected > 2) {
          return (
            <PaginationItem active={e === pageSelected} key={e}>
              <PaginationLink
              style={{ '--color-dinamico': storedColor }}
                className={`customPagination ${
                  pageSelected === e && "customPaginationSelected"
                }`}
                onClick={(e) =>
                  e.target.innerText === "..."
                    ? null
                    : page(parseInt(e.target.innerText) - 1)
                }
              >
              {/* <PaginationLink
                style={{
                  backgroundColor:
                    pageSelected === e ? storedColor : "transparent",
                  color: "black",
                }}
                className={customColorFromAPI}
                onClick={(e) =>
                  e.target.innerText === "..."
                    ? null
                    : page(parseInt(e.target.innerText) - 1)
                }
              > */}
                {start + 2 === e + 1
                  ? "..."
                  : end - 1 === e + 1 && e + 1 !== totalPages
                  ? "..."
                  : start + 1 === e + 1
                  ? 1
                  : end === e + 1
                  ? totalPages
                  : e + 1}
              </PaginationLink>
            </PaginationItem>
          );
        } else if (
          e >= start &&
          e < end &&
          totalPages > 7 &&
          pageSelected < 3
        ) {
          return (
            <PaginationItem active={e === pageSelected} key={e}>
              <PaginationLink
              style={{ '--color-dinamico': storedColor }}
                className={`customPagination ${
                  pageSelected === e && "customPaginationSelected"
                }`}
                // style={{
                //   backgroundColor:
                //     pageSelected === e ? storedColor : "transparent",
                //   color: "black",
                // }}
                // className={customColorFromAPI}
                onClick={(e) =>
                  e.target.innerText === "..."
                    ? null
                    : page(parseInt(e.target.innerText) - 1)
                }
              >
                {end - 1 === e + 1 && e + 1 !== totalPages
                  ? "..."
                  : start + 1 === e + 1
                  ? 1
                  : end === e + 1
                  ? totalPages
                  : e + 1}
              </PaginationLink>
            </PaginationItem>
          );
        } else if (totalPages < 8) {
          return (
            <PaginationItem active={e === pageSelected} key={e}>
              <PaginationLink
              style={{ '--color-dinamico': storedColor }}
                className={`customPagination ${
                  pageSelected === e && "customPaginationSelected"
                }`}
                // style={{
                //   backgroundColor:
                //     pageSelected === e ? storedColor : "transparent",
                //   color: "black",
                // }}
                // className={customColorFromAPI}
                onClick={(e) => {
                  page(parseInt(e.target.innerText) - 1);
                }}
              >
                {e + 1}
              </PaginationLink>
            </PaginationItem>
          );
        } else {
          return null;
        }
      })}
      <PaginationItem
        disabled={pageSelected + 1 === totalPages}
        onClick={() =>
          pageSelected + 1 < totalPages && page(parseInt(pageSelected) + 1)
        }
      >
        <PaginationLink
          style={{ color: pageSelected + 1 !== totalPages && "black" }}
        >
          Siguiente
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
}

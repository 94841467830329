export const posibleComs = [
    { label: "Cerrillos", value: "Cerrillos"},
    { label: "Cerro Navia", value: "Cerro Navia"},
    { label: "Colina", value: "Colina"},
    { label: "Conchalí", value: "Conchalí"},
    { label: "El Bosque", value: "El Bosque"},
    { label: "Estación Central", value: "Estación Central"},
    { label: "Huechuraba", value: "Huechuraba"},
    { label: "Independencia", value: "Independencia"},
    { label: "La Cisterna", value: "La Cisterna"},
    { label: "La Florida", value: "La Florida"},
    { label: "La Granja", value: "La Granja"},
    { label: "La Pintana", value: "La Pintana"},
    { label: "La Reina", value: "La Reina"},
    { label: "Lampa", value: "Lampa"},
    { label: "Las Condes", value: "Las Condes"},
    { label: "Lo Barnechea", value: "Lo Barnechea"},
    { label: "Lo Espejo", value: "Lo Espejo"},
    { label: "Lo Prado", value: "Lo Prado"},
    { label: "Macul", value: "Macul"},
    { label: "Maipú", value: "Maipú"},
    { label: "Ñuñoa", value: "Ñuñoa"},
    { label: "Padre Hurtado", value: "Padre Hurtado"},
    { label: "Pedro Aguirre Cerda", value: "Pedro Aguirre Cerda"},
    { label: "Peñalolén", value: "Peñalolén"},
    { label: "Providencia", value: "Providencia"},
    { label: "Puente Alto", value: "Puente Alto"},
    { label: "Pudahuel", value: "Pudahuel"},
    { label: "Quilicura", value: "Quilicura"},
    { label: "Quinta Normal", value: "Quinta Normal"},
    { label: "Recoleta", value: "Recoleta"},
    { label: "Renca", value: "Renca"},
    { label: "Santiago", value: "Santiago"},
    { label: "San Joaquín", value: "San Joaquín"},
    { label: "San Miguel", value: "San Miguel"},
    { label: "San Ramón", value: "San Ramón"},
    { label: "San Bernardo", value: "San Bernardo"},
    { label: "Vitacura", value: "Vitacura"},
  ];

import React from "react";
import { Modal, ModalBody } from 'reactstrap'
import Spinner from "../spinner";

export default function LoadingTable() {
  const cards = ["", "", "", ""];
  const fakeData = [...Array(10).keys()];
  const fakePages = [...Array(6).keys()];
  return (
    <div className="loading-container vh-100">
      <Modal isOpen={true} backdrop={false} centered={true} fade={false}>
        <ModalBody>
          <div className="w-100 d-flex justify-content-center mb-5 mt-3">
          <Spinner />
          </div>
          <h5 className="loading-title">Cargando vista</h5>
          <div className="text-center">
            <span className="loading-text">Por favor no actualices la página</span>
          </div>
        </ModalBody>
      </Modal>
      <div className="loading-container__empty-searchbar"></div>
      <div className="loading-container__empty-bar"></div>
      <div className="container-99">
        <div className="d-flex w-100 justify-content-between">
          {cards.map((_, index) => (
            <div
              key={index}
              className="loading-container__cards d-flex justify-content-between align-items-center"
            >
              <div className="w-100 loading-container__cards__fakeContainer">
                <div className="loading-container__cards__fakeTitle"></div>
                <div className="loading-container__cards__fakeContent"></div>
              </div>
              <div className="w-50 d-flex align-items-cneter justify-content-center">
                <div className="loading-container__cards__fakeValue"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="loading-container__table-container">
        <div className="d-flex w-100 justify-content-between">
          <div className="loading-container__table-container__point-container d-flex justify-content-between align-items-center">
            <div className="loading-container__table-container__point-container__point"></div>
            <div className="loading-container__table-container__point-container__point"></div>
          </div>
          <div className="loading-container__table-container__rectangle"></div>
        </div>
        <div className="loading-container__table-container__fakeTitleContainer d-flex justify-content-between">
          <div className="loading-container__table-container__fakeTitleContainer__fakeTitle1"></div>
          <div className="loading-container__table-container__fakeTitleContainer__fakeTitle2"></div>
          <div className="loading-container__table-container__fakeTitleContainer__fakeTitle3"></div>
          <div className="loading-container__table-container__fakeTitleContainer__fakeTitle4"></div>
          <div className="loading-container__table-container__fakeTitleContainer__fakeTitle5"></div>
          <div className="loading-container__table-container__fakeTitleContainer__fakeTitle6"></div>
        </div>
        {fakeData.map((_, index) => (
          <div
            className="loading-container__table-container__fakeDataContainer d-flex justify-content-between align-items-center"
            key={index}
          >
            <div className="loading-container__table-container__fakeDataContainer__fakeData1"></div>
            <div className="loading-container__table-container__fakeDataContainer__fakeData2">
              <div className="loading-container__table-container__fakeDataContainer__fakeData2__avatar"></div>
              <div className="loading-container__table-container__fakeDataContainer__fakeData2__fakeData"></div>
            </div>
            <div className="loading-container__table-container__fakeDataContainer__fakeData3"></div>
            <div className="loading-container__table-container__fakeDataContainer__fakeData4"></div>
            <div className="loading-container__table-container__fakeDataContainer__fakeData5"></div>
            <div className="loading-container__table-container__fakeDataContainer__fakeData6"></div>
          </div>
        ))}
        <div className="loading-container__table-container__fakePaginatorContainer d-flex justify-content-center w-100">
          <div className="loading-container__table-container__fakePaginatorContainer__fakeContainer d-flex justify-content-center">
            <div className="loading-container__table-container__fakePaginatorContainer__fakeContainer__nextPrevPage"></div>
            {fakePages.map((_, index) => <div className="loading-container__table-container__fakePaginatorContainer__fakeContainer__page" key={index}></div>)}
            <div className="loading-container__table-container__fakePaginatorContainer__fakeContainer__nextPrevPage"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

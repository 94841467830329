import axios from "axios";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_CONNECTION,
  timeout: 30000,
  mode: "no-cors",
  headers: {
    "Access-Control-Allow-Origin": "*"
  },
});

Axios.defaults.headers.common["content-type"] = "application/json";
Axios.defaults.headers.common["application"] = "web";
Axios.defaults.headers.common["Authorization"] = `${localStorage.getItem("accessToken")}`

export default Axios;

import React, {useState} from 'react'
import {
    Breadcrumb,
    BreadcrumbItem, Button, ButtonGroup
  } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { iconList } from "../../utils/iconList";
import Operation from "./operation"
import RoutesList from './routesList';


const RoutesBreadcrumb = () =>{
    const navigate = useNavigate()

    return (
        <Breadcrumb className="custom-breadcrumb-container">
          <BreadcrumbItem
            className="d-flex align-items-center cursor-pointer"
            onClick={() => navigate("/admin")}
          >
            <img src={iconList.home} alt="home" className="mr-2" />
            <span>Inicio</span>
          </BreadcrumbItem>
          <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
            <span>Retiros</span>
          </BreadcrumbItem>
          {/* nuevo titulo - ksandoval 10-05-2024 */}
          <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
            <span>Administrar retiros</span> 
          </BreadcrumbItem>
          
        </Breadcrumb>
    )
}

const Title = ({weekly, setWeekly}) =>{
    return (
        <h2 className="h2-custom-size all-orders-container__title d-flex justify-content-between">
            Todos los pedidos
            <ButtonGroup style={{position: 'static'}}>
              <Button
              style={{position: 'static'}}
                className={`custom-group-button ${
                    weekly
                    ? "selected-custom-btn bg-gray-200"
                    : "not-selected-custom-btn"
                }`}
                onClick={() => setWeekly(true)}
              >
                Lunes a viernes
              </Button>
              <Button
              style={{position: 'static'}}
                className={`custom-group-button ${
                  !weekly
                    ? "selected-custom-btn bg-gray-200"
                    : "not-selected-custom-btn"
                }`}
                onClick={() => setWeekly(false)}
              >
                Sábado
              </Button>
            </ButtonGroup>
          </h2>
    )
}

export default function Routes() {
    const [weekly, setWeekly] = useState(true)
  return (
    // se cambia componente section por div con estilos segun standar - ksandoval - 10-05
    <div className="w-100 all-orders-container mt-6">
        <RoutesBreadcrumb/>
        <Title weekly={weekly} setWeekly={setWeekly}/>
        <Operation weekly={weekly}/>
        <RoutesList/>
    </div>
  )
}

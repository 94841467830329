import React from "react";
import { Loader } from "@googlemaps/js-api-loader";

export default function RouteMap({places}) {
  const loader = new Loader({
    apiKey: "AIzaSyCg6QBDE2eqn0TKdpKhTnpb3-f6UTKqzxs",
    version: "weekly",
    libraries: ["places"],
  });

  const mapOptions = {
    center: {
      lat: -33.449176,
      lng: -70.664899,
    },
    zoom: 11,
  };

  loader
    .load()
    .then(async (google) => {
      const map = new google.maps.Map(
        document.getElementById("routeMap"),
        mapOptions
      );
        places.map(place => {
            new google.maps.Marker({
                position: place,
                map: map,
                })
        })

    })
    .catch((e) => {
      console.log(e);
      // do something
    });

  return <div id="routeMap" className="h-full"/>;
}
import Axios from "../config/axios-config";

export const getAllPackages = async ({
  id,
  page,
  limit,
  query,
  orderField,
  coms,
  status,
  startDate,
  endDate,
}) => {
  const packages = await Axios.get(
    `getClientPackages-by-status-paginated/${id}?page=${page}&limit=${limit}${query && `&${query.type}=${query.query}`}&orderField=${orderField}&sortType=desc${status ? `&status=${status}` : ""
    }${coms ? `&shipping_address.city.name=${coms}` : ""}${startDate
      ? `&startDateCreation=${startDate}&endDateCreation=${endDate}`
      : ""
    }`
  ).catch((err) => {
    return { error: err.response.data };
  });

  return packages;
};

// Dvlprchris
export const getOnePackage = async (id) => {
  const packageFound = await Axios.get(`getOnePackage/${id}`).catch((err) => {
    return { error: err.response.data };
  });


  return packageFound.data.result

}

export const countAllPackages = async ({
  id,
  page,
  limit,
  query,
  orderField,
  coms,
  status,
  startDate,
  endDate,
}) => {
  const packages = await Axios.get(
    `countPackages/${id}?page=${page}&limit=${limit}${query && `&${query.type}=${query.query}`}&orderField=${orderField}&sortType=desc${status ? `&status=${status}` : ""
    }${coms ? `&shipping_address.city.name=${coms}` : ""}${startDate
      ? `&startDateCreation=${startDate}&endDateCreation=${endDate}`
      : ""
    }`
  ).catch((err) => {
    return { error: err.response.data };
  });

  return packages;
}

export const getAllPackagesByStatus = async ({
  id,
  state,
  startDate,
  endDate,
}) => {
  const packages = await Axios.get(
    `status-packages/${id}?state=${state}&startDate=${startDate}&endDate=${endDate}`
  ).catch((err) => {
    return { error: err.response.data };
  });

  return packages;
};

export const updatePackageStatus = async ({ status, id }) => {
  const packageUpdated = await Axios.put(`package/update/${id}`, {
    value: { status },
  }).catch((err) => {
    return { error: err.response.data };
  });

  return packageUpdated;
};

export const createPackage = async ({ values }) => {
  const packageCreated = await Axios.post(`package`, values).catch((err) => {
    return { error: err.response.data };
  });

  return packageCreated;
};

export const loadFile = async ({ file, user }) => {
  const documentLoaded = await Axios.post(`add-excel`, {
    bdata: file,
    user,
  }).catch((err) => {
    return { error: err.response.data };
  });

  return documentLoaded;
};

export const loadCustomFile = async ({ file, user }) => {
  const documentLoaded = await Axios.post(`add-custom-excel`, {
    bdata: file,
    user,
  }).catch((err) => {
    return { error: err.response.data };
  });

  return documentLoaded;
};

export const getAdminPackages = async ({
  page,
  limit,
  query,
  orderField,
  sortType,
  coms,
  status,
  startDate,
  endDate,
  assignTo,
  client,
}) => {
  const queryClient = client && `&senderUser.sender_id=${client}`;
  const packages = await Axios.get(
    `getAdminAllPackages-by-status-paginated?page=${page}${client ? `&senderUser.sender_id=${client}`: ''}${assignTo ? `&assignTo=${assignTo}`:''}&limit=${limit}${query && `&${query.type}=${query.query}`}&orderField=${orderField}&sortType=desc${status ? `&status=${status}` : ""
    }${coms ? `&shipping_address.city.name=${coms}` : ""}${startDate
      ? `&startDateCreation=${startDate}&endDateCreation=${endDate}`
      : ""
    }`
  ).catch((err) => {
    return { error: err.response.data };
  });

  return packages;
};

export const getAllPackagesByStatusForAdmin = async ({
  state,
  startDate,
  endDate,
}) => {
  const packages = await Axios.get(
    `admin-status-packages?state=${state}&startDate=${startDate}&endDate=${endDate}`
  ).catch((err) => {
    return { error: err.response.data };
  });

  return packages;
};

export const updatePackage = async ({ id, values }) => {
  const updatedPackage = await Axios.put(`package/update/${id}`, {
    value: values,
  }).catch((err) => {
    return { error: err.response.data };
  });

  return updatedPackage;
};

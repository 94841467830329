import {BrowserRouter, Routes, Route} from "react-router-dom";
import AuthProvider from "./providers/authProviders";
import routes from './routes/routes'
import ModalProvider from "./components/modalProvider";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './scss/style.scss'
import {SelectedItemProvider} from "./components/menu/SelectedItemContext";
import RequireAdmin from "./components/RequireAdmin";

// function App() {
//   return (
//     <BrowserRouter>
//     <ModalProvider/>
//       <Routes>
//         {routes.map((route, index) => { 
//           return (
//           <Route
//             key={index}
//             path={route.path}
//             element={
//               <AuthProvider>
//                 <route.element routes={route.children} />
//               </AuthProvider>
//             }
//           />
//         )})}
//       </Routes>
//     </BrowserRouter>
//   );
// }
function App() {
  return (
      <BrowserRouter>
        <ModalProvider/>
        <SelectedItemProvider> {/* Dvlprchris- para manejar contexto sin prop directa */}
          <Routes>
            {routes.map((route, index) => {
              return (
                  <Route
                      key={index}
                      path={route.path}
                      element={
                        <AuthProvider>
                          {route.adminOnly ? (
                              <RequireAdmin>
                                <route.element routes={route.children}/>
                              </RequireAdmin>
                          ) : (
                              <route.element routes={route.children}/>)}
                        </AuthProvider>
                      }
                  />
              )
            })}
          </Routes>
        </SelectedItemProvider>
      </BrowserRouter>
  );
}

export default App;

import React, { useState } from "react";
import {
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import DropdownItem from './dropdownItem'

export default function SearchDropdown({
  options,
  placeholder,
  selected,
  onChange,
  disabled,
  className,
  tooltip
}) {
  const [dropdownToggle, setDropdownToggle] = useState(false);
  const [search, setSearch] = useState("");

  const callback = (value) => {
    onChange(value);
  };

  return (
    <Dropdown
      isOpen={dropdownToggle}
      toggle={() => options.length > 0 && setDropdownToggle(!dropdownToggle)}
      direction="down"
      disabled={disabled}
      className={className}
    >
      <DropdownToggle
        className={`d-flex align-items-center p-0 custom-searchable-dropdown w-100 ${
          disabled && "custom-searchable-dropdown__disabled"
        }`}
        disabled={disabled}
      >
        {dropdownToggle ? (
          <Input
            className="forms-custom-input-search-dropdown"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder={placeholder}
            disabled={disabled}
            onKeyDown={(e) => {
              if (e.code === "Space") {
                e.preventDefault();
                setSearch(search + " ");
              }
            }}
          />
        ) : (
          <Input
            className="forms-custom-input-search-dropdown"
            onChange={(e) => setSearch(e.target.value)}
            value={selected}
            placeholder={placeholder}
            disabled={disabled}
          />
        )}
      </DropdownToggle>
      <DropdownMenu className="w-100 custom-search-dropdown-menu">
        {options?.map((option) => {
          if (option?.label.toUpperCase().includes(search.toUpperCase())) {
            return (
              <DropdownItem value={option?.value} label={option?.label} callback={callback} setSearch={setSearch} tooltip={tooltip} />
            );
          }
          return null;
        })}
      </DropdownMenu>
    </Dropdown>
  );
}

import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardTitle,
  Label,
  Input,
  Col,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { iconList } from "../../../utils/iconList";
import SearchTerm from "../../../components/searchTerm";
import Modal from "../../../components/modal";
import Trash from "../../../assets/delete.svg";
import Edit from "../../../assets/edit.svg";
import Search from "../../../assets/search.svg";
import { vehicles } from "../../../data/index";
import CreateVehicle from "../../../components/createVehicle/createVehicle";
import PackageTabs from "../../../components/packageTabs";

export default function AdminFleet() {
  const navigate = useNavigate();
  const { items } = vehicles;

  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [editMode, setEditMode] = useState(false);

  const filteredItems = items.filter(
    (vehicle) =>
      vehicle.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.plate.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.type.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const tabContent = [
    <CreateVehicle
      closeModal={setOpenModal}
      fetch={fetch}
      vehicle={selectedVehicle}
      edit={editMode}
    />,
  ];

  const handleEditClick = (vehicle) => {
    setSelectedVehicle(vehicle);
    setOpenModalEdit(true);
    setEditMode(true);
  };

  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
    setEditMode(false);
  };

  return (
    <div>
      {/* Modal Confirma Eliminar */}
      <Modal
        size={"md"}
        open={openModalDelete}
        setOpen={() => setOpenModalDelete()}
        header={
          <div>
            <h1 className="text-xl font-bold">Eliminar vehículo</h1>
          </div>
        }
        body={
          <p>
            Esta acción eliminará el vehículo de tu flota. Es irreversible y no
            podrás deshacerlo. Puedes crear nuevos vehículos cuando quieras.
          </p>
        }
        footer={
          <div className="flex flex-auto justify-between items-center">
            <Button
              block
              color="orange"
              className=" py-2 m-2 border font-bold border-orange-500 text-orange-500 hover:text-red-600 hover:bg-red-300 hover:border-red-800"
            >
              Sí, eliminar
            </Button>
            <Button
              block
              color="orange"
              className=" py-2 m-2 border font-bold border-orange-500 text-orange-500 hover:text-red-600 hover:bg-red-300 hover:border-red-800"
            >
              No, mantener
            </Button>
          </div>
        }
      ></Modal>

      {/* Modal  editar */}
      <Modal
        size={"md"}
        open={openModalEdit}
        // setOpen={() => setOpenModalEdit()}
        setOpen={handleCloseModalEdit}
        header={
          <div>
            <h1 className="text-xl font-bold">Editar vehículo</h1>
          </div>
        }
        body={<PackageTabs tabContent={tabContent} />}
      />

      {/* Modal  crear */}
      <Modal
        size={"md"}
        open={openModal}
        setOpen={() => setOpenModal()}
        header={
          <div>
            <h1 className="text-xl font-bold">Crear vehículo</h1>
          </div>
        }
        body={<PackageTabs tabContent={tabContent} />}
      />

      <Breadcrumb className="custom-breadcrumb-container">
        <BreadcrumbItem
          className="d-flex align-items-center cursor-pointer"
          onClick={() => navigate("/admin")}
        >
          <img src={iconList.home} alt="home" className="mr-2" />
          <span>Inicio</span>
        </BreadcrumbItem>
        <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
          <span>Rutas y flota</span>
        </BreadcrumbItem>
        <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
          <span>Administrar flota</span>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="d-flex justify-content-between">
        <span className="w-100 d-flex justify-content-between">
          <h2 className="h2-custom-size all-users-container__title">
            Administrar flota
          </h2>
        </span>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <p className="text-sm">Gestiona y administra tu flota.</p>
      </div>
      <Card className="custom-inform-card">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <label>{items.length} vehículos creados</label>
          <div className="">
            <Button
              block
              color="orange"
              className="bg-orange-600 hover:bg-orange-500 text-white"
              onClick={() => setOpenModal(true)}
            >
              Crear nuevo vehiculo
            </Button>
          </div>
        </div>

        {/* incorporar aca el buscador */}
        <SearchTerm
          setSearchTerm={setSearchTerm}
          type={"text"}
          placeholder={"Buscar por nombre, patente o tipo de vehículo"}
        />
        {/* incorporar aca el buscador */}

        {/* generar card de vehiculos */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredItems.map((vehicle) => (
            <Card
              key={vehicle.id}
              className="my-2"
              color="light"
              style={{
                width: "18rem",
              }}
            >
              {/* <CardHeader>{vehicle.name}</CardHeader> */}
              <CardHeader className="flex justify-between items-center">
                <div>{vehicle.name}</div>
                <div className="flex gap-2">
                  <button
                    className="p-1 bg-transparent border-none"
                    onClick={() => handleEditClick(vehicle)}
                  >
                    <img
                      src={Edit}
                      alt="Edit"
                      className="w-4 h-4 text-gray-500 hover:text-gray-700"
                    />
                  </button>
                  <button
                    className="p-1 bg-transparent border-none"
                    onClick={() => setOpenModalDelete(true)}
                  >
                    <img
                      src={Trash}
                      alt="Delete"
                      className="w-4 h-4 text-gray-500 hover:text-gray-700"
                    />
                  </button>
                </div>
              </CardHeader>
              <CardBody className="text-sm">
                <Row>
                  <Col xs="6" md="6" xl="6">
                    Tipo:
                  </Col>
                  <Col xs="6" md="6" xl="6" className="justify-end items-end">
                    <strong>{vehicle.type}</strong>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs="6" md="7" xl="7">
                    Patente:
                  </Col>
                  <Col xs="6" md="5" xl="5" className="justify-end items-end">
                    <strong>{vehicle.plate}</strong>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs="6" md="7" xl="7">
                    Peso Máximo:
                  </Col>
                  <Col xs="6" md="5" xl="5" className="justify-end items-end">
                    <strong>{vehicle.maxWeight}</strong>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs="6" md="7" xl="7">
                    Volumen Máximo:
                  </Col>
                  <Col xs="6" md="5" xl="5" className="justify-end items-end">
                    <strong>{vehicle.maxVolume}</strong>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs="6" md="7" xl="7">
                    Puntos Máximos:
                  </Col>
                  <Col xs="6" md="5" xl="5" className="justify-end items-end">
                    <strong>{vehicle.maxPoints}</strong>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ))}
        </div>
      </Card>
    </div>
  );
}

import React, { useState } from "react";
import { Input } from "reactstrap";
import { iconList } from "../../utils/iconList";

export default function InputEdit({
  onChange,
  value,
  placeholder,
  type,
  onSend,
}) {
  const [disabled, setDisabled] = useState(true);

  return (
    <div
      className={`d-flex align-items-center ${
        disabled
          ? "custom-edit-input-forms-disabled"
          : "custom-edit-input-forms"
      }`}
    >
      <Input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        disabled={disabled}
        className="new-input-without-styles"
      />
      {disabled && (
        <img
          src={iconList.edit}
          alt="edit"
          className="edit-input-icon mr-3"
          onClick={() => setDisabled(false)}
        />
      )}
      {!disabled && (
        <div className="d-flex">
          <img
            src={iconList.userCheck}
            alt="edit"
            className="edit-input-icon mr-2"
            onClick={() => {
              onSend();
              setDisabled(true);
            }}
          />{" "}
          <div className="d-flex justify-content-center align-items-center delete-icon-my-account mr-3">
            <img
              src={iconList.redclose}
              alt="edit"
              onClick={() => setDisabled(true)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

import AddFile from "../../assets/addFile.svg";

function FileInput({ onSelectDocument }) {
    return (
        <div className="d-grid custom-add-file-package">
            <input
                type="file"
                id="fileInput"
                className="custom-file-input"
                onChange={(e) => onSelectDocument(e.target.files)}
                accept=".xlsx, .xls, .csv"
            />
            <div className="d-flex justify-content-center">
                <img src={AddFile} alt="addFile" />
            </div>
            <span className="text-center mt-3 custom-upload-file-text">
                Pincha aquí para seleccionar un archivo
            </span>
            <span className="text-center mt-3 mb-4 custom-upload-file-sub-text">
                O arrástralo aquí
            </span>
        </div>
    );
}

export default FileInput;
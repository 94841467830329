import React, { useState, useEffect, useCallback } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Input,
  FormGroup,
  Button,
  Form,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  getAllFaqs,
  changeFaqStatus,
  createFaq,
  editFaq,
  deleteFaqApi,
} from "../../../api/faqs";
import { showToast } from "../../../utils/showToast";
import { iconList } from "../../../utils/iconList";
import Modal from "../../../components/modal";
import ErrorMessage from "../../../components/errorMessage";
import SearchBar from "../../../components/searchBar";

export default function Faqs() {
  const methods = useForm({ mode: "onSubmit" });
  const navigate = useNavigate();
  const [open, setOpen] = useState(0);
  const [faqs, setFaqs] = useState([]);
  const [update, setUpdate] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openCreateModal, setCreateOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState({});
  const [imageToUpload, setImageToUpload] = useState();

  const toggle = (id) => {
    setOpen(id);
  };

  const changeStatus = (id, value) => {
    changeFaqStatus({ id, status: value }).then(() => {
      setUpdate(true);
    });
  };

  const createValues = useCallback(
    async (values) => {
      if (values?.optionAditional === "image") {
        const parsedValues = {
          title: values?.title,
          content: values?.content,
          videoUrl: null,
          image: imageToUpload,
        };

        const createdFaq = await createFaq({ values: parsedValues });
        if (createdFaq) {
          showToast(createdFaq?.data.message, true);
          setUpdate(true);
          setCreateOpenModal(false);
        }
        return null;
      }

      if (values?.optionAditional === "video") {
        const parsedValues = {
          title: values?.title,
          content: values?.content,
          videoUrl: values?.videoUrl,
          image: null,
        };

        const createdFaq = await createFaq({ values: parsedValues });
        if (createdFaq) {
          showToast(createdFaq?.data.message, true);
          setUpdate(true);
          setCreateOpenModal(false);
        }
        return null;
      }

      const parsedValues = {
        title: values?.title,
        content: values?.content,
        videoUrl: null,
        image: null,
      };

      const createdFaq = await createFaq({ values: parsedValues });

      if (createdFaq) {
        showToast(createdFaq?.data.message, true);
        setUpdate(true);
        setCreateOpenModal(false);
      }
    },
    [imageToUpload]
  );

  const toBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageToUpload(reader.result);
    };
  };

  const submitValues = useCallback(
    async (values) => {
      if (values?.optionAditional === "image") {
        const parsedValues = {
          title: values?.title,
          content: values?.content,
          videoUrl: null,
          image: imageToUpload,
          id: selectedFaq._id,
        };

        const editedFaq = await editFaq({ values: parsedValues });
        if (editedFaq) {
          showToast(editedFaq?.data.message, true);
          setUpdate(true);
          setOpenModal(false);
        }

        return null;
      }

      if (values?.optionAditional === "video") {
        const parsedValues = {
          title: values?.title,
          content: values?.content,
          videoUrl: values?.videoUrl,
          image: null,
          id: selectedFaq._id,
        };

        const editedFaq = await editFaq({ values: parsedValues });
        if (editedFaq) {
          showToast(editedFaq?.data.message, true);
          setUpdate(true);
          setOpenModal(false);
        }

        return null;
      }

      const parsedValues = {
        title: values?.title,
        content: values?.content,
        videoUrl: null,
        image: null,
        id: selectedFaq._id,
      };

      const editedFaq = await editFaq({ values: parsedValues });
      if (editedFaq) {
        showToast(editedFaq?.data.message, true);
        setUpdate(true);
        setOpenModal(false);
      }
    },
    [selectedFaq, imageToUpload]
  );

  useEffect(() => {
    if (openModal) {
      methods.setValue("title", selectedFaq?.title);
      methods.setValue("content", selectedFaq?.content);
      if (selectedFaq?.videoUrl) methods.setValue("optionAditional", "video");
      if (selectedFaq?.image) methods.setValue("optionAditional", "image");
      if (!selectedFaq?.image && !selectedFaq?.videoUrl)
        methods.setValue("optionAditional", null);
    }
  }, [openModal, selectedFaq, methods]);

  useEffect(() => {
    if (openCreateModal) {
      methods.setValue("title", null);
      methods.setValue("content", null);
      methods.setValue("optionAditional", null);
    }
  }, [openCreateModal, methods]);

  useEffect(() => {
    if (update) {
      getAllFaqs()
        .then((data) => setFaqs(data?.data?.faq))
        .catch((err) => console.error(err));
      setUpdate(false);
    }
  }, [update]);

  const deleteFaq = async () => {
    const deleted = await deleteFaqApi({ id: selectedFaq?._id });

    if (deleted) {
      setDeleteModal(false);
      setUpdate(true);
      showToast(deleted?.data?.message, true);
    }
  };

  methods.watch();

  return (
      // se cambia componente section por div con estilos segun standar - ksandoval - 10-05
    <div className="w-100 all-users-container mt-6">
      <Modal
        open={deleteModal}
        setOpen={() => setDeleteModal(false)}
        body={
          <div>
            <span>Está seguro de eliminar esta pregunta?</span>
            <div>
              <Label>{selectedFaq.title}</Label>
            </div>
            <div>
              <Label>{selectedFaq.content}</Label>
            </div>
            <Button
              block
              className="mt-4 bg-danger"
              onClick={() => deleteFaq()}
            >
              Eliminar
            </Button>
          </div>
        }
      />
      <Modal
        open={openModal}
        setOpen={() => setOpenModal(false)}
        body={
          <div>
            <Form onSubmit={methods.handleSubmit(submitValues)}>
              <Label className="mt-3 pl-3">Titulo</Label>
              <Controller
                name="title"
                control={methods.control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="title"
                    className="p-2 px-3 forms-custom-input"
                    defaultValue={selectedFaq?.title}
                  />
                )}
                rules={{ required: "Ingrese titulo" }}
              />
              {methods.formState.errors?.title && (
                <ErrorMessage msg={methods.formState.errors?.title?.message} />
              )}
              <br />
              <Label className="mt-3 pl-3">Respuesta</Label>
              <Controller
                name="content"
                control={methods.control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="textarea"
                    className="p-2 px-3 forms-custom-input mt-3"
                    defaultValue={selectedFaq?.content}
                  />
                )}
              />
              <Label className="mt-3 pl-3">Contenido adicional</Label>
              <Controller
                name="optionAditional"
                control={methods.control}
                render={({ field }) => (
                  <Input
                    type="select"
                    {...field}
                    className="p-2 px-3 forms-custom-input mt-3"
                  >
                    <option selected disabled hidden>
                      Seleccione una...
                    </option>
                    <option selected={selectedFaq?.videoUrl} value="video">
                      Video
                    </option>
                    <option selected={selectedFaq?.image} value="image">
                      Imagen
                    </option>
                  </Input>
                )}
              />
              {methods.getValues("optionAditional") === "video" && (
                <div>
                  <Label className="mt-3 pl-3">Ingrese URL de video</Label>{" "}
                  <Controller
                    name="videoUrl"
                    control={methods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        className="p-2 px-3 forms-custom-input mt-3"
                        defaultValue={selectedFaq?.videoUrl}
                      />
                    )}
                  />
                </div>
              )}
              {methods.getValues("optionAditional") === "image" && (
                <div>
                  <Label className="mt-3 pl-3">Seleccione imagen</Label>{" "}
                  <Input
                    type="file"
                    className="p-2 px-3 forms-custom-input mt-3"
                    onChange={(e) => toBase64(e.target.files[0])}
                  />
                </div>
              )}
              <Button block cla className="mt-4 bg-black">
                Editar
              </Button>
            </Form>
          </div>
        }
      />
      <Modal
        open={openCreateModal}
        setOpen={() => setCreateOpenModal(false)}
        body={
          <div>
            <Form onSubmit={methods.handleSubmit(createValues)}>
              <Label className="mt-3 pl-3">Titulo</Label>
              <Controller
                name="title"
                control={methods.control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="title"
                    className="p-2 px-3 forms-custom-input"
                  />
                )}
                rules={{ required: "Ingrese titulo" }}
              />
              {methods.formState.errors?.title && (
                <ErrorMessage msg={methods.formState.errors?.title?.message} />
              )}
              <br />
              <Label className="mt-3 pl-3">Respuesta</Label>
              <Controller
                name="content"
                control={methods.control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="textarea"
                    className="p-2 px-3 forms-custom-input mt-3"
                  />
                )}
              />
              <Label className="mt-3 pl-3">Contenido adicional</Label>
              <Controller
                name="optionAditional"
                control={methods.control}
                render={({ field }) => (
                  <Input
                    type="select"
                    {...field}
                    className="p-2 px-3 forms-custom-input mt-3"
                  >
                    <option selected disabled hidden>
                      Seleccione una...
                    </option>
                    <option selected={selectedFaq?.videoUrl} value="video">
                      Video
                    </option>
                    <option selected={selectedFaq?.image} value="image">
                      Imagen
                    </option>
                  </Input>
                )}
              />
              {methods.getValues("optionAditional") === "video" && (
                <div>
                  <Label className="mt-3 pl-3">Ingrese URL de video</Label>{" "}
                  <Controller
                    name="videoUrl"
                    control={methods.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        className="p-2 px-3 forms-custom-input mt-3"
                        defaultValue={selectedFaq?.videoUrl}
                      />
                    )}
                  />
                </div>
              )}
              {methods.getValues("optionAditional") === "image" && (
                <div>
                  <Label className="mt-3 pl-3">Seleccione imagen</Label>{" "}
                  <Input
                    type="file"
                    className="p-2 px-3 forms-custom-input mt-3"
                    onChange={(e) => toBase64(e.target.files[0])}
                  />
                </div>
              )}
              <Button block className="mt-4 bg-primary">
                Crear
              </Button>
            </Form>
          </div>
        }
      />
      {/* <SearchBar message={"Buscar por nombre o correo"} /> */}
      <Breadcrumb className="custom-breadcrumb-container">
        <BreadcrumbItem
          className="d-flex align-items-center cursor-pointer"
          onClick={() => navigate("/admin")}
        >
          <img src={iconList.home} alt="home" className="mr-2" />
          <span>Inicio</span>
        </BreadcrumbItem>
        <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
          <span>Preguntas frecuentes</span>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="d-flex justify-content-between">
        <span className="w-100 d-flex justify-content-between">
          <h2 className="h2-custom-size all-users-container__title">
            Preguntas frecuentes
          </h2>
          <Button
            className="bg-primary"
            onClick={() => setCreateOpenModal(true)}
          >
            Crear pregunta
          </Button>
        </span>
      </div>
      {faqs.length > 0 ? (
        <div className="custom-accordion-container px-3">
          <Accordion open={open} toggle={toggle} flush>
            {faqs.map((faq, index) => (
              <AccordionItem className="p-3" key={index}>
                <AccordionHeader targetId={index}>
                  <div className="d-flex justify-content-between w-100">
                    <FormGroup switch>
                      <span className="custom-header-text-form">
                        {faq.title}
                      </span>
                      <Input
                        type="switch"
                        defaultChecked={faq.isActive}
                        onClick={(e) => changeStatus(faq._id, e.target.checked)}
                      />
                    </FormGroup>
                    <div>
                      <Button
                        className="mr-2 custom-faq-btn bg-black"
                        onClick={() => {
                          setOpenModal(true);
                          setSelectedFaq(faq);
                        }}
                      >
                        Editar
                      </Button>
                      <Button
                        className="bg-danger custom-faq-btn mr-5"
                        onClick={() => {
                          setDeleteModal(true);
                          setSelectedFaq(faq);
                        }}
                      >
                        Eliminar
                      </Button>
                    </div>
                  </div>
                </AccordionHeader>
                <AccordionBody accordionId={index}>
                  <span className="custom-body-text-form">{faq.content}</span>
                  {faq?.image && (
                    <div className="w-100 d-flex justify-content-center mt-3">
                      <img
                        src={faq?.image}
                        alt="faqPreview"
                        className="custom-faqs-image"
                      />
                    </div>
                  )}
                  {faq?.videoUrl && (
                    <div className="w-100 d-flex justify-content-center mt-3">
                      <iframe
                        width="560"
                        height="315"
                        src={faq.videoUrl}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      />
                    </div>
                  )}
                </AccordionBody>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      ) : (
        <span>No se han encontrado preguntas.</span>
      )}
    </div>
  );
}

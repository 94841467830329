import React, { useCallback, useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  FormFeedback,
  Row,
  Col,
  Container,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { createPackage } from "../../api/packages";
import { getUser } from "../../api/user";
import { packageStatus } from "../../utils/packageStatus";
import { posibleComs } from "../../utils/coms";
import { showToast } from "../../utils/showToast";
import SearchDropdown from "../searchDropdown";
import useAuth from "../../hooks/useAuth";
import FormDivider from "../formsDivider";
import Stepper from "../stepper";
import ErrorMessage from "../errorMessage";
import { vehiclesTypes } from "../../data/index";
// import { updateUserApi, createUser } from "../../api/user";
import Modal from "../../../src/components/modal";

export default function CreateUserBeartrack({
  edit,
  defaultData,
  closeModal,
  fetch,
  setConfirmationModal,
  setPackagesCreated,
  user,
}) {
  // console.log("datos del usuario para editar: ", user);
  // console.log("valor de editmode: ", edit);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();

  const methods = useForm({
    mode: "onSubmit",
    defaultValues: edit ? user : {},
  });

  useEffect(() => {
    if (edit) {
      // console.log("en el useEffect");
      methods.setValue("pass", user?.pass || "beartrack2024");
    }

    // para probar el modal warning
    let timeout;
    const resetTimeout = () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        setOpenModalWarning(true);
      }, 10000); // 10 segundos de inactividad
    };

    const handleActivity = () => {
      resetTimeout();
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    resetTimeout(); // Inicializar el timeout al montar el componente

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
    };
  }, [edit, user, methods]);

  const ErrorMessage = ({ msg }) => (
    <FormFeedback className="d-block">{msg}</FormFeedback>
  );

  const FormDivider = ({ className }) => (
    <div className={`w-full border-t border-gray-300 my-2 ${className}`} />
  );

  const submitValues = useCallback(
    async (values) => {
      setLoading(true);

      try {
        if (edit) {
          //editar parametros de entrada aca - ksandoval - 22-05
          console.log("editar");
          // console.log(user._id, values);
          // await updateUserApi(user._id, values); // Usa editUser para editar el usuario
          console.log("Usuario actualizado:", values);
        } else {
          console.log("crear");
          // await createUser(values); // Usa createUser para crear un nuevo usuario
          console.log("Usuario creado:", values);
        }
        setLoading(false);
        closeModal(false);
        fetch(true);
      } catch (error) {
        console.error("Error al guardar los cambios:", error);
        setLoading(false);
        // Aquí podrías mostrar un mensaje de error al usuario, por ejemplo, usando un toast
      }
    },
    [closeModal, edit, fetch, user]
  );

  const [openModalWarning, setOpenModalWarning] = useState(false);

  return (
    <div className="">
      <Container className="mt-0">
        <Form onSubmit={methods.handleSubmit(submitValues)}>
          <p>
            Crea excepcionalmente un usuario. Los repartidores deben crear sus
            perfiles a través de la App. Los vendedores desde el portal público.
          </p>
          <FormDivider className="mb-0"></FormDivider>
          <Row className="mt-0">
            <h1 className="text-black font-semibold text-xl mb-4 mt-0">
              Datos personales
            </h1>
            <Col xs="12" md="12" xl="12">
              <Label className="forms-input-title">Nombre </Label>
              <Controller
                name="name"
                control={methods.control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="name"
                    className="p-2 px-3 forms-custom-input"
                    placeholder="Luis Gonzalez"
                    disabled={edit}
                    defaultValue={defaultData?.name}
                  />
                )}
                rules={{ required: "Ingrese el nombre del usuario" }}
              />
              {methods.formState.errors?.name && (
                <ErrorMessage msg={methods.formState.errors?.name?.message} />
              )}
            </Col>
            <h1 className="text-black font-semibold text-xl mb-0 mt-4">
              Seguridad
            </h1>
            <FormDivider></FormDivider>

            <Col xs="12" md="12" xl="12" className="mb-4">
              <Label className="forms-input-title">Tipo de usuario</Label>
              <Row>
                <Col>
                  <Controller
                    name="role"
                    control={methods.control}
                    rules={{ required: "Seleccione un tipo de usuario" }}
                    render={({ field }) => (
                      <FormGroup check className="custom-radio">
                        <Input
                          {...field}
                          type="radio"
                          value="client"
                          disabled={edit}
                          checked={field.value === "client"}
                          className="custom-control-input"
                        />
                        <Label check className="custom-control-label">
                          Vendedor
                        </Label>
                      </FormGroup>
                    )}
                  />
                  {methods.formState.errors?.role && (
                    <ErrorMessage
                      msg={methods.formState.errors?.role?.message}
                    />
                  )}
                </Col>
                <Col>
                  <Controller
                    name="role"
                    control={methods.control}
                    rules={{ required: "Seleccione un tipo de usuario" }}
                    render={({ field }) => (
                      <FormGroup check className="custom-radio">
                        <Input
                          {...field}
                          type="radio"
                          value="delivery"
                          disabled={edit}
                          checked={field.value === "delivery"}
                          className="custom-control-input"
                        />
                        <Label check className="custom-control-label">
                          Repartidor
                        </Label>
                      </FormGroup>
                    )}
                  />
                  {methods.formState.errors?.role && (
                    <ErrorMessage
                      msg={methods.formState.errors?.role?.message}
                    />
                  )}
                </Col>
              </Row>
            </Col>

            <Col xs="12" md="12" xl="12" className="mb-4">
              <Label className="forms-input-title">Correo electrónico</Label>
              <Controller
                name="email"
                control={methods.control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="email"
                    className="p-2 px-3 forms-custom-input"
                    placeholder="luis@otschile.cl"
                    disabled={edit}
                    defaultValue={defaultData?.email}
                  />
                )}
                rules={{ required: "Ingrese un correo electrónico valido" }}
              />
              {methods.formState.errors?.email && (
                <ErrorMessage msg={methods.formState.errors?.email?.message} />
              )}
            </Col>

            <Col xs="12" md="12" xl="12" className="mb-4">
              <Label className="forms-input-title">Contraseña</Label>
              <Controller
                name="pass"
                control={methods.control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text" //cambiar a type password
                    className="p-2 px-3 forms-custom-input"
                    placeholder="*********"
                    defaultValue={
                      edit ? user.pass || "beartrack2024" : defaultData?.pass
                    }
                    disabled={edit}
                  />
                )}
                // rules={{ required: "Ingrese la constraseña del usuario" }}
                rules={{
                  required: edit ? false : "Ingrese la contraseña del usuario",
                }}
              />
              {methods.formState.errors?.pass && (
                <ErrorMessage msg={methods.formState.errors?.pass?.message} />
              )}
            </Col>
          </Row>
          {/* Botones de acción  - ksandoval 16-05*/}
          <Row className="mt-4">
            <Col xs="12">
              <Button
                color="orange"
                className="w-100 bg-[#F8612C] text-white hover:bg-orange-500"
                type="submit"
                disabled={loading}
              >
                {edit ? "Guardar cambios" : "Crear usuario"}
                {loading && <Spinner size="sm" />}
              </Button>
            </Col>
            <Col xs="12" className="mt-2">
              <Button
                color="white"
                className="w-100 text-black border-black"
                onClick={() => closeModal(false)} // Maneja la acción de cancelar
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </Form>

        <Modal
          size={"md"}
          open={openModalWarning}
          setOpen={() => setOpenModalWarning()}
          header={
            <div>
              <h1 className="text-xl font-bold">Adventencia</h1>
            </div>
          }
          body={
            <div>
              <p>
                Tienes cambios sin guardar. ¿Estás seguro que deseas continuar?
                Se perderán tus modificaciones.
              </p>
            </div>
          }
          footer={
            <Container className="mt-0">
            <Row className="mt-4">
            <Col xs="12">
                <Button
                  color="orange"
                  className="w-100 bg-[#D92D20] text-white hover:bg-[#B42318]"
                  type="button"
                  onClick={() => closeModal(false)} // Maneja la acción de cancelar
                >
                  Sí, descartar
                </Button>
              </Col>
              
              <Col xs="12" className="mt-2">
                <Button
                  color="white"
                  className="w-100 text-black border-[#D0D5DD] hover:bg-[#D0D5DD]"
                  onClick={() => closeModal(false)} // Maneja la acción de cancelar
                >
                  Continuar editando
                </Button>
              </Col>
            </Row>
            </Container>
          }
        ></Modal>
      </Container>
    </div>
  );
}

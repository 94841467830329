import FileInput from "../../fileInput";
import { useState } from "react";
import * as XLSX from "xlsx";
import { updateUserApi } from "../../../api/user";
import useModal from "../../../store/modal";
import useAuth from "../../../hooks/useAuth";

const tableLabels = {
    receiverName: "Nombre*",
    receiverPhone: "Telefono*",
    streetName: "Direccion*",
    city: "Comuna*",
    type: "Tipo de paquete",
    comments: "Comentario",
    orderId: "Numero de orden",
    comment: "Comentario referencia",
    deliveryPreference: "Preferencia de envio"

}

const initialCustomTableFields = {
    receiverName: null,
    receiverPhone: null,

    streetName: null,
    city: null,
    type: null,
    comments: null,

    orderId: null,
    comment: null,
    deliveryPreference: null
}



function CreateCustomTable() {
    const closeModal = useModal((state) => state.closeModal);
    const { userId } = useModal(state => state.props);
    const [headers, setHeaders] = useState([]);
    const [customTableFields, setCustomTableFields] = useState(initialCustomTableFields);

    const updateCustomTableFields = (field, value) => {
        setCustomTableFields({ ...customTableFields, [field]: headers.indexOf(value) });
    };

    const handleFileUpload = (e) => {
        const file = e;
        const reader = new FileReader();

        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            // Obtener la primera hoja del libro
            const sheet = workbook.Sheets[workbook.SheetNames[0]];

            // Obtener los encabezados de las columnas en la primera fila
            const headerRow = XLSX.utils.sheet_to_json(sheet, { header: 1 })[0];

            setHeaders(headerRow);
        };

        reader.readAsArrayBuffer(file);
    };

    return (
        <>
            <h1 className="text-sm text-center">

                Para poder homologar los datos correctamente, debes cargar una plantilla (de tipo excel) para poder extraer los nombres de las columnas

            </h1>
            <>
                {headers.length === 0 ? <div className="flex justify-center my-2">
                    <FileInput onSelectDocument={(file) => { handleFileUpload(file[0]) }} />

                </div> :
                    <section className="w-[90%] flex flex-col gap-1 mx-auto">
                        {Object.keys(customTableFields).map((field, index) => {
                            return (
                                <div key={index} className="flex justify-between my-2 gap-2">
                                    <span className="text-sm whitespace-nowrap font-semibold">{tableLabels[field]}</span>
                                    <select
                                        onChange={(e) => updateCustomTableFields(field, e.target.value)}
                                        className="p-2 text-xl " name="" id="">
                                        <option value="">Selecciona un campo</option>
                                        {headers.map((header, index) => {
                                            return (
                                                <option key={index} value={header}>{header}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            )
                        })}
                        <button
                            className="bg-green-400 text-gray-200 p-2 rounded-md hover:scale-105 transition"
                            onClick={() => {
                                // console.log(userId)
                                updateUserApi({ userId, values: { customTemplate: customTableFields } }).then((res) => {
                                    if (res) {
                                        closeModal(false);
                                    }
                                }
                                )
                            }}
                        >Confirmar</button>
                    </section>

                }
            </>

        </>
    );
}

export default CreateCustomTable;
import React from "react";
import { Button } from "reactstrap";
import { iconList } from "../../utils/iconList";
import { activateUserApi } from "../../api/user";

export default function UnBlockModal({ userId, closeModal, update }) {
  const sendUnBlock = () => {
    activateUserApi({ userId: userId, status: true }).then((data) => {
      closeModal(false);
      update(true);
    });
  };

  return (
    <div className="custom-container-unblock-modal">
      <div className="d-flex align-items-center">
        <span className="custom-unlock-icon-unblock-modal">
          <img src={iconList.unlock} alt="unlock" />
        </span>
        <span className="custom-unblock-modal-title">Desbloquear usuario</span>
      </div>
      <div className="custom-unblock-modal-divider"></div>
      <div>
        <span className="custom-unblock-modal-text">
          Esto reactivará al usuario. Tendrá acceso nuevamente a la plataforma.
        </span>
      </div>
      <Button
        block
        className="custom-unblock-active-btn"
        onClick={() => sendUnBlock()}
      >
        Desbloquear usuario
      </Button>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardTitle,
  Collapse,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Button,
  Spinner,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { getActiveUsers, getNewUsers, deleteUserApi } from "../../../api/user";
import SearchTerm from "../../../components/searchTerm";
import Modal from "../../../components/modal";
import Trash from "../../../assets/delete.svg";
import Edit from "../../../assets/edit.svg";
import Search from "../../../assets/search.svg";
import SearchBar from "../../../components/searchBar";

import { iconList } from "../../../utils/iconList";
import BlockModal from "../../../components/blockModal";
import UnblockModal from "../../../components/unblockModal";
import EditUserModal from "../../../components/editUserModal";
import { showToast } from "../../../utils/showToast";
import PackageTabs from "../../../components/packageTabs";
import CreateUserBeartrack from "../../../components/createUserBeartrack/createUserBeartrack";
import CreateClientBeartrack from "../../../components/createClientBeartrack/createClientBeartrack";
import { toast } from "react-toastify";
import BlockDeleteUserBeartrack from "../../../components/blockDeleteUserBeartrack/blockDeleteUserBeartrack";
import CreateDeliveryBeartrack from "../../../components/createDeliveryBeartrack/createDeliveryBeartrack";

export default function BeartrackUsers() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  // Variables para paginación
  // comentario 27-05
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Modales
  const [blockMode, setBlockMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalClient, setOpenModalClient] = useState(false);
  const [openModalDelivery, setOpenModalDelivery] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEditClient, setOpenModalEditClient] = useState(false);
  const [openModalEditDelivery, setOpenModalEditDelivery] = useState(false);
  const [openModalBlock, setOpenModalBlock] = useState(false);

  useEffect(() => {
    if (loading || typeof searchTerm === "string") {
      fetchUsers(currentPage).then();
    }
    //dvlprchris  - 27-05
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [loading, currentPage, searchTerm]);

  const handleCloseModalEdit = () => {
    setOpenModalEditClient(false);
    setOpenModalEditDelivery(false);
    setOpenModalBlock(false);
    setEditMode(false);
  };
  const fetchUsers = async (page) => {
    const toastId = toast.loading("Cargando usuarios..."); // Muestra el indicador de carga
    try {
      const { data } = await getActiveUsers(page, 10, searchTerm);
      setUsers(data?.users);
 
      setTotalPages(data?.totalPages);
      setLoading(false);
      toast.update(toastId, {
        render: "Usuarios cargados",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
    } catch (error) {
      console.error("Error fetching active users", error);
      setLoading(false);
      toast.update(toastId, {
        render: "Error al cargar usuarios",
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setLoading(true);
  };

  const tabContent = [
    <CreateUserBeartrack
      closeModal={setOpenModal}
      cerrarModal={handleCloseModalEdit}
      fetch={fetchUsers}
      user={selectedUser}
      edit={editMode}
    />,
  ];

  const tabContentClient = [
    <CreateClientBeartrack
      closeModal={setOpenModalClient}
      cerrarModal={handleCloseModalEdit}
      fetch={fetchUsers}
      user={selectedUser}
      edit={editMode}
    />,
  ];

  const tabContentDelivery = [
    <CreateDeliveryBeartrack
      closeModal={setOpenModalDelivery}
      cerrarModal={handleCloseModalEdit}
      fetch={fetchUsers}
      user={selectedUser}
      edit={editMode}
    />,
  ];

  const tabContentDelete = [
    <BlockDeleteUserBeartrack
      closeModal={setOpenModalDelete}
      cerrarModal={handleCloseModalEdit}
      fetch={fetchUsers}
      user={selectedUser}
      block={blockMode}
    />,
  ];

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setEditMode(true);
    if (user.role === "delivery") {
      setOpenModalEditDelivery(true);
    } else {
      setOpenModalEditClient(true);
    }

    // setTimeout(() => {
    //   setOpenModalEditClient(false);
    //   setOpenModalEditDelivery(false);
    // }, 5000)
  };

  const handleDeleteUser = (user) => {
    setSelectedUser(user);
    setOpenModalDelete(true);
  };

  const handleBlockUser = (user) => {
    setSelectedUser(user);
    setOpenModalBlock(true);
    setBlockMode(true);
  };

  const menuOptions = [
    {
      title: "Editar usuario",
      subtitle: "Modifica algunos datos como el correo, rol o contraseña.",
      onClick: handleEditUser,
    },
    {
      title: "Bloquear usuario",
      subtitle:
        "Prohibe temporalmente el acceso a la plataforma. No se elimina el registro.",
      onClick: handleBlockUser,
    },
    {
      title: "Eliminar usuario",
      subtitle:
        "Eliminar permanentemente este usuario. Esta acción le quita el acceso a la plataforma.",
      onClick: handleDeleteUser,
    },
  ];


  const handleCloseModalBlock = () => {
    setOpenModalBlock(false);
    setBlockMode(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);

  const handleResize = () => {
    if (window.innerWidth >= 1400) {
      setCardsPerRow(3);
      setCardWidth("23rem");
      setCardPadding("my-1 mx-1");
    } else if (window.innerWidth >= 1080) {
      setCardsPerRow(2);
      setCardWidth("30rem");
      setCardPadding("m-1");
    } else {
      setCardsPerRow(1);
      setCardWidth("100%");
      setCardPadding("m-1");
    }
  };

  const [cardsPerRow, setCardsPerRow] = useState(3);
  const [cardWidth, setCardWidth] = useState("23rem");
  const [cardPadding, setCardPadding] = useState("my-1 mx-1");

  // nuevo boton multiOpcion
  const createOptions = [
    {
      title: "Crear vendedor",
      subtitle:
        "Crea un usuario tipo vendedor/cliente, tendrá acceso al dashboard de pedidos.",
      onClick: setOpenModalClient,
    },
    {
      title: "Crear repartidor",
      subtitle:
        "Crea un usuario tipo repartidor, sincronizado con la app de repartos.",
      onClick: setOpenModalDelivery,
    },
  ];

  const handleMenuItemClick = (item) => {
    console.log(`Clicked on ${item.title}`);
    // Cierra el menú después de hacer clic en un elemento
    // toggle();

    if (item.title === "Crear vendedor") {
      setOpenModalClient(true);
    } else if (item.title === "Crear repartidor") {
      setOpenModalDelivery(true);
    }
  };

  const renderMenuList = () => {
    return createOptions.map((item, index) => (
      <React.Fragment key={index}>
        <DropdownItem
          onClick={() => handleMenuItemClick(item)}
          onMouseEnter={() => setHoveredIcon(item.title)}
          onMouseLeave={() => setHoveredIcon(null)}
        >
          <div className="flex items-center gap-1 px-2">
            <div>
              <div
                className="font-medium text-md"
                style={{
                  color: hoveredIcon === item.title ? "#F8612C" : "#000",
                  transition: "color 0.05s",
                }}
              >
                {item.title}
              </div>
              <div
                className="font-extralight text-sm"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {item.subtitle}
              </div>
            </div>
          </div>
        </DropdownItem>
        {index < createOptions.length - 1 && <DropdownItem divider />}
      </React.Fragment>
    ));
  };

  return (
    <div className="mt-6 w-full h-full">
      {/* Modal  eliminar */}
      <Modal
        size={"md"}
        open={openModalDelete}
        setOpen={() => setOpenModalDelete()}
        body={<PackageTabs tabContent={tabContentDelete} />}
      ></Modal>

      {/* Modal bloquear */}
      <Modal
        size={"md"}
        open={openModalBlock}
        // setOpen={() => setOpenModalBlock()}
        setOpen={handleCloseModalBlock}
        body={<PackageTabs tabContent={tabContentDelete} />}
      ></Modal>

      {/* Modal  crear Client */}
      <Modal
        size={"md"}
        open={openModalClient}
        setOpen={() => setOpenModalClient()}
        body={<PackageTabs tabContent={tabContentClient} />}
      ></Modal>

      {/* Modal  editar Client*/}
      <Modal
        size={"md"}
        open={openModalEditClient}
        setOpen={handleCloseModalEdit}
        body={<PackageTabs tabContent={tabContentClient} />}
      ></Modal>

      {/* Modal  crear Delivery */}
      <Modal
        size={"md"}
        open={openModalDelivery}
        setOpen={() => setOpenModalDelivery()}
        body={<PackageTabs tabContent={tabContentDelivery} />}
      ></Modal>

      {/* Modal  editar Client*/}
      <Modal
        size={"md"}
        open={openModalEditDelivery}
        setOpen={handleCloseModalEdit}
        body={<PackageTabs tabContent={tabContentDelivery} />}
      ></Modal>

      {/* Título y descripción */}
      <div className="d-flex justify-content-between">
        <span className="w-100 d-flex justify-content-between">
          <h2 className="h2-custom-size all-users-container__title font-bold">
            Usuarios activos
          </h2>
        </span>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <p className="text-sm">Gestiona y edita los usuarios activos.</p>
      </div>

      {/* Contenedor de cards */}
      <Card className="custom-inform-card scrollable-card ">
        <div className="d-flex justify-content-between items-end mb-2">
          {/*<label>{users.length} usuarios creados</label>*/}
          <div
            className=" d-flex justify-content-end "
            style={{
              marginLeft: "auto",
              width: "16rem",
              minWidth: "200px",
            }}
          >
            <Navbar color="none" light expand="sm" className="">
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav>
                      <Button
                        block
                        width="10px"
                        height="10px"
                        className="bg-slate-50 hover:bg-slate-200 text-black"
                      >
                        <span
                          className="material-symbols-outlined"
                          style={{
                            border: "none",
                            marginRight: "0",
                            fontSize: "20px",
                            color: "black",
                            backgroundColor: "transparent !important",
                          }}
                        >
                          add
                        </span>{" "}
                        Crear nuevo usuario
                      </Button>
                    </DropdownToggle>
                    <DropdownMenu
                      end
                      style={{
                        marginTop: "-4px",
                        width: "26rem",
                        minWidth: "200px",
                        marginLeft: "-200px",
                      }}
                    >
                      {renderMenuList()}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </Collapse>
            </Navbar>
          </div>
        </div>

        {/* incorporar aca el buscador */}
        <SearchTerm
          setSearchTerm={setSearchTerm}
          type={"text"}
          placeholder="Buscar por correo electrónico"
        />
        {/* incorporar aca el buscador */}

        {/* Cards */}
        <div
          className={`grid grid-cols-1 md:grid-cols-${cardsPerRow} lg:grid-cols-${cardsPerRow} xl:grid-cols-${cardsPerRow} gap-4 mt-3`}
        >
          {users.map((user, index) => (
            
            <Card
              key={user.id}
              className={`${cardPadding} rounded-xl ${
                hoveredCardIndex === index ? "highlighted-card" : ""
              }`}
              color="white"
              style={{ width: cardWidth }}
              onMouseEnter={() => setHoveredCardIndex(index)}
              onMouseLeave={() => setHoveredCardIndex(null)}
            >
              <CardHeader
                className="flex justify-between items-center h-[50px]"
                color="white"
              >
                <div>{user.name}</div>
                <UncontrolledDropdown>
                  <DropdownToggle nav>
                    <span className="material-symbols-outlined">more_vert</span>
                  </DropdownToggle>

                  <DropdownMenu
                    end
                    style={{
                      marginTop: "-1rem",
                      width: "350px",
                      minWidth: "100px",
                      marginLeft: "0px",
                    }}
                  >
                    {menuOptions.map((item, index) => (
                      <React.Fragment key={index}>
                        <DropdownItem
                          onClick={() => item.onClick(user)}
                          onMouseEnter={() => setHoveredIcon(item.title)}
                          onMouseLeave={() => setHoveredIcon(null)}
                        >
                          <div className="  gap-1 px-2">
                            <div>
                              <p
                                className="text-sm text-[12px] font-bold text-wrap"
                                style={{
                                  color:
                                    hoveredIcon === item.title
                                      ? "#F8612C"
                                      : "#000",
                                  transition: "color 0.05s",
                                  fontSize: "12px",
                                }}
                              >
                                {item.title}
                              </p>
                              <p className="text-xs text-[10px] text-wrap">
                                {item.subtitle}
                              </p>
                            </div>
                          </div>
                        </DropdownItem>
                        {index < menuOptions.length - 1 && (
                          <DropdownItem divider />
                        )}
                      </React.Fragment>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </CardHeader>
              <CardBody className="text-sm">
                <Row>
                  <Col xs="6" md="4" xl="4">
                    Rol:
                  </Col>
                  <Col
                    xs="6"
                    md="8"
                    xl="8"
                    className="justify-end items-end text-right"
                  >
                    {user.role}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs="6" md="4" xl="4">
                    Teléfono:
                  </Col>
                  <Col
                    xs="6"
                    md="8"
                    xl="8"
                    className="justify-end items-end text-right"
                  >
                    {user.phone}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs="6" md="4" xl="4">
                    Correo:
                  </Col>
                  <Col
                    xs="6"
                    md="8"
                    xl="8"
                    className="justify-end items-end text-right"
                  >
                    {user.email}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs="6" md="4" xl="4">
                    Contraseña:
                  </Col>
                  <Col
                    xs="6"
                    md="8"
                    xl="8"
                    className="justify-end items-end text-right"
                  >
                    *******************
                  </Col>
                </Row>
                <hr />
              </CardBody>
            </Card>
          ))}
        </div>

        {/* Controles de paginación */}
        <div className="d-flex justify-content-between align-items-center mt-3">
          <Button
            color="orange"
            className="bg-[#F8612C] text-white hover:bg-orange-500"
            type="button"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Anterior
          </Button>
          <span>
            Página {currentPage} de {totalPages}
          </span>
          <Button
            color="orange"
            className="bg-[#F8612C] text-white hover:bg-orange-500"
            type="button"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Siguiente
          </Button>
        </div>
      </Card>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Breadcrumb, BreadcrumbItem, Button, ButtonGroup } from "reactstrap";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../../components/searchBar";
import Card from "../../../components/card";
import Clock from "../../../assets/clock.svg";
import Box from "../../../assets/box.svg";
import Rocket from "../../../assets/rocket.svg";
import CustomTable from "../../../components/customTable";
import useAuth from "../../../hooks/useAuth";
import { getAllPackages, getAllPackagesByStatus } from "../../../api/packages";
import {
  packageStatus,
  packageStatusStyles,
} from "../../../utils/packageStatus";
import { debounce } from "../../../utils/debounce";
import { iconList } from "../../../utils/iconList";
import LoadingTable from "../../../components/loadingTable";
import EvidenceView from "../../../components/evidenceView";
import moment from "moment";

export default function DeliveredView() {
  const navigate = useNavigate();
  const { user, isLoading } = useAuth();
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [pageSelected, setPageSelected] = useState(0);
  const [inStorage, setInStorage] = useState(0);
  const [picked, setPicked] = useState(0);
  const [delivered, setDelivered] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [query, setQuery] = useState("");
  const [viewEvidence, setViewEvidence] = useState(false);
  const [packageEvidence, setPackageEvidence] = useState({});
  const [filters, setFilters] = useState({});
  const [showResume, setShowResume] = useState({
    startDate: moment().startOf("week").format("MM/DD/YYYY"),
    endDate: moment().endOf("week").format("MM/DD/YYYY"),
  });
  const [cardsWeekly, setCardsWeekly] = useState(true);
  const { height } = window.visualViewport;

  const totalDocsPerPage = Math.floor((height * 0.6) / 55 - 2);

  const docsPerPage = totalDocsPerPage;

  useEffect(() => {
    if (user?.id) {
      setLoadingTable(true);
      const newQuery = debounce(() => {
        getAllPackages({
          id: user?.id,
          limit: docsPerPage,
          page: pageSelected,
          query: query,
          orderField: filters?.orderBy || "_id",
          coms: filters?.coms,
          status: 4,
          startDate:
            filters?.date && moment(filters?.date).format("MM/DD/YYYY"),
          endDate:
            filters?.date &&
            moment(filters?.date).add(1, "days").format("MM/DD/YYYY"),
        }).then((data) => {
          if (data?.data?.result) {
            setData(data?.data?.result);
            setTotalDocs(data?.data?.count);
            setLoading(false);
            setLoadingTable(false);
          }
        });
      }, 1000);
      newQuery();
    }
  }, [user, isLoading, docsPerPage, query, filters, pageSelected]);

  useEffect(() => {
    if (cardsWeekly) {
      setShowResume({
        startDate: moment().startOf("week").format("MM/DD/YYYY"),
        endDate: moment().endOf("week").format("MM/DD/YYYY"),
      });
    }
    if (!cardsWeekly) {
      setShowResume({
        startDate: moment().startOf("month").format("MM/DD/YYYY"),
        endDate: moment().endOf("month").format("MM/DD/YYYY"),
      });
    }
  }, [cardsWeekly]);

  useEffect(() => {
    getAllPackagesByStatus({
      id: user?.id,
      state: 2,
      startDate: showResume?.startDate,
      endDate: showResume?.endDate,
    }).then((data) => data?.data?.total && setInStorage(data?.data?.total));
    getAllPackagesByStatus({
      id: user?.id,
      state: 1,
      startDate: showResume?.startDate,
      endDate: showResume?.endDate,
    }).then((data) => data?.data?.total && setPicked(data?.data?.total));
    getAllPackagesByStatus({
      id: user?.id,
      state: 4,
      startDate: showResume?.startDate,
      endDate: showResume?.endDate,
    }).then((data) => {
      if (data?.data?.total) {
        setDelivered(data?.data?.total);
      }
    });
  }, [showResume, user]);

  useEffect(() => {
    const parsedData = data?.map((element) => {
      return {
        correlative: {
          value: (
            <div className="custom-center-inner-td ml-3">
              <span>{element?.followingNumber}</span>
            </div>
          ),
          type: "correlative",
          comparative: element?.followingNumber,
        },
        receiver: (
          <div className="d-grid">
            <span>{element?.receiver_name}</span>
            <span>{element?.receiver_phone}</span>
          </div>
        ),
        destiny: (
          <div className="custom-center-inner-td">
            <span className="text-truncate">
              {element?.shipping_address?.address_line},{" "}
              <span className="custom-table-city-name">
                {element?.shipping_address?.city?.name}
              </span>
            </span>
          </div>
        ),
        ingresed: (
          <div className="custom-center-inner-td">
            <span>{moment(element?.updatedAt).format("DD/MM/YY HH:mm")}</span>
          </div>
        ),
        status: {
          statusPill: (
            <div className="custom-center-inner-td">
              <div className={`${packageStatusStyles[element?.status]}`}>
                {packageStatus[element?.status]}
              </div>
            </div>
          ),
          value: (
            <div className="custom-center-inner-td">{element?.status}</div>
          ),
          status: element?.status,
        },
      };
    });
    setTableData(parsedData);
  }, [data]);

  const totalCards = [
    { title: "En bodega", icon: Clock, value: inStorage, rotate: false },
    { title: "Recolectados", icon: Box, value: picked, rotate: false },
    { title: "Entregados", icon: Rocket, value: delivered, rotate: false },
  ];

  const headers = [
    "Correlativo",
    "Cliente",
    "Dirección de entrega",
    "Última actualización",
    "Estado",
    " ",
  ];

  const searchValue = (value) => {
    const newQuery = debounce(() => setQuery(value), 1000);
    newQuery();
  };

  if (loading) return <LoadingTable />;

  return (
      // se cambia componente section por div con estilos segun standar - ksandoval - 10-05
    <div className="vh-90 w-100 all-orders-container mt-6">
       <SearchBar onChange={searchValue} loading={loadingTable} />
      <Breadcrumb className="custom-breadcrumb-container">
        <BreadcrumbItem
          className="d-flex align-items-center cursor-pointer"
          onClick={() => navigate("/home")}
        >
          <img src={iconList.home} alt="home" className="mr-2" />
          <span>Inicio</span>
        </BreadcrumbItem>
        <BreadcrumbItem
          className="d-flex align-items-center cursor-pointer"
          onClick={() => setViewEvidence(false)}
        >
          <span>Pedidos entregados</span>
        </BreadcrumbItem>
        {viewEvidence && (
          <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
            <span>{packageEvidence?.correlative?.value}</span>
          </BreadcrumbItem>
        )}
      </Breadcrumb>
      {viewEvidence ? (
        <EvidenceView
          selectedPackage={packageEvidence}
          completePackages={data}
          setViewEvidence={setViewEvidence}
        />
      ) : (
        <>
          <h2 className="h2-custom-size all-orders-container__title d-flex justify-content-between">
            Pedidos entregados
            <ButtonGroup
            style={{position: 'static'}}
            >
              <Button
              style={{position: 'static'}}
                className={`custom-group-button ${
                  cardsWeekly
                    ? "selected-custom-btn bg-gray-200"
                    : "not-selected-custom-btn"
                }`}
                onClick={() => setCardsWeekly(true)}
              >
                Semana
              </Button>
              <Button
              style={{position: 'static'}}
                className={`custom-group-button ${
                  !cardsWeekly
                    ? "selected-custom-btn bg-gray-200"
                    : "not-selected-custom-btn"
                }`}
                onClick={() => setCardsWeekly(false)}
              >
                Mes
              </Button>
            </ButtonGroup>
          </h2>
          <div className="d-flex justify-content-between">
            {totalCards.map((item, index) => (
              <Card
                key={index}
                title={item.title}
                value={item.value}
                icon={item.icon}
                rotate={item.rotate}
                total={totalCards?.length}
              />
            ))}
          </div>
          <div className="all-orders-container__table">
            <CustomTable
              title={"Todos los pedidos"}
              headers={headers}
              data={tableData}
              totalDocs={totalDocs}
              page={setPageSelected}
              actions
              completeData={data}
              docsPerPage={docsPerPage}
              loadingTable={loadingTable}
              pageSelected={pageSelected}
              setViewEvidence={setViewEvidence}
              setPackageEvidence={setPackageEvidence}
              filters={filters}
              setFilters={setFilters}
              statusDisabled={true}
            />
          </div>
        </>
      )}
    </div>
  );
}

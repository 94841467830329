import React, {useCallback, useState, useEffect} from "react";
import {
  Form,
  FormGroup,
  FormFeedback,
  Row,
  Col,
  Container,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import {useForm, Controller} from "react-hook-form";
import {createPackage} from "../../api/packages";
import {getUser} from "../../api/user";
import {packageStatus} from "../../utils/packageStatus";
import {posibleComs} from "../../utils/coms";
import {showToast} from "../../utils/showToast";
import SearchDropdown from "../searchDropdown";
import useAuth from "../../hooks/useAuth";
import FormDivider from "../formsDivider";
import Stepper from "../stepper";
import ErrorMessage from "../errorMessage";
import {createClientUser, updateClientUser} from "../../api/user";
import Modal from "../../../src/components/modal";
import {toast} from 'react-toastify';

export default function CreateClientBeartrack({
                                                edit,
                                                defaultData,
                                                closeModal,
                                                cerrarModal,
                                                fetch,
                                                setConfirmationModal,
                                                setPackagesCreated,
                                                user,
                                              }) {
  console.log("datos del client para editar: ", user);
  // console.log("valor de editmode: ", edit);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [step, setStep] = useState(1);

  const titles = {
    form1: "Datos de la empresa",
    form2: "Datos del administrador",
    form3: "Datos para retiros",
  };
  //logica steps
  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };
  // end -logica steps

  const methods = useForm({
    mode: "onSubmit",
    defaultValues: edit ? user : {},
  });

  useEffect(() => {
    if (edit && user) {
      methods.reset(user);  // Asignar los valores del usuario al formulario
      methods.setValue('senderAddress', user.senderUser?.senderAddress || '');
    }
  }, [edit, user, methods]);

  const ErrorMessage = ({msg}) => (
      <FormFeedback className="d-block">{msg}</FormFeedback>
  );

  const FormDivider = ({className}) => (
      <div className={`w-full border-t border-gray-300 my-2 ${className}`}/>
  );

  const submitValues = useCallback(
      async (values) => {
        setLoading(true);
        const toastId = toast.loading("Guardando cambios...");
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken) {
          console.log("datos accessToken::::", accessToken);
          values.token = accessToken;
        }

        try {
          if (edit) {
            console.log("editar", values._id);
            await updateClientUser(values._id, values);
            toast.update(toastId, {
              appearance: "success",
              render: "Usuario actualizado exitosamente",
              type: "success",
              isLoading: false,
              autoClose: 2000,
              position: "bottom-center",
              autoDismiss: true,
            });
          } else {
            // console.log("datos usuario::::", values);
            await createClientUser(values);
            toast.update(toastId, {
              appearance: "success",
              render: "Usuario creado exitosamente",
              type: "success",
              isLoading: false,
              autoClose: 2000,
              position: "bottom-center",
              autoDismiss: true,
            });
          }
          setLoading(false);
          closeModal(false);
          fetch(true);
        } catch (error) {
          // console.error("Error al guardar los cambios:", error);
          setLoading(false);
          toast.update(toastId, {
            appearance: "error",
            render: "Error al guardar los cambios: " + error.message,
            type: "error",
            isLoading: false,
            autoClose: 2000,
            position: "bottom-center",
            autoDismiss: true,
          });
        }
      },
      [closeModal, edit, fetch, user]
  );

  const getCurrentTitle = () => {
    switch (step) {
      case 1:
        return titles.form1;
      case 2:
        return titles.form2;
      case 3:
        return titles.form3;
      default:
        return titles.form1;
    }
  };

  const isForm1Complete =
      methods.watch("socialRazon") &&
      methods.watch("rut") &&
      methods.watch("fantasyName") &&
      methods.watch("fiscalAddress");

  const isForm2Complete =
      methods.watch("name") &&
      methods.watch("email") &&
      methods.watch("phone");

  const isForm3Complete =
      methods.watch("senderAddress") &&
      methods.watch("preferedHour") &&
      methods.watch("namePayPerson") &&
      methods.watch("emailPayPerson");

  return (
      <div>
        <h1 className="text-black font-semibold text-xl mb-0 mt-0">
          {getCurrentTitle()}
        </h1>
        <FormDivider/>
        <Container className="mt-0">
          <Form onSubmit={methods.handleSubmit(submitValues)}>
            {step === 1 && (
                <>
                  <Row className="mt-0">
                    <Col xs="12">
                      <Label className="forms-input-title">Razón social</Label>
                      <Controller
                          name="socialRazon"
                          control={methods.control}
                          render={({field}) => (
                              <Input
                                  {...field}
                                  type="text"
                                  className="p-2 px-3 forms-custom-input"
                                  placeholder="GAMU SPA"
                                  defaultValue=""
                              />
                          )}
                          rules={{required: "Ingrese la Razón social del vendedor"}}
                      />
                      {methods.formState.errors?.socialRazon && (
                          <ErrorMessage
                              msg={methods.formState.errors?.socialRazon?.message}
                          />
                      )}
                    </Col>
                    <Col xs="12">
                      <Label className="forms-input-title">RUT</Label>
                      <Controller
                          name="rut"
                          control={methods.control}
                          render={({field}) => (
                              <Input
                                  {...field}
                                  type="text"
                                  className="p-2 px-3 forms-custom-input"
                                  placeholder="76.774.123-4"
                                  defaultValue=""
                              />
                          )}
                          rules={{required: "Ingrese el RUT del vendedor"}}
                      />
                      {methods.formState.errors?.rut && (
                          <ErrorMessage
                              msg={methods.formState.errors?.rut?.message}
                          />
                      )}
                    </Col>
                    <Col xs="12">
                      <Label className="forms-input-title">
                        Nombre de fantasía
                      </Label>
                      <Controller
                          name="fantasyName"
                          control={methods.control}
                          render={({field}) => (
                              <Input
                                  {...field}
                                  type="text"
                                  className="p-2 px-3 forms-custom-input"
                                  placeholder="GAMU SPA"
                                  defaultValue=""
                              />
                          )}
                          rules={{
                            required: "Ingrese el Nombre de fantasía del vendedor",
                          }}
                      />
                      {methods.formState.errors?.fantasyName && (
                          <ErrorMessage
                              msg={methods.formState.errors?.fantasyName?.message}
                          />
                      )}
                    </Col>
                    <Col xs="12">
                      <Label className="forms-input-title">Dirección fiscal</Label>
                      <Controller
                          name="fiscalAddress"
                          control={methods.control}
                          render={({field}) => (
                              <Input
                                  {...field}
                                  type="text"
                                  className="p-2 px-3 forms-custom-input"
                                  placeholder="General Gana 123, Santiago"
                                  defaultValue=""
                              />
                          )}
                          rules={{
                            required: "Ingrese la Dirección fiscal del vendedor",
                          }}
                      />
                      {methods.formState.errors?.fiscalAddress && (
                          <ErrorMessage
                              msg={methods.formState.errors?.fiscalAddress?.message}
                          />
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs="6">
                      <Button
                          color="orange"
                          className={`w-100 bg-[#F8612C] text-white hover:bg-orange-500 ${
                              !isForm1Complete ? "disabled-button" : ""
                          }`}
                          type="button"
                          onClick={handleNextStep}
                          disabled={!isForm1Complete}
                      >
                        Siguiente paso
                      </Button>
                    </Col>
                    <Col xs="6">
                      <Button
                          color="white"
                          className="w-100 text-black border-black"
                          type="button"
                          onClick={() => {closeModal(false); cerrarModal(false)}}
                      >
                        Cancelar
                      </Button>
                    </Col>
                  </Row>
                </>
            )}

            {step === 2 && (
                <>
                  <Row className="mt-0">
                    <Col xs="12">
                      <Label className="forms-input-title">Nombre</Label>
                      <Controller
                          name="name"
                          control={methods.control}
                          render={({field}) => (
                              <Input
                                  {...field}
                                  type="text"
                                  className="p-2 px-3 forms-custom-input"
                                  placeholder="Luis González"
                                  defaultValue=""
                              />
                          )}
                          rules={{required: "Ingrese el Nombre del vendedor"}}
                      />
                      {methods.formState.errors?.name && (
                          <ErrorMessage
                              msg={methods.formState.errors?.name?.message}
                          />
                      )}
                    </Col>
                    <Col xs="12">
                      <Label className="forms-input-title">
                        Correo electrónico
                      </Label>
                      <Controller
                          name="email"
                          control={methods.control}
                          render={({field}) => (
                              <Input
                                  {...field}
                                  type="email"
                                  className="p-2 px-3 forms-custom-input"
                                  placeholder="luis@otschile.cl"
                                  defaultValue=""
                              />
                          )}
                          rules={{
                            required: "Ingrese el Correo electrónico del vendedor",
                          }}
                      />
                      {methods.formState.errors?.email && (
                          <ErrorMessage
                              msg={methods.formState.errors?.email?.message}
                          />
                      )}
                    </Col>
                    <Col xs="12">
                      <Label className="forms-input-title">Teléfono</Label>
                      <Controller
                          name="phone"
                          control={methods.control}
                          render={({field}) => (
                              <Input
                                  {...field}
                                  type="text"
                                  className="p-2 px-3 forms-custom-input"
                                  placeholder="987654321"
                                  defaultValue=""
                              />
                          )}
                          rules={{required: "Ingrese el Teléfono del vendedor"}}
                      />
                      {methods.formState.errors?.phone && (
                          <ErrorMessage
                              msg={methods.formState.errors?.phone?.message}
                          />
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs="6">
                      <Button
                          color="orange"
                          className={`w-100 bg-[#F8612C] text-white hover:bg-orange-500 ${
                              !isForm2Complete ? "disabled-button" : ""
                          }`}
                          type="button"
                          onClick={handleNextStep}
                          disabled={!isForm2Complete}
                      >
                        Siguiente paso
                      </Button>
                    </Col>
                    <Col xs="6">
                      <Button
                          color="white"
                          className="w-100 text-black border-black"
                          type="button"
                          onClick={handlePrevStep}
                      >
                        Volver atrás
                      </Button>
                    </Col>
                  </Row>
                </>
            )}

            {step === 3 && (
                <>
                  <Row className="mt-0">
                    <Col xs="12">
                      <Label className="forms-input-title">
                        Dirección de retiro
                      </Label>
                      <Controller
                          name="senderAddress"
                          control={methods.control}
                          render={({field}) => (
                              <Input
                                  {...field}
                                  type="text"
                                  className="p-2 px-3 forms-custom-input"
                                  placeholder="General Gana 123, Santiago"
                                  defaultValue=""
                              />
                          )}
                          rules={{
                            required: "Ingrese la Dirección de retiro del vendedor",
                          }}
                      />
                      {methods.formState.errors?.senderAddress && (
                          <ErrorMessage
                              msg={methods.formState.errors?.senderAddress?.message}
                          />
                      )}
                    </Col>
                    <Col xs="12">
                      <Label className="forms-input-title">Horario de retiro</Label>
                      <Controller
                          name="preferedHour"
                          control={methods.control}
                          render={({field}) => (
                              <Input
                                  {...field}
                                  type="text"
                                  className="p-2 px-3 forms-custom-input"
                                  placeholder="13:00"
                                  defaultValue=""
                              />
                          )}
                          rules={{
                            required: "Ingrese el horario de retiro del vendedor",
                          }}
                      />
                      {methods.formState.errors?.preferedHour && (
                          <ErrorMessage
                              msg={methods.formState.errors?.preferedHour?.message}
                          />
                      )}
                    </Col>
                    <h1 className="text-black font-semibold text-xl mb-0 mt-4">
                      Datos para cobros
                    </h1>
                    <FormDivider></FormDivider>
                    <Col xs="12">
                      <Label className="forms-input-title">
                        Responsable cobros
                      </Label>
                      <Controller
                          name="namePayPerson"
                          control={methods.control}
                          render={({field}) => (
                              <Input
                                  {...field}
                                  type="text"
                                  className="p-2 px-3 forms-custom-input"
                                  placeholder="Gerardo Morales"
                                  defaultValue=""
                              />
                          )}
                          rules={{required: "Ingrese el Responsable de cobros"}}
                      />
                      {methods.formState.errors?.namePayPerson && (
                          <ErrorMessage
                              msg={methods.formState.errors?.namePayPerson?.message}
                          />
                      )}
                    </Col>
                    <Col xs="12">
                      <Label className="forms-input-title">
                        Correo responsable cobros
                      </Label>
                      <Controller
                          name="emailPayPerson"
                          control={methods.control}
                          render={({field}) => (
                              <Input
                                  {...field}
                                  type="email"
                                  className="p-2 px-3 forms-custom-input"
                                  placeholder="gmorales@grupogamu.cl"
                                  defaultValue=""
                              />
                          )}
                          rules={{
                            required: "Ingrese el correo del responsable de cobros",
                          }}
                      />
                      {methods.formState.errors?.emailPayPerson && (
                          <ErrorMessage
                              msg={methods.formState.errors?.emailPayPerson?.message}
                          />
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs="6">
                      <Button
                          color="orange"
                          className={`w-100 bg-[#F8612C] text-white hover:bg-orange-500 ${
                              !isForm3Complete ? "disabled-button" : ""
                          }`}
                          type="submit"
                          disabled={!isForm3Complete}
                      >
                        {edit ? "Editar usuario" : "Crear usuario"}
                      </Button>
                    </Col>
                    <Col xs="6">
                      <Button
                          color="white"
                          className="w-100 text-black border-black"
                          type="button"
                          onClick={() => {closeModal(false); cerrarModal(false)}}
                      >
                        Cancelar
                      </Button>
                    </Col>
                  </Row>
                </>
            )}
          </Form>
        </Container>
      </div>
  );
}

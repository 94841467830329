import React, {useState, useEffect, useCallback} from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardTitle,
  Collapse,
  Label,
  Input,
  Col,
  Row,
  Button,
  Spinner,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import {activateUserApi, deleteUserApi, getActiveUsers, getInActiveUsers} from "../../../api/user";
import SearchTerm from "../../../components/searchTerm";
import Modal from "../../../components/modal";
import Trash from "../../../assets/delete.svg";
import Edit from "../../../assets/edit.svg";
import Search from "../../../assets/search.svg";
import SearchBar from "../../../components/searchBar";

import { iconList } from "../../../utils/iconList";
import BlockModal from "../../../components/blockModal";
import UnblockModal from "../../../components/unblockModal";
import EditUserModal from "../../../components/editUserModal";
import { showToast } from "../../../utils/showToast";
import PackageTabs from "../../../components/packageTabs";
import CreateUserBeartrack from "../../../components/createUserBeartrack/createUserBeartrack";
import {toast} from "react-toastify";
import {deleteRoutesApi} from "../../../api/routes";

export default function BeartrackBloquedUsers() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [unblockModal, setUnBlockModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  // modales
  const [editMode, setEditMode] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalUnblock, setOpenModalUnblock] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(1);

  const [users, setUsers] = useState([]);


  useEffect(() => {

    if (loading || typeof searchTerm === "string") {
      fetchUsers(currentPage).then();
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // console.log("datos usuarios:::", users);
  }, [loading, currentPage, searchTerm]);


  const handlePageChange = (page) => {
    setCurrentPage(page);
    setLoading(true);
  };
  const fetchUsers = async (page) => {
    const toastId = toast.loading("Cargando usuarios..."); // Muestra el indicador de carga
    try {
      const { data } = await getInActiveUsers(page, 10, searchTerm);

      setUsers(data?.users);
      setTotalPages(data?.totalPages);
      setTotal(data?.total);
      setLoading(false);

      toast.update(toastId, {
        render: "Usuarios cargados",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
    } catch (error) {
      console.error("Error fetching active users", error);
      setLoading(false);
      toast.update(toastId, {
        render: "Error al cargar usuarios",
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    }
  };

  const handleUnlock = async () => {
    const toastId = toast.loading("Cargando usuarios..."); // Muestra el indicador de carga
    console.log("desbloquear user:::", selectedUser._id);
    setLoading(true);
    activateUserApi({ userId: selectedUser._id, status: true })
        .then((data) => {
          setLoading(false);
          setOpenModalUnblock(false);
          toast.update(toastId, {
            render: "Usuario desbloqueado exitosamente",
            type: "success",
            isLoading: false,
            autoClose: 2000,
            position: "bottom-center",
          });
        })
        .catch((error) => {
          setLoading(false);
          toast.update(toastId, {
            render: "Error al desbloqueado el usuario",
            type: "error",
            isLoading: false,
            autoClose: 2000,
            position: "bottom-center",
          });
        });
  };



  const tabContent = [
    <CreateUserBeartrack
      closeModal={setOpenModalEdit}
      fetch={fetch}
      user={selectedUser} 
      edit={editMode}
    />,
  ];

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setOpenModalEdit(true);
    setEditMode(true);
  };

  const handleDeleteUser = (user) => {
    setSelectedUser(user);
    setOpenModalDelete(true);
  };

  const handleUnblockUser = (user) => {
    setSelectedUser(user);
    setOpenModalUnblock(true);
  };


  const menuOptions = [
    {
      title: "Editar usuario",
      subtitle: "Modifica algunos datos como el correo, rol o contraseña.",
      onClick: handleEditUser,
    },
    {
      title: "Desbloquear usuario",
      subtitle: "Permite al usuario volver a acceder a la plataforma.",
        onClick: handleUnblockUser,
    },
    {
      title: "Eliminar usuario",
      subtitle:"Eliminar permanentemente este usuario. Esta acción le quita el acceso a la paltaforma.",
      onClick: handleDeleteUser,
    },
  ];

  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
    setEditMode(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const [hoveredIcon, setHoveredIcon] = useState(null);

  // Antes del return en tu componente
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);

  const handleResize = () => {
    if (window.innerWidth >= 1400) {
      setCardsPerRow(3);
      setCardWidth("23rem");
      setCardPadding("my-1 mx-1");
    } else if (window.innerWidth >= 1080) {
      setCardsPerRow(2);
      setCardWidth("30rem");
      setCardPadding("m-1");
    } else {
      setCardsPerRow(1);
      setCardWidth("100%");
      setCardPadding("m-1");
    }
  };

  const [cardsPerRow, setCardsPerRow] = useState(3);
  const [cardWidth, setCardWidth] = useState("23rem");
  const [cardPadding, setCardPadding] = useState("my-1 mx-1");

  return (
    <div className="mt-6 w-full h-full">
       {/* Modal  eliminar */}
       <Modal
        size={"md"}
        open={openModalDelete}
        setOpen={() => setOpenModalDelete()}
        header={
          <div>
            <h1 className="text-xl font-bold">Eliminar usuario</h1>
          </div>
        }
        body={
          <p>
            Esta acción no se puede deshacer. Esto eliminará permanentemente la
            cuenta de usuario. Todos los datos serán eliminados y no tendrá
            acceso a la plataforma.
          </p>
        }
        footer={
          <div className="flex flex-auto justify-between items-center">
            <Button
              block
              color="orange"
              className=" py-2 m-2 border font-bold border-orange-500 text-orange-500 hover:text-red-600 hover:bg-red-300 hover:border-red-800"
            >
              Sí, eliminar
            </Button>
            <Button
              block
              color="orange"
              className=" py-2 m-2 border font-bold border-orange-500 text-orange-500 hover:text-red-600 hover:bg-red-300 hover:border-red-800"
              onClick={() => setOpenModalDelete(false)}
            >
              No, mantener
            </Button>
          </div>
        }
      ></Modal>

      {/* Modal  editar */}
      <Modal
        size={"md"}
        open={openModalEdit}
        // setOpen={() => setOpenModalEdit()}
        setOpen={handleCloseModalEdit}
        s
        header={
          <div>
            <h1 className="text-xl font-bold">Editar usuario</h1>
          </div>
        }
        body={<PackageTabs tabContent={tabContent} />}
      ></Modal>

      {/* Modal desbloquear */}
      <Modal
        size={"md"}
        open={openModalUnblock}
        setOpen={() => setOpenModalUnblock()}
        header={
          <div>
            <h1 className="text-xl font-bold">Desbloquear usuario</h1>
          </div>
        }
        body={
          <p>
            Esta acción desbloqueará al usuario seleccionado.
            Esta acción permitira al usuario acceder nuevamente a la plataforma.
          </p>
        }
        footer={
          <div className="flex flex-auto justify-between items-center">
            <Button
              block
              color="orange"
              className=" py-2 m-2 border font-bold border-orange-500 text-orange-500 hover:text-red-600 hover:bg-red-300 hover:border-red-800"
              onClick={handleUnlock}
            >
              Sí, desbloquear
            </Button>
            <Button
              block
              color="orange"
              className=" py-2 m-2 border font-bold border-orange-500 text-orange-500 hover:text-red-600 hover:bg-red-300 hover:border-red-800"
              onClick={() => setOpenModalUnblock(false)}
            >
              No, mantener
            </Button>
          </div>
        }
      ></Modal>


       <Modal
        open={unblockModal}
        setOpen={() => setUnBlockModal(false)}
        body={
          <UnblockModal
            closeModal={setUnBlockModal}
            userId={selectedUser?._id}
            update={setLoading}
          />
        }
      />

    
      {/* Título y descripción */}
      <div className="d-flex justify-content-between">
        <span className="w-100 d-flex justify-content-between">
          <h2 className="h2-custom-size all-users-container__title font-bold">
            Usuarios inactivos
          </h2>
        </span>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <p className="text-sm">Gestiona y edita los usuarios inactivos/bloqueados.</p>
      </div>

      {/* Contenedor de cards */}
      <Card className="custom-inform-card scrollable-card">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <label>{total} usuarios inactivos</label>
        </div>

        {/* Barra de búsqueda */}
        <SearchTerm
          setSearchTerm={setSearchTerm}
          type={"text"}
          placeholder={"Buscar por nombre, correo o teléfono"}
        />
        

       {/* Cards */}
        <div className={`grid grid-cols-1 md:grid-cols-${cardsPerRow} lg:grid-cols-${cardsPerRow} xl:grid-cols-${cardsPerRow} gap-4 mt-3`}>
          {users.map((user, index) => (
            <Card
             
              key={user.id}
              className={`${cardPadding} rounded-xl ${hoveredCardIndex === index ? 'highlighted-card' : ''}`}
              color="white"
              style={{ width: cardWidth }}
              onMouseEnter={() => setHoveredCardIndex(index)}
              onMouseLeave={() => setHoveredCardIndex(null)}
            >
          
              <CardHeader
                className="flex justify-between items-center h-[50px]"
                color="white"
              >
                <div>{user.name}</div>
                <UncontrolledDropdown>
                  <DropdownToggle nav>
                    <span className="material-symbols-outlined">more_vert</span>
                  </DropdownToggle>

                  <DropdownMenu
                    end
                    style={{
                      marginTop: "-1rem",
                      width: "350px",
                      minWidth: "100px",
                      marginLeft: "0px",
                    }}
                  >
                    {menuOptions.map((item, index) => (
                      <React.Fragment key={index}>
                        <DropdownItem
                          onClick={() => item.onClick(user)}
                          onMouseEnter={() => setHoveredIcon(item.title)}
                          onMouseLeave={() => setHoveredIcon(null)}
                        >
                          <div className="  gap-1 px-2">
                            <div>
                              <p
                                className="text-sm text-[12px] font-bold text-wrap"
                                style={{
                                  color:
                                    hoveredIcon === item.title
                                      ? "#F8612C"
                                      : "#000",
                                  transition: "color 0.05s",
                                  fontSize: "12px", // Tamaño de fuente más grande para el icono
                                }}
                              >
                                {item.title}
                              </p>
                              <p className="text-xs text-[10px] text-wrap">
                                {item.subtitle}
                              </p>
                            </div>
                          </div>
                        </DropdownItem>
                        {index < menuOptions.length - 1 && (
                          <DropdownItem divider />
                        )}
                      </React.Fragment>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </CardHeader>
              <CardBody className="text-sm">
                <Row>
                  <Col xs="6" md="4" xl="4">
                    Rol:
                  </Col>
                  <Col
                    xs="6"
                    md="8"
                    xl="8"
                    className="justify-end items-end text-right"
                  >
                    {user.role}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs="6" md="4" xl="4">
                    Teléfono:
                  </Col>
                  <Col
                    xs="6"
                    md="8"
                    xl="8"
                    className="justify-end items-end text-right"
                  >
                    {user.phone}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs="6" md="4" xl="4">
                    Correo:
                  </Col>
                  <Col
                    xs="6"
                    md="8"
                    xl="8"
                    className="justify-end items-end text-right"
                  >
                    {user.email}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs="6" md="4" xl="4">
                    Contraseña:
                  </Col>
                  <Col
                    xs="6"
                    md="8"
                    xl="8"
                    className="justify-end items-end text-right"
                  >
                    *******************
                  </Col>
                </Row>
                <hr />
              </CardBody>
            </Card>
          ))}
        </div>

        {/* Controles de paginación */}
        <div className="d-flex justify-content-between align-items-center mt-3">
          <Button
              color="orange"
              className="bg-[#F8612C] text-white hover:bg-orange-500"
              type="button"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
          >
            Anterior
          </Button>
          <span>
            Página {currentPage} de {totalPages}
          </span>
          <Button
              color="orange"
              className="bg-[#F8612C] text-white hover:bg-orange-500"
              type="button"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
          >
            Siguiente
          </Button>
        </div>
      </Card>
    </div>
  );
}

import {create} from 'zustand';

export default create((set) => ({
    modalName: "",
    props: {},
    openModal: (name, props = {}) => {
        set((state) => ({ ...state, modalName: name, props }));
    },
    closeModal: () => {
        set((state) => ({ ...state, modalName: "" }));
    },
    setProps: (newProps) =>{
        set((state) => ({...state, props: newProps}));
    }
  }))
import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AuthProvider from "../../providers/authProviders";
import ClientMenu from "../../components/menu/clientMenu";
import NewClientMenu from "../../components/menu/newClientMenu";
import AdminMenu from "../../components/menu/adminMenu";
import NewAdminMenu from "../../components/menu/newAdminMenu";
import useAuth from "../../hooks/useAuth";
import { iconList } from "../../utils/iconList";
import TaildwindClientMenu from "../../components/menu/tailwindClientMenu";


export default function MainLayout({ routes }) {
  const { user, isLoading } = useAuth();
  const [updatePackages, setUpdatePackages] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user && !isLoading) {
      navigate("/login");
    }
  }, [user, isLoading, navigate]);

  return (
  <Container className="w-100 m-0 p-0" style={{ maxWidth: "100%", backgroundColor: "none", overflowX: "hidden" }}>
    <ToastContainer />
    <Row className="">
      <div className="custom-menu-layout">
        {user?.role === "client" && <ClientMenu fetch={setUpdatePackages} />}
        {user?.role === "admin" && <AdminMenu fetch={setUpdatePackages} />}
      </div>

      <div className="custom-content-layout">
          {user?.role === "client" && (
            //header1
            // <TaildwindClientMenu fetch={setUpdatePackages} />
            <NewAdminMenu fetch={setUpdatePackages} />
          )}
          {user?.role === "admin" && (
            //header2
            <NewAdminMenu fetch={setUpdatePackages} />
          )}

        <Routes className="px-4">
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <AuthProvider>
                    <div className="content-main-container">
                      <route.element
                        update={updatePackages}
                        resetUpdate={setUpdatePackages}
                      />
                    </div>
                  </AuthProvider>
                }
              />
            ))}
          </Routes>
      
      </div>
    </Row>
  </Container>
  );
}

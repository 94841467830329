/* eslint-disable */
import React, { useCallback, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import jwtDecode from "jwt-decode";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Login as LoginApi } from "../../api/auth";
import { showToast } from "../../utils/showToast";
import { iconList } from "../../utils/iconList";
import { getConfig } from "../../api/configService";
import { isEqual } from "lodash";

export default function Login() {
  const methods = useForm({ mode: "onSubmit" });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // DvlprChris
  const [config, setConfig] = useState(null);
  const [storedColor, setStoredColor] = useState(null);
  const [configLoaded, setConfigLoaded] = useState(false);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const serviceConfig = await getConfig();
        console.log("serviceConfig from API: ", serviceConfig);
        // console.log("color from API: ", serviceConfig.color);
        setConfig(serviceConfig);
        setStoredColor(serviceConfig.color);
        localStorage.setItem("appConfig", JSON.stringify(serviceConfig));
        // console.log("Contenido del localStorage :", localStorage);
        setConfigLoaded(true);

      } catch (error) {
        console.log(error);
      }
    };
    fetchConfig();
  }, []);

  // useEffect(() => {
  //   const fetchConfig = async () => {
  //     try {
  //       console.log("Contenido del localStorage al inicio:", localStorage);

  //       const cachedConfig = localStorage.getItem("appConfig");

  //       if (cachedConfig) {
  //         // Si hay configuración en el almacenamiento local, utiliza esa configuración.
  //         //setConfig(JSON.parse(cachedConfig));

  //         const configData = JSON.parse(cachedConfig);
  //         const serviceConfig = await getConfig();
  //         console.log("Se obtienen datos del servicio:", serviceConfig);

  //         const json1 = JSON.stringify(configData);
  //         const json2 = JSON.stringify(serviceConfig);

  //         if (json1 === json2) {
  //           console.log("Las constantes son iguales.");
  //           setConfig(configData);
  //         } else {
  //           console.log("Las constantes son diferentes.");
  //           setConfig(serviceConfig);
  //           console.log("console.log(config)2: ", config);
  //           localStorage.setItem("appConfig", JSON.stringify(serviceConfig));
  //         }

  //         //obtener color de la API
  //         const configObject = JSON.parse(configData);
  //         const colorFromAPI = String(configObject.color);
  //         setStoredColor(colorFromAPI);
  //         console.log("storedColor IF::: ", storedColor);

  //       } else {
  //         console.log("No hay configuración en el almacenamiento local");
  //         // Si no hay configuración en el almacenamiento local, obtén la configuración del servicio.
  //         const configData = await getConfig();
  //         console.log("Se obtienen datos del servicio: ", configData);
  //         setConfig(configData);
  //         // Almacena la configuración en el almacenamiento local para futuras consultas.
  //         localStorage.setItem("appConfig", JSON.stringify(configData));

  //         //obtener color de la API
  //         const configObject = JSON.parse(configData);
  //         const colorFromAPI = String(configObject.color);
  //         setStoredColor(colorFromAPI);
  //         console.log("storedColor ELSE::: ", storedColor);
  //       }
  //     } catch (error) {
  //       console.error("Error al obtener la configuración:", error);
  //     }
  //   };

  //   fetchConfig();
  // }, []); // El array vacío asegura que este efecto solo se ejecute una vez al montar el componente.
  // // DvlprChris

  const submitValues = useCallback(
    async (values) => {
      setLoading(true);
      const { data, error } = await LoginApi(values);
      if (error) {
        showToast(error.message, false);
        setLoading(false);
        return;
      }
      // if (data.message === "El usuario no se encuentra registrado.") {
      //   showToast(data.message, false);
      //   setLoading(false);
      // }

      // if(data.message === "Correo o Contraseña no es correcto") {
      //   showToast(data.message, false);
      //   setLoading(false);
      // }

      // if(data.message === "La contraseña es incorrecta.") {
      //   showToast(data.message, false);
      //   setLoading(false);
      // }
      if (data.message !== "El usuario no se encuentra registrado.") {
        const userData = await jwtDecode(data?.accessToken);

        if (userData?.role === "delivery") {
          showToast(
            "De momento solo pueden ingresar usuarios de tipo cliente o administrador.",
            false
          );
          setLoading(false);
        }
        if (userData?.role === "client") {
          showToast("Login exitoso", true);
          setLoading(false);
          navigate("/home");
        }
        if (userData?.role === "admin") {
          showToast("Login exitoso", true);
          setLoading(false);
          navigate("/admin");
        }
      }
      if (error) {
        showToast(error.message, false);
        setLoading(false);
      }
    },
    [navigate]
  );

  return (
    <Container className="vh-100">
      <Row
        className="d-flex justify-content-center align-items-center vh-100"
        style={{ overflow: "hidden" }}
      >
        <Col xs="6" md="6" xl="6">
          <div className="login-card-style mt-5">
            <div className="w-100 d-flex justify-content-center">
              <Card className="m-0 login-card-over">
                <CardBody className="p-4">
                  <a
                    href="#"
                    className="d-flex justify-content-center mt-3 mb-4"
                  >
                    <img
                      // src={iconList.logo}
                      src={config?.logo} // Usar logo por defecto si no hay respuesta
                      // src="https://ots-prd.s3.us-east-005.backblazeb2.com/ots/1705975934.png"
                      alt="logo"
                      style={{ width: "70%" }}
                    />
                  </a>
                  <h3 className="mb-3 h3-custom-size">
                    Bienvenido a BearTrack!
                  </h3>
                  <h3 className="mb-3 login-custom-size">
                    Inicia sesión para consultar tus pedidos
                  </h3>
                  <Form onSubmit={methods.handleSubmit(submitValues)}>
                    <FormGroup>
                      <div>
                        <Label className="mt-3">Email</Label>
                        <Controller
                          name="email"
                          control={methods.control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="email"
                              className="p-2 px-3"
                            />
                          )}
                          rules={{ required: "Ingrese correo" }}
                        />
                        {methods?.formState?.errors?.email && (
                          <Label className="text-danger">
                            * {methods?.formState?.errors?.email?.message}
                          </Label>
                        )}
                      </div>
                      <div>
                        <Label className="mt-3">Contraseña</Label>
                        <Controller
                          name="password"
                          control={methods.control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="password"
                              placeholder="············"
                              className="p-2 px-3"
                            />
                          )}
                          rules={{ required: "Ingrese contraseña" }}
                        />
                        {methods?.formState?.errors?.password && (
                          <Label className="text-danger">
                            * {methods?.formState?.errors?.password?.message}
                          </Label>
                        )}
                      </div>
                    </FormGroup>
                    <div className="w-100">
                      {loading ? (
                        <Button className="login-btn" block>
                          <Spinner size="sm" />
                        </Button>
                      ) : (
                        // Dvlprchris - utilziar el color de la API
                        <Button
                          style={{
                            color:"black",
                            backgroundColor: storedColor,
                            borderColor: storedColor,
                          }}
                          block
                        >
                          Iniciar sesión
                        </Button>
                      )}
                    </div>
                    <div className="w-full flex justify-end mt-1">
                      <a
                        href="/login/recovery"
                        className=" text-md text-yellow-500"
                      >
                        ¿Olvidaste tu contraseña?
                      </a>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

import React, { useCallback, useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Container,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { createPackage } from "../../api/packages";
import { getUser } from "../../api/user";
import { packageStatus } from "../../utils/packageStatus";
import { posibleComs } from "../../utils/coms";
import { showToast } from "../../utils/showToast";
import SearchDropdown from "../searchDropdown";
import useAuth from "../../hooks/useAuth";
import FormDivider from "../formsDivider";
import Stepper from "../stepper";
import ErrorMessage from "../errorMessage";

export default function CreatePackage({
  edit,
  defaultData,
  closeModal,
  fetch,
  setConfirmationModal,
  setPackagesCreated,
}) {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState()
  const methods = useForm({ mode: "onSubmit" });
  const {
    user: { id, email },
  } = useAuth();

  const submitValues = useCallback(
    async (values) => {
      setLoading(true);
      const parsedValues = {
        cityName: values?.cityName,
        comment: values?.comment,
        comments: "",
        delivery_preference: "Normal",
        municipalityName: values?.cityName,
        neighborhoodName: values?.neighborhoodName,
        order_id: values?.order_id,
        receiver_lastName: values?.receiver_lastName,
        receiver_name: values?.receiver_name,
        receiver_phone: values?.receiver_phone,
        senderAddress: values?.senderAddress,
        senderNeighborhood: values?.senderNeighborhood,
        senderBusinessName: values?.businessName,
        senderState: "Metropolitana",
        stateName: "",
        status: 0,
        street_name: values?.street_name,
        street_number: values?.street_number,
        type: "Paquete",
        userMail: email,
      };

      const { data, error } = await createPackage({ values: parsedValues });

      if (data) {
        setConfirmationModal(true);
        setPackagesCreated(data?.packageStored ? data?.packageStored : data?.packageCreated);
        setLoading(false);
        closeModal(false);
        fetch(true);
      }

      if (error) {
        setLoading(false);
        showToast(
          "Error al crear el paquete, porfavor inténtelo nuevamente.",
          false
        );
      }
    },
    [id, closeModal, fetch, setConfirmationModal, setPackagesCreated]
  );

  useEffect(() => {
    if (id)
      getUser({ id })
        .then((data) => {
          if(data?.data?.user?.municipality) methods.setValue("senderNeighborhood", data?.data?.user?.municipality)
          if(data?.data?.user?.shippingAddress) methods.setValue("senderAddress", data?.data?.user?.shippingAddress)
          setUserData(data?.data?.user)
        })
        .catch((ex) => console.error(ex));
  }, [id, methods]);

  const stepsValue = { step1: { value: defaultData?.status } };

  return (
    <Container className="mt-3">
      <Form onSubmit={methods.handleSubmit(submitValues)}>
        {edit && (
          <Label className="forms-following-number-title">
            Pedido {defaultData.followingNumber}
          </Label>
        )}
        <FormDivider message={"Datos del emisor"} />
        <Row>
          <Col xs="6" md="6" xl="6" className="mt-3">
            <Label className="forms-input-title">COMUNA DE RETIRO</Label>
            <Controller
              name="senderNeighborhood"
              control={methods.control}
              render={({ field }) => (
                <SearchDropdown
                  {...field}
                  options={posibleComs}
                  placeholder="Buscar comuna"
                  selected={
                    edit
                      ? defaultData?.senderUser?.senderNeighborhood
                      : field.value
                  }
                  onChange={field.onChange}
                  disabled={edit || userData?.municipality}
                />
              )}
              rules={{ required: "Seleccione comuna de retiro" }}
            />
            {methods.formState.errors?.senderNeighborhood && (
              <ErrorMessage
                msg={methods.formState.errors?.senderNeighborhood?.message}
              />
            )}
          </Col>
          <Col xs="6" md="6" xl="6" className="mt-3 mb-5">
            <Label className="forms-input-title">DIRECCIÓN DE RETIRO</Label>
            <Controller
              name="senderAddress"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="senderAddress"
                  className="p-2 px-3 forms-custom-input"
                  placeholder="Ej: Rinconada El Salto 100"
                  disabled={edit || userData?.shippingAddress}
                  defaultValue={defaultData?.senderUser?.senderAddress}
                />
              )}
              rules={{ required: "Ingrese dirección de retiro" }}
            />
            {methods.formState.errors?.senderAddress && (
              <ErrorMessage
                msg={methods.formState.errors?.senderAddress?.message}
              />
            )}
          </Col>
          <FormDivider message={"Datos de quién recibe"} />
          {/* <Col xs="6" md="6" xl="6">
            <Label className="forms-input-title">NOMBRES</Label>
            <Controller
              name="receiver_name"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="receiver_name"
                  className="p-2 px-3 forms-custom-input"
                  placeholder="Escribe el nombre"
                  disabled={edit && true}
                  defaultValue={defaultData?.receiver_name}
                />
              )}
              rules={{ required: "Ingrese nombres" }}
            />
            {methods.formState.errors?.receiver_name && (
              <ErrorMessage
                msg={methods.formState.errors?.receiver_name?.message}
              />
            )}
          </Col>
          <Col xs="6" md="6" xl="6">
            <Label className="forms-input-title">APELLIDOS</Label>
            <Controller
              name="receiver_lastName"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="receiver_lastName"
                  className="p-2 px-3 forms-custom-input"
                  placeholder="Escribe el apellido"
                  disabled={edit && true}
                  defaultValue={defaultData?.receiver_lastName}
                />
              )}
              rules={{ required: "Ingrese apellidos" }}
            />
            {methods.formState.errors?.receiver_lastName && (
              <ErrorMessage
                msg={methods.formState.errors?.receiver_lastName?.message}
              />
            )}
          </Col> */}
          {/* trello - ots-161 - dvlprchris - 06-06-2024 */}
          <Col xs="12" md="12" xl="12">
            <Label className="forms-input-title">NOMBRE COMPLETO</Label>
            <Controller
              name="receiver_name"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  className="p-2 px-3 forms-custom-input"
                  placeholder="Escribe el nombre completo"
                  disabled={edit}
                  defaultValue={`${defaultData?.receiver_name ?? ''} ${defaultData?.receiver_lastName ?? ''}`}
                />
              )}
              rules={{ required: "Ingrese el nombre completo" }}
            />
            {methods.formState.errors?.receiver_name && (
              <ErrorMessage
                msg={methods.formState.errors?.receiver_name?.message}
              />
            )}
          </Col>
          <Col xs="6" md="6" xl="6" className="mt-3">
            <Label className="forms-input-title">TELÉFONO</Label>
            <Controller
              name="receiver_phone"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  maxLength={9}
                  type="receiver_phone"
                  className="p-2 px-3 forms-custom-input"
                  placeholder="Escribe el número de teléfono"
                  disabled={edit && true}
                  defaultValue={defaultData?.receiver_phone}
                />
              )}
            />
          </Col>
          <Col xs="6" md="6" xl="6" className="mt-3">
            <Label className="forms-input-title">NÚMERO DE VENTA</Label>
            <Controller
              name="order_id"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="order_id"
                  className="p-2 px-3 forms-custom-input"
                  placeholder="Escribe la referencia de tu venta"
                  disabled={edit && true}
                  defaultValue={defaultData?.order_id}
                />
              )}
            />
          </Col>
          <Col xs="6" md="6" xl="6" className="mt-3">
            <Label className="forms-input-title">COMUNA DE ENTREGA</Label>
            <Controller
              name="cityName"
              control={methods.control}
              render={({ field }) => (
                <SearchDropdown
                  {...field}
                  options={posibleComs}
                  placeholder="Selecciona la comuna"
                  selected={
                    edit
                      ? defaultData?.shipping_address?.city?.name
                      : field.value
                  }
                  onChange={field.onChange}
                  disabled={edit}
                />
              )}
              rules={{ required: "Seleccione comuna de entrega" }}
            />
            {methods.formState.errors?.cityName && (
              <ErrorMessage msg={methods.formState.errors?.cityName?.message} />
            )}
          </Col>
          {edit ? (
            <Col xs="12" md="12" xl="12" className="mt-3">
              <Label className="forms-input-title">DIRECCIÓN ENTREGA</Label>
              <Controller
                name="street_name"
                control={methods.control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="street_name"
                    className="p-2 px-3 forms-custom-input"
                    placeholder="Escribe la dirección"
                    disabled={edit && true}
                    defaultValue={defaultData?.shipping_address?.address_line}
                  />
                )}
              />
            </Col>
          ) : (
            <>
              <Col xs="6" md="6" xl="6" className="mt-3">
                <Label className="forms-input-title">CALLE DE ENTREGA</Label>
                <Controller
                  name="street_name"
                  control={methods.control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="street_name"
                      className="p-2 px-3 forms-custom-input"
                      placeholder="Escribe la calle"
                      disabled={edit && true}
                    />
                  )}
                  rules={{ required: "Ingrese calle de contacto" }}
                />
                {methods.formState.errors?.street_name && (
                  <ErrorMessage
                    msg={methods.formState.errors?.street_name?.message}
                  />
                )}
              </Col>
              <Col xs="6" md="6" xl="6" className="mt-3">
                <Label className="forms-input-title">
                  NÚMERO CALLE DE ENTREGA
                </Label>
                <Controller
                  name="street_number"
                  control={methods.control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="street_number"
                      className="p-2 px-3 forms-custom-input"
                      placeholder="Escribe el número de la casa"
                      disabled={edit && true}
                    />
                  )}
                  rules={{ required: "Ingrese número calle de entrega" }}
                />
                {methods.formState.errors?.street_number && (
                  <ErrorMessage
                    msg={methods.formState.errors?.street_number?.message}
                  />
                )}
              </Col>
            </>
          )}
          <Col xs="6" md="6" xl="6" className="mt-3">
            <Label className="forms-input-title">
              VILLA / POBLACIÓN DE ENTREGA
            </Label>
            <Controller
              name="neighborhoodName"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="neighborhoodName"
                  className="p-2 px-3 forms-custom-input"
                  placeholder="Escribe la villa o población"
                  disabled={edit && true}
                  defaultValue={
                    defaultData?.shipping_address?.neighborhood?.name
                  }
                />
              )}
            />
          </Col>
          <Col xs="12" md="12" xl="12" className="mt-3 mb-5">
            <Label className="forms-input-title">COMENTARIO</Label>
            <Controller
              name="comment"
              control={methods.control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="comment"
                  className="p-2 px-3 forms-custom-input-full-width"
                  placeholder="Escribe una referencia o nota"
                  disabled={edit && true}
                  defaultValue={defaultData?.shipping_address?.comment}
                />
              )}
            />
          </Col>
        </Row>
        {!edit && (
          <Button
            block

            color="dark"
            className="forms-custom-sender-button bg-black"
            disabled={loading}
          >
            Crear encomienda {loading && <Spinner size="sm" />}
          </Button>
        )}
        {edit && (
          <Row>
            <Col xs="12" md="12" xl="12">
              <Row>
                <Col xs="3" md="3" xl="3" className="d-grid">
                  <Label className="forms-input-detail-view">ESTADO</Label>
                  <Label className="forms-input-detail-view__state-data">
                    {packageStatus[defaultData?.status]}
                  </Label>
                </Col>
                <Col xs="6" md="6" xl="6" className="d-grid">
                  <Label className="forms-input-detail-view">
                    HORA ESTIMADA DE LLEGADA
                  </Label>
                  <Label className="forms-input-detail-view__time">
                    Entre: 14:00 y 21:00
                  </Label>
                </Col>
              </Row>
            </Col>
            <Col xs="12" md="12" xl="12">
              <Row>
                <Label className="forms-input-detail-view mt-4">
                  HISTORIAL DEL PEDIDO
                </Label>
                <Stepper stepStatus={stepsValue} />
              </Row>
            </Col>
          </Row>
        )}
      </Form>
    </Container>
  );
}

import React from "react";
import { iconList } from "../../utils/iconList";
import moment from "moment/moment";
import "moment/locale/es";

// const parseDate = (date) => {
//   if(typeof date === "string"){
//     return moment(date).format("DD MMM YYYY - HH:mm");
//   }
//   return moment(date.updatedAt).format("DD MMM YYYY - HH:mm");
// }

// DvlprChris
const parseDate = (date) => {
  if (date && (typeof date === "string" || date.updatedAt)) {
    const formattedDate = typeof date === "string" ? date : date.updatedAt;
    return moment(formattedDate).format("DD MMM YYYY - HH:mm");
  }
  return null; // Retorna null si no hay fecha válida
};
// DvlprChris

export default function Stepper({ stepStatus, data }) {
  console.log("full data: ", data)
  console.log("fecha estado Creado: ", data[0][data[0].length - 1])
  console.log("fecha estado Recolectado: ", data[1][data[1].length - 1])
  console.log("fecha estado En Bodega: ", data[2][data[2].length - 1])
  console.log("fecha estado En Ruta: ", data[3][data[3].length - 1])
  console.log("fecha estado Entregado: ", data[4][data[4].length - 1])
  console.log("fecha estado Entrega Fallida: ", data[5][data[5].length - 1])

  return (
    <div className="d-grid align-items-center">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <div
            className={`rounded-circle ${
              stepStatus?.step1?.value === 5
                ? "vertical-pending-rounded"
                : stepStatus?.step1?.value === 0
                ? "vertical-process-rounded"
                : "vertical-complete-rounded"
            } d-flex justify-content-center align-items-center`}
          >
            {stepStatus?.step1?.value > 0 && stepStatus?.step1?.value !== 5 && (
              <img src={iconList.check} alt="img" />
            )}
            {stepStatus?.step1?.value === 0 && (
              <div className="rounded-circle vertical-on-course vertical-rounded-process" />
            )}
            {stepStatus?.step1?.value === 5 && (
              <div className="rounded-circle vertical-rounded-pending-process" />
            )}
          </div>
          <div className="d-grid ml-3">
            <span className="vertical-custom-status-following">Creado</span>
          </div>
        </div>
        <div className="date-container">
          <span className="date-size">
            {data[0].length > 0
              ? parseDate(data[0][data[0].length - 1])
              : parseDate(data?.createdAt)}
          </span>
        </div>
      </div>
      {/* //verificar logica aca */}
      {(stepStatus?.step1?.value > 0 || stepStatus?.step1?.value === 5) && data[1][data[1].length - 1] != null && (
        <div className="d-flex justify-content-center line-container">
          {(stepStatus?.step1?.value === 5 ||
            (stepStatus?.step1?.value > 0 && data[1].length === 0)) && (
            <div className="vertical-line-flow-pending" />
            // entra aca
          )}
          {stepStatus?.step1?.value > 0 && data[1].length > 0 && (
            <div className="vertical-line-flow" />
          )}
        </div>
      )}
      {stepStatus?.step1?.value > 0 && data[1][data[1].length - 1] != null && (
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <div
              className={`rounded-circle ${
                stepStatus?.step1?.value === 5 ||
                (stepStatus?.step1?.value > 0 && data[1].length === 0)
                  ? "vertical-pending-rounded"
                  : stepStatus?.step1?.value < 1
                  ? "vertical-pending-rounded"
                  : stepStatus?.step1?.value === 1
                  ? "vertical-process-rounded"
                  : "vertical-complete-rounded"
              } d-flex justify-content-center align-items-center`}
            >
              {stepStatus?.step1?.value > 1 &&
                data[1].length > 0 &&
                stepStatus?.step1?.value !== 5 && (
                  <img src={iconList.check} alt="img" />
                )}
              {stepStatus?.step1?.value === 1 ||
                (stepStatus?.step1?.value > 1 && data[1].length === 0 && (
                  <div className="rounded-circle vertical-on-course vertical-rounded-process" />
                ))}
              {stepStatus?.step1?.value < 1 ||
                (stepStatus?.step1?.value > 1 && data[1].length === 0) ||
                (stepStatus?.step1?.value === 5 && (
                  <div className="rounded-circle vertical-rounded-pending-process" />
                ))}
            </div>
            <div className="d-grid ml-3">
              <span className="vertical-custom-status-following">
                Recolectado
              </span>
            </div>
          </div>
          <div className="date-container">
            <span className="date-size">
              {data[1].length > 0 && parseDate(data[1][data[1].length - 1])}
            </span>
          </div>
        </div>
      )}
      {(stepStatus?.step1?.value > 1 || stepStatus?.step1?.value === 5) && data[2][data[2].length - 1] != null && (
        <div className="d-flex justify-content-center line-container">
          {(stepStatus?.step1?.value === 5 ||
            (stepStatus?.step1?.value > 1 && data[2].length === 0)) && (
            <div className="vertical-line-flow-pending" />
          )}
          {stepStatus?.step1?.value > 1 && data[2].length > 0 && (
            <div className="vertical-line-flow" />
          )}
        </div>
      )}
      {stepStatus?.step1?.value > 1 && data[2][data[2].length - 1] != null && (
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <div
              className={`rounded-circle ${
                stepStatus?.step1?.value === 5 ||
                (stepStatus?.step1?.value > 1 && data[2].length === 0)
                  ? "vertical-pending-rounded"
                  : stepStatus?.step1?.value < 2
                  ? "vertical-pending-rounded"
                  : stepStatus?.step1?.value === 2
                  ? "vertical-process-rounded"
                  : "vertical-complete-rounded"
              } d-flex justify-content-center align-items-center`}
            >
              {stepStatus?.step1?.value > 2 &&
                data[2].length > 0 &&
                stepStatus?.step1?.value !== 5 && (
                  <img src={iconList.check} alt="img" />
                )}
              {stepStatus?.step1?.value === 2 ||
                (stepStatus?.step1?.value > 2 && data[2].length === 0 && (
                  <div className="rounded-circle vertical-on-course vertical-rounded-process" />
                ))}
              {stepStatus?.step1?.value < 2 ||
                (stepStatus?.step1?.value > 2 && data[2].length === 0) ||
                (stepStatus?.step1?.value === 5 && (
                  <div className="rounded-circle vertical-rounded-pending-process" />
                ))}
            </div>
            <div className="d-grid ml-3">
              <span className="vertical-custom-status-following">
                En bodega
              </span>
            </div>
          </div>
          <div className="date-container">
            <span className="date-size">
            {console.log(
                "fecha para estado En bodega: ",
                data[2][data[2].length - 1]
              )}
              {data[2].length > 0 && parseDate(data[2][data[2].length - 1])}
            </span>
          </div>
        </div>
      )}
      {(stepStatus?.step1?.value > 2 || stepStatus?.step1?.value === 5) && (
        <div className="d-flex justify-content-center line-container">
          {(stepStatus?.step1?.value === 5 ||
            (stepStatus?.step1?.value > 2 && data[3].length === 0)) && (
            <div className="vertical-line-flow-pending" />
          )}
          {stepStatus?.step1?.value > 2 && data[3].length > 0 && (
            <div className="vertical-line-flow" />
          )}
        </div>
      )}
      {stepStatus?.step1?.value > 2 && data[3][data[3].length - 1] != null && (
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <div
              className={`rounded-circle ${
                stepStatus?.step1?.value === 5 ||
                (stepStatus?.step1?.value > 2 && data[3].length === 0)
                  ? "vertical-pending-rounded"
                  : stepStatus?.step1?.value < 3
                  ? "vertical-pending-rounded"
                  : stepStatus?.step1?.value === 3
                  ? "vertical-process-rounded"
                  : "vertical-complete-rounded"
              } d-flex justify-content-center align-items-center`}
            >
              {stepStatus?.step1?.value > 3 &&
                data[3].length > 0 &&
                stepStatus?.step1?.value !== 5 && (
                  <img src={iconList.check} alt="img" />
                )}
              {stepStatus?.step1?.value === 3 ||
                (stepStatus?.step1?.value > 3 && data[3].length === 0 && (
                  <div className="rounded-circle vertical-on-course vertical-rounded-process" />
                ))}
              {stepStatus?.step1?.value < 3 ||
                (stepStatus?.step1?.value > 3 && data[3].length === 0) ||
                (stepStatus?.step1?.value === 5 && (
                  <div className="rounded-circle vertical-rounded-pending-process" />
                ))}
            </div>
            <div className="d-grid ml-3">
              <span className="vertical-custom-status-following">En ruta</span>
            </div>
          </div>
          <div className="date-container">
            <span className="date-size">
              {data[3].length > 0 &&
                parseDate(data[3][data[3].length - 1].updatedAt)}
            </span>
          </div>
        </div>
      )}
      {(stepStatus?.step1?.value > 3 || stepStatus?.step1?.value === 5) && (
        <div className="d-flex justify-content-center line-container">
          {(stepStatus?.step1?.value === 5 ||
            (stepStatus?.step1?.value > 3 && data[4].length === 0)) && (
            <div className="vertical-line-flow-pending" />
          )}
          {stepStatus?.step1?.value > 3 && data[4].length > 0 && (
            <div className="vertical-line-flow" />
          )}
        </div>
      )}
      {/* aca sigue la logica de estados */}
      {stepStatus?.step1?.value > 3 &&
        stepStatus?.step1?.value < 6 &&
        data[4][data[4].length - 1] != null && (
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div
                className={`rounded-circle ${
                  stepStatus?.step1?.value === 5
                    ? "vertical-pending-rounded"
                    : stepStatus?.step1?.value === 4 && data[4].length > 0
                    ? "vertical-complete-rounded"
                    : "vertical-pending-rounded"
                } d-flex justify-content-center align-items-center`}
              >
                {stepStatus?.step1?.value === 4 &&
                  data[4].length > 0 &&
                  stepStatus?.step1?.value !== 5 && (
                    <img src={iconList.check} alt="img" />
                  )}
                {stepStatus?.step1?.value === 4 &&
                  data[4].length === 0 &&
                  stepStatus?.step1?.value !== 5 && (
                    <div className="rounded-circle vertical-on-course vertical-rounded-process" />
                  )}
                {stepStatus?.step1?.value > 4 &&
                  data[4].length === 0 &&
                  stepStatus?.step1?.value !== 5 && (
                    <div className="rounded-circle vertical-rounded-pending-process" />
                  )}
              </div>
              <div className="d-grid ml-3">
                <span className="vertical-custom-status-following">
                  Entregado
                </span>
              </div>
            </div>
            <div className="date-container">
              <span className="date-size">
                {data[4].length > 0 && parseDate(data[4][data[4].length - 1])}
              </span>
            </div>
          </div>
        )}
      {stepStatus?.step1?.value === 6 &&
        data[5][data[5].length - 1] != null && (
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div
                className={`rounded-circle ${
                  stepStatus?.step1?.value === 5
                    ? "vertical-pending-rounded"
                    : stepStatus?.step1?.value < 6
                    ? "vertical-pending-rounded"
                    : "vertical-complete-rounded"
                } d-flex justify-content-center align-items-center`}
              >
                {stepStatus?.step1?.value === 6 && (
                  <img src={iconList.check} alt="img" />
                )}
                {stepStatus?.step1?.value < 6 && (
                  <div className="rounded-circle vertical-rounded-pending-process" />
                )}
                {stepStatus?.step1?.value === 5 && (
                  <div className="rounded-circle vertical-rounded-pending-process" />
                )}
              </div>
              <div className="d-grid ml-3">
                <span className="vertical-custom-status-following">
                  Entrega Fallida
                </span>
              </div>
            </div>
            <div className="date-container">
              <span className="date-size">
                {data[5].length > 0 && parseDate(data[5][data[5].length - 1])}
              </span>
            </div>
          </div>
        )}
    </div>
  );
}

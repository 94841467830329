import Axios from "../config/axios-config";
import AxiosV2 from "../config/axios-v2-config";

export const getRoutesApi = async () => {
    const clients = await Axios.get("route").catch((err) => {
      return { error: err.response.data };
    });
  
    return clients;
  };

  export const getRoute = async (routeId) => {
    const clients = await Axios.get("route/" + routeId).catch((err) => {
      return { error: err.response.data };
    });
  
    return clients;
  };

  //ksandoval - 18-07
  export const getRoutesWithDetails = async () => {
    const clients = await Axios.get("routesWithDetails").catch((err) => {
      return { error: err.response.data };
    });
  
    return clients;
  };

  // export const addRouteApi = async (newRoute) => {
  //   await Axios.post("route", newRoute).catch((err) => {
  //     return { error: err.response.data };
  //   });

  // };

  export const addRouteApi = async (newRoute) => {
    try {
      const response = await Axios.post("route", newRoute);
      return response.data; // Retornar los datos de la respuesta en caso de éxito
    } catch (error) {
      console.error("Error al crear la ruta:", error);
      return { error: error.response ? error.response.data : error.message };
    }
  };

  //api v2
  export const addRouteApiNew = async (newRoute) => {
    await AxiosV2.post("route", newRoute).catch((err) => {
      return { error: err.response.data };
    });

  };

  export const addToRouteApi = async (routeId, clientsId) => {
    await Axios.post("addToRoute", {id: routeId, clientsId}).catch((err) => {
        return { error: err.response.data };
        });
    }

  // export const deleteRoutesApi = async (routeIds) => {
  //   await Axios.delete("route", {id: routeIds}).catch((err) => {
  //     return { error: err.response.data };
  //   });
  // }

  export const deleteRoutesApi = async (routeId) => {
    try {
      const response = await Axios.delete("route", {
        data: { id: routeId },
      });
      return response.data;
    } catch (err) {
      return { error: err.response.data };
    }
  };

  export const deleteClientFromRouteApi = async (routeId, clientId) => {
    await Axios.post("deleteClient", {id: routeId, clientId}).catch((err) => {
        return { error: err.response.data };
        });
    }

  export const assignDeliveryApi = async (routeId, deliveryId) => {
    await Axios.patch(`route/${routeId}`, {data: {deliveryMan: deliveryId}}).catch((err) => {
      return { error: err.response.data };
    });
  }

  export const updateRoute = async (routeId, updatedRouteData) => {
    await Axios.patch(`route/${routeId}`, { data: updatedRouteData }).catch((err) => {
      return { error: err.response.data };
    });
  };
import React, { useState } from "react";
import { FullInput } from "../../components/inputs";

import { ClientInfo } from "../../components/routes";

import { FunctionButton } from "../../components/buttons/buttons";

import RouteMap from "../../components/map/routeMap";

import Route from "../../assets/route.svg";
import Tune from "../../assets/tune.svg";
import Road from "../../assets/add_road.svg";

import useRoutes from "../../store/routes";
import { useEffect } from "react";

import Spinner from "../../components/spinner";
import { addRouteApi, addToRouteApi } from "../../api/routes";
import { toast } from "react-toastify";

const LeftSide = () => {
  const clients = useRoutes((state) => state.clients);
  const selectedClients = useRoutes((state) => state.selectedClients);
  const selectAll = useRoutes((state) => state.selectAll);
  const sortClientsBySelected = useRoutes(
    (state) => state.sortClientsBySelected
  );
  const setClientQuery = useRoutes((state) => state.setClientQuery);
  const clientQuery = useRoutes((state) => state.clientQuery);
  const routes = useRoutes((state) => state.routes);
  const fetchRoutes = useRoutes((state) => state.fetchRoutes);
  const deselectAllClients = useRoutes((state) => state.deselectAllClients);
  const selectedRoutes = useRoutes((state) => state.selectedRoutes);

  const [unselectedClients, setUnselectedClients] = useState([]);

  //THATS FILTER THE INPUT QUERY LOCATED IN LEFT SIDE
  const filterClients = (clients) => {
    // console.log("los clientes in filterClients:: ", clients);
    let filteredClients = clients;

    try {
      if (clientQuery !== "") {
        filteredClients = filteredClients.filter((client) => {
          const fullName = `${client.name} ${client.lastName}`;
          
          // comentario 10-05-2024

          return (
            // DvlprChris
            (client.businessName &&
              client.businessName
                .toLowerCase()
                .includes(clientQuery.toLowerCase())) ||
            fullName.toLowerCase().includes(clientQuery.toLowerCase()) ||
            (client.shippingAddress &&
              client.shippingAddress
                .toLowerCase()
                .includes(clientQuery.toLowerCase())) ||
            (client.address &&
              client.address
                .toLowerCase()
                .includes(clientQuery.toLowerCase())) ||
            (client.shippingMunicipality &&
              client.shippingMunicipality
                .toLowerCase()
                .includes(clientQuery.toLowerCase())) ||
            (client.municipality &&
              client.municipality
                .toLowerCase()
                .includes(clientQuery.toLowerCase()))
          );
        });
      }

      // DvlprChris, quitar registro 0 si hay resultados en filtro
      if (filteredClients.length > 1 && filteredClients[0].name === "0") {
        // console.log("quitando registro 0 de los clientes filtrados");
        filteredClients = filteredClients.slice(1);
      }

      return filteredClients;
    } catch (error) {
      return filteredClients;
    }
  };
  //THATS FILTER THE CLIENTS THAT ARE ALREADY IN A ROUTE, SO THEY CANT BE SELECTED AGAIN
  function filterClientsInRoutes(routes, clients) {
    const routesClients = routes.flatMap((route) =>
      route.clients.map((client) => client._id)
    );
    const uniqueClients = [...new Set(routesClients)];

    return clients.filter((client) => !uniqueClients.includes(client._id));
  }

  useEffect(() => {
    sortClientsBySelected();
  }, [clientQuery]);

  useEffect(() => {
    const filteredClients = filterClientsInRoutes(routes, clients);
    // console.log("todos los clientes:::.", filteredClients);
    setUnselectedClients(filteredClients);
  }, [routes, clients]);

  return (
    <section className="w-1/2 h-[580px]  p-2 border rounded-lg border-gray-400">
      <div className="flex justify-between mb-2">
        <div>
          <FunctionButton
            content="Seleccionar todos"
            onClick={() => selectAll()}
          />
        </div>
        <div className="flex gap-1">
          <FunctionButton
            icon={Road}
            content="Asignar a ruta existente"
            onClick={() => {
              if (selectedRoutes.length === 0) {
                toast.error("No hay rutas seleccionadas");
                return;
              }
              if (selectedRoutes.length > 1) {
                toast.error("Solo puedes seleccionar una ruta");
                return;
              }
              toast
                .promise(addToRouteApi(selectedRoutes[0], selectedClients), {
                  pending: "Cargando",
                  success: "Clientes agregados a la ruta",
                  error: "Error al agregar clientes a la ruta",
                })
                .then(() => {
                  fetchRoutes().then(() => {
                    deselectAllClients();
                  });
                });
            }}
          />
          <FunctionButton
            icon={Route}
            content="Crear ruta"
            onClick={() => {
              addRouteApi({
                data: {
                  clients: selectedClients,
                  date: new Date(),
                  createdAt: new Date(),
                  updatedAt: new Date(),
                },
              }).then(() => {
                toast
                  .promise(fetchRoutes(), {
                    pending: "Cargando",
                    success: "Ruta creada con éxito",
                    error: "Error al crear la ruta",
                  })
                  .then(() => {
                    deselectAllClients();
                  });
                // fetchRoutes().then(() => {
                // deselectAllClients()
                //     toast.success("Ruta creada con éxito")
                // })
              });
            }}
            inverted
          />
        </div>
      </div>
      <div className="mb-2">
        {/* dvlpr_chris - aca trabajando 16-03-2024 */}
        <FullInput
          placeholder="Buscar por cliente, dirección o comuna"
          value={clientQuery}
          onChange={(e) => {
            setClientQuery(e.target.value);
          }}
        />
      </div>
      <div className="overflow-y-scroll yellow-scrollbar max-h-[480px]">
        {clients === undefined ? (
          <div>loading</div>
        ) : (
          filterClients(unselectedClients).map((client) => {
            // console.log("client", client)
            const clientName = client.businessName
              ? client.businessName.toUpperCase()
              : `${client.name.toUpperCase()} ${client.lastName.toUpperCase()}`;

            // Convertir solo las letras a mayúsculas en la dirección
            const clientAddress = client.shippingAddress
              ? client.shippingAddress.replace(
                  /[a-zA-ZñÑáéíóúÁÉÍÓÚ]+/g,
                  (match) => match.toUpperCase()
                )
              : client.address
              ? client.address.toUpperCase()
              : "";

            const clientMunicipality = client.shippingMunicipality
              ? client.shippingMunicipality.replace(
                  /[a-zA-ZñÑáéíóúÁÉÍÓÚ]+/g,
                  (match) => match.toUpperCase()
                )
              : client.municipality
              ? client.municipality.toUpperCase()
              : "";
            // console.log("new clientName", clientName)
            // console.log("new clientAddress", clientAddress)
            // console.log("new clientMunicipality", clientMunicipality)

            return (
              // <div key={`${client.name} ${client.lastName}`}>
              <div key={`${client._id}`}>
                <ClientInfo
                  id={client._id}
                  name={clientName}
                  address={clientAddress}
                  municipality={clientMunicipality}
                  hour={client.preferedHour}
                />
                <div className="h-[2px] my-[6px] w-full bg-gray-200" />
              </div>
            );
          })
        )}
      </div>
    </section>
  );
};

const RightSide = () => {
  const clients = useRoutes((state) => state.clients);
  const routes = useRoutes((state) => state.routes);
  const [unselectedClients, setUnselectedClients] = useState([]);

  function filterClientsInRoutes(routes, clients) {
    const routesClients = routes.flatMap((route) =>
      route.clients.map((client) => client._id)
    );
    const uniqueClients = [...new Set(routesClients)];

    return clients.filter((client) => !uniqueClients.includes(client._id));
  }

  useEffect(() => {
    const filteredClients = filterClientsInRoutes(routes, clients);
    setUnselectedClients(filteredClients);
  }, [routes, clients]);

  return (
    <section className="w-1/2 h-[580px]  p-2 border rounded-lg border-gray-400">
      <RouteMap
        places={unselectedClients.map(
          (client) => client.addressCoords || { lat: 0, lng: 0 }
        )}
      />
    </section>
  );
};

export default function Operation({ weekly }) {
  const [loading, setLoading] = useState(false);
  const fetchClients = useRoutes((state) => state.fetchClients);
//  console.log("weekly:::", weekly)
 
  useEffect(() => {
    setLoading(true);
    fetchClients(weekly).then(() => {
      setLoading(false);
    });
  }, [weekly]);
  return (
    <div>
      <div className="flex gap-2">
        {loading ? (
          <div className="flex justify-center items-center w-full h-[75vh]">
            <Spinner />{" "}
          </div>
        ) : (
          <>
            <LeftSide weekly={weekly} />
            <RightSide />
          </>
        )}
      </div>
    </div>
  );
}

import { utils, writeFile } from "xlsx";
import { packageStatus } from "./packageStatus";
import { formatRut } from "./formatter";

export const downloadExcel = async ({ dataToExcel, fileName }) => {
  try {
    const data = dataToExcel?.data?.result;
    let filename = fileName;
    let wb = utils.book_new();
    let ws_name = "hoja 1";
    let ws_data = [
      [
        "Paquete",
        "FECHA DE CREACIÓN DEL ENVÍO",
        "COMENTARIO DEL PAQUETE",
        "NÚMERO DE SEGUIMIENTO",
        "NOMBRE DEL DESTINATARIO",
        "NÚMERO DEL DESTINATARIO",
        "ESTADO DEL PAQUETE",
        "ENTREGADO A LAS",
        "NÚMERO DE VENTA",
        "NOMBRE Y APELLIDO DEL REPARTIDOR",
        "DIRECCIÓN DE ORIGEN",
        "NOMBRE DEL VENDEDOR",
        "APELLIDO DEL VENDEDOR",
        "COMUNA DEL VENDEDOR",
        "NOMBRE DEL RECEPTOR",
        "APELLIDO DEL RECEPTOR",
        "RUT DEL RECEPTOR",
        "COMUNA DE DESTINO",
        "DIRECCIÓN DE DESTINO",
        "COMENTARIO DEL ENVÍO",
        "DIRECCIÓN COMPLEMENTARIA",
      ],
    ];
    data?.forEach((item, index) => {
      ws_data.push([
        index + 1,
        item?.createdAt || null,
        item?.comments,
        item?.followingNumber,
        item?.receiver_name,
        item?.receiver_phone,
        packageStatus[item?.status],
        (typeof item[4][0] == "string" ? item[4][0] : item[4][0]?.updatedAt) || "",
        item?.order_id,
        `${item?.assignTo?.name || ""} ${item?.assignTo?.lastName || ""}`,
        item?.senderUser?.senderAddress,
        item?.senderUser?.senderName,
        item?.senderUser?.senderLastName,
        item?.senderUser?.senderNeighborhood,
        item?.realReceiver?.name,
        item?.realReceiver?.lastName,
        formatRut(item?.realReceiver?.rut) || "",
        item?.shipping_address?.city?.name,
        item?.shipping_address?.street_name,
        item?.shipping_address?.comment,
        item?.shipping_address?.address_line,
      ]);
    });
    let ws = utils.aoa_to_sheet(ws_data);
    var col = [
      { wpx: 50 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
    ];
    ws["!cols"] = col;
    utils.book_append_sheet(wb, ws, ws_name);
    writeFile(wb, filename + ".xlsx");
  } catch (error) {
    console.error(error);
  }
};

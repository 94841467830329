import React from "react";
import { Button } from 'reactstrap'
import { iconList } from '../../utils/iconList'

export function PrimaryButton({ content, icon }) {
    return (
        <div>
            <Button className="custom-primary-button">{icon && <img src={iconList[icon]} alt="icon" className="custom-icon" />}<label className="ml-2">{content}</label></Button>
        </div>
    )
}

export function SecondaryButton({ content, icon }) {
    return (
        <div>
            <Button className="custom-secondary-button">{icon && <img src={iconList[icon]} alt="icon" className="custom-second-icon" />}<label className="ml-2">{content}</label></Button>
        </div>
    )
}

export function FunctionButton({content, icon, onClick, inverted = false}){
    return(
        <div
            className={`flex gap-1 items-center text-sm py-1 px-2 w-fit cursor-pointer border-gray-400 border rounded-lg  ${ inverted ? 'bg-gray-900 text-gray-200 hover:bg-gray-800' : 'bg-white hover:bg-gray-200' }`}
            onClick={onClick}
            onKeyDown={onClick}
          >
            {icon && <img src={icon} className={`w-4 h-4 ${inverted ? "invert" : ""}`} alt="filters" />}
            <span>
            {content}
            </span>
          </div>)
}
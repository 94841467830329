import React from 'react'

import Clock from "../../assets/schedule.svg"

import { FunctionButton } from '../buttons/buttons'

import  useRoutes  from "../../store/routes"

import useModal  from "../../store/modal"

export function ClientInfo({id, name, address= "Sin Dirección", municipality, hour = "Horario"}) {

    const selectedClients = useRoutes(state => state.selectedClients)
    const toggleClient = useRoutes(state => state.toggleClient)
    const openModal = useModal(state => state.openModal)


  return (
    <div className="flex justify-between items-center  px-1">
        <div className="flex gap-2 items-center">
        <input type="checkbox" checked={selectedClients.includes(id)} onChange={
            (e) => {
                toggleClient(id, e.target.checked)
            }
        } className="clickable-correlative checkbox-custom-style form-check-input " />
        <div className="flex flex-col gap-1">
            <h3>{name}</h3>
            <p>{address}, <b>{municipality}</b></p>
        </div>
    </div>
    {/* <div className="px-2 flex items-center gap-1 cursor-pointer py-1 text-xs border border-gray-400 rounded-xl">
        <img src={Clock} className="w-3 h-3" alt="" />
        {hour}
    </div> */}
    <FunctionButton onClick={() => {openModal("preferedHour",{id})}} content={hour} icon={Clock} />
    </div>
  )
}

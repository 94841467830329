import React, { useEffect, useState, useMemo } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { formatRut } from "../../utils/formatter";
import {
  packageStatus,
  packageStatusEvidenceStyles,
} from "../../utils/packageStatus";
import VerticalStepper from "../verticalStepper";
import Map from "../map";
import moment from "moment/moment";
import { getOnePackage } from "../../api/packages";

import JSZip from 'jszip';
import { saveAs } from 'file-saver';


const imageClasses = {
  1: "w-100 justify-content-center",
  2: "w-70 justify-content-between",
  3: "w-100 justify-content-between"
};

function calcularHoras(segundos) {
  const horaActual = new Date();
  let hora1, hora2;
  if (segundos < 900) {
    hora1 = new Date(horaActual.getTime() + 30 * 60 * 1000);
    hora2 = new Date(horaActual.getTime() + 60 * 60 * 1000);
  } else if (segundos < 1800) {
    hora1 = new Date(horaActual.getTime() + 60 * 60 * 1000);
    hora2 = new Date(horaActual.getTime() + 90 * 60 * 1000);
  } else if (segundos < 3600) {
    hora1 = new Date(horaActual.getTime() + 90 * 60 * 1000);
    hora2 = new Date(horaActual.getTime() + 120 * 60 * 1000);
  } else {
    hora1 = new Date(horaActual.getTime() + 120 * 60 * 1000);
    hora2 = new Date(horaActual.getTime() + 240 * 60 * 1000);
  }

  const hora1Str = hora1.toLocaleTimeString('es-AR', { hour: '2-digit', minute: '2-digit' });
  const hora2Str = hora2.toLocaleTimeString('es-AR', { hour: '2-digit', minute: '2-digit' });
  
  return `${hora1Str} - ${hora2Str}`;
}

export default function EvidenceView({ selectedPackage }) {
  // const evidencePackage = completePackages.find(
  //   (f) => f?.followingNumber === selectedPackage?.correlative?.comparative
  // );

  const [evidencePackage, setEvidencePackage] = useState(null)

  const stepsValue = { step1: { value: evidencePackage?.status } };

  const deliveredHour = useMemo(() => {
    if(!evidencePackage) return
    return evidencePackage[4][evidencePackage[4].length - 1]?.updatedAt || evidencePackage[4][evidencePackage[4].length - 1] || null
  }, [evidencePackage])

  const debugBase64 = (base64URL) => {
    let win = window.open("about:blank", "Evidencia");
    win.document.write(
      `<img src="${base64URL}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;object-fit: contain;" allowfullscreen></img>`
    );
    // win.document.title = "Evidencia"
  };


  useEffect(() => {
    // console.log(selectedPackage)
    getOnePackage(selectedPackage?.correlative.comparative).then((res) => {
      setEvidencePackage(res)
    })
  }, [selectedPackage]);

  if (!evidencePackage) return <div>Cargando...</div>;

  const downloadImagesAsZip = async (images, followingNumber) => {
    const zip = new JSZip();
  
    for (const [index, img] of images.entries()) {
      // Revisa si la imagen es Base64 o una URL
      if (img.startsWith('data:image')) {
        // Es una imagen Base64, la convertimos a Blob
        const base64Response = await fetch(img);
        const blob = await base64Response.blob();
        zip.file(`${followingNumber}-evidence-${index + 1}.jpg`, blob, { binary: true });
      } else {
        // Es una URL, hacemos un fetch y luego convertimos a Blob
        const response = await fetch(img);
        const blob = await response.blob();
        zip.file(`${followingNumber}-evidence-${index + 1}.jpg`, blob, { binary: true });
      }
    }
    // Generar el archivo zip y descargarlo
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `${followingNumber}-evidence.zip`); // Usar FileSaver para guardar el .zip
    });
  };
 
  return (
    <div className="custom-evidence-container mt-4">
      <div className="mt-2">
        <span className="custom-evidence-container__title">
          Pedido #{evidencePackage?.followingNumber}
        </span>
      </div>
      <Row className="mt-4">
        <Col xs="6" md="6" xl="6">
          <Card className="custom-evidence-card">
            <CardBody className="d-grid">
              <span className="custom-evidence-card__title">Resumen</span>
              <div className="custom-evidence-card__divider" />
              <span className="custom-evidence-card__subText">
                {evidencePackage?.status === 4
                  ? "Tu paquete fue entregado a las:"
                  : "El horario de entrega de tu paquete es:"}
              </span>
              <span className="custom-evidence-card__schedule mt-2">
                {evidencePackage?.status === 4
                  ? moment(
                    deliveredHour
                    ).format("HH:mm")
                  : evidencePackage.timeFromDelivery ? calcularHoras(evidencePackage?.timeFromDelivery) : "14:00 - 21:00"}
              </span>
              <span className="custom-evidence-card__date mt-2">
                {evidencePackage?.status === 4
                  ? moment(
                    deliveredHour
                    ).format("DD MMM YYYY")
                  : moment(evidencePackage?.createdAt).format("DD MMM YYYY")}
              </span>
            </CardBody>
          </Card>
          <Card className="custom-evidence-card mt-3">
            <CardBody className="d-grid">
              <span className="custom-evidence-card__title">
                Detalles del pedido
              </span>
              <div className="custom-evidence-card__divider" />
              <div className="d-flex justify-content-between mt-3">
                <span className="custom-evidence-card__question">
                  Código de seguimiento:
                </span>
                <span className="custom-evidence-card__response">
                  {evidencePackage?.followingNumber}
                </span>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <span className="custom-evidence-card__question">
                  Fecha de ingreso:
                </span>
                <span className="custom-evidence-card__response">
                  {moment(evidencePackage?.createdAt).format(
                    "DD MMM YYYY - HH:mm"
                  )}
                </span>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <span className="custom-evidence-card__question">Cliente:</span>
                <span className="custom-evidence-card__response">
                  {evidencePackage?.receiver_name}{" "}
                  {evidencePackage?.receiver_lastName}
                </span>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <span className="custom-evidence-card__question">Estado:</span>
                <span
                  className={`${
                    packageStatusEvidenceStyles[evidencePackage?.status]
                  }`}
                >
                  {packageStatus[evidencePackage?.status]}
                </span>
              </div>
            </CardBody>
          </Card>
          <Card className="custom-evidence-card mt-3">
            <CardBody className="d-grid">
              <span className="custom-evidence-card__title">Eventos</span>
              <div className="custom-evidence-card__divider" />
              {/* DvlprChris - VerticalStepper contiene la logica de estados */}
              <VerticalStepper stepStatus={stepsValue} data={evidencePackage} />
            </CardBody>
          </Card>
        </Col>
        <Col xs="6" md="6" xl="6">
          <Card className="custom-evidence-card mb-3">
            <CardBody className="d-grid">
              <span className="custom-evidence-card__title">
                Dirección de entrega
              </span>
              <div className="custom-evidence-card__divider" />
              <div className="d-grid">
                <span className="custom-evidence-card__response">
                  {selectedPackage?.destiny}
                </span>
                {(() => {
                  switch (evidencePackage?.status) {
                    case 4:
                      return <Map coords={evidencePackage?.coords} id="map"/>;
                    case 6:
                      return <Map coords={evidencePackage?.coords} id="map"/>;
                    default:
                      return null;
                  }
                })()}
              </div>
            </CardBody>
          </Card>
          <Card className="custom-evidence-card">
            <CardBody className="d-grid">
              <span className="custom-evidence-card__title">
                Detalles de la entrega
              </span>
              <div className="custom-evidence-card__divider" />
              <div className="d-flex justify-content-between">
                <span className="custom-evidence-card__question">
                  Nombre de quién recibe:
                </span>
                <span className="custom-evidence-card__response">
                  {evidencePackage?.realReceiver?.name}{" "}
                  {evidencePackage?.realReceiver?.lastName}
                </span>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <span className="custom-evidence-card__question">
                  RUT de quién recibe:
                </span>
                <span className="custom-evidence-card__response">
                  {formatRut(evidencePackage?.realReceiver?.rut)}
                </span>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <span className="custom-evidence-card__question">
                  Hora de entrega
                </span>
                <span className="custom-evidence-card__response">
                  {evidencePackage[4]?.length > 0
                    ? moment(
                      deliveredHour
                      ).format("DD MMM YYYY - HH:mm")
                    : ""}
                </span>
              </div>
            </CardBody>
          </Card>
          <Card className="custom-evidence-card mt-3">
          <CardBody className="d-grid">
              <div className="d-flex justify-content-between align-items-center">
                <span className="custom-evidence-card__title">
                  {evidencePackage?.status === 4 ? "Evidencia de entrega" :
                  evidencePackage?.status === 6 ? "Evidencia de intento de entrega" :
                  "Evidencia de entrega"}
                </span>
                {(evidencePackage?.status === 4 || evidencePackage?.status === 6) && evidencePackage?.images?.length > 0 && (
                  <button onClick={() => downloadImagesAsZip(evidencePackage.images, evidencePackage.followingNumber)} className="download-btn">
                    Descargar todas las imágenes
                  </button>
                )}
              </div>
              <div className="custom-evidence-card__divider" />
              <div className="w-100 d-flex items-center  gap-2 flex-col justify-content-center">
                {(() => {
                  switch (evidencePackage?.status) {
                    case 4:
                      return (
                        <span className="custom-evidence-card__response">
                          Tu paquete fue entregado
                        </span>
                      );
                    case 6:
                      return (
                        <span className="custom-evidence-card__response">
                          Tu paquete no pudo ser entregado<span className="italic font-bold">: {evidencePackage?.failed?.option}</span>
                        </span>
                      );
                    default:
                      return (
                        <span className="custom-evidence-card__response">
                          Aquí verás las fotos de evidencia de tu entrega
                        </span>
                      );
                  }
                })()}
                <div className="flex gap-2 items-center justify-center">
                  {evidencePackage?.images?.length > 0 && (
                      <>
                        {evidencePackage.status === 4 && evidencePackage.images.map((img, index) => (
                          <img
                            key={index}
                            className="custom-proof-img"
                            src={img}
                            alt="evidence"
                            onClick={() => debugBase64(img)}
                          />
                        ))}
                        {evidencePackage.status === 6 && evidencePackage.failed?.images?.length > 0 && evidencePackage.failed.images.map((img, index) => (
                          <img
                            key={index}
                            className="custom-proof-img"
                            src={img}
                            alt="attempt evidence"
                            onClick={() => debugBase64(img)}
                          />
                        ))}
                      </>
                    )}
                  {/* {evidencePackage?.status === 4 && evidencePackage?.images?.length > 0 && (
                    <>
                      {evidencePackage.images.map((img, index) => (
                        <img
                          key={index}
                          className="custom-proof-img"
                          src={img}
                          alt="evidence"
                          onClick={() => debugBase64(img)}
                        />
                      ))}
                    </>
                    )}
                  {evidencePackage?.status === 6 && evidencePackage?.failed?.images?.length > 0 && (
                    <>
                        {evidencePackage.failed.images.map((img, index) => (
                          <img
                            key={index}
                            className="custom-proof-img"
                            src={img}
                            alt="attempt evidence"
                            onClick={() => debugBase64(img)}
                          />
                        ))}
                    </>
                  )} */}
                </div>
              </div>
              {
                  evidencePackage?.status === 6 && evidencePackage?.failed?.coords ? <>
                          <div className="custom-evidence-card__divider" />
                          <div className="w-100 d-flex items-center  gap-2 flex-col justify-content-center">
                            <span className="custom-evidence-card__response">
                              Ubicación del intento de entrega
                            </span>
                            <Map coords={evidencePackage?.failed?.coords} id="evidenceMap" className="w-full h-52"/>

                          </div>
                  </> : <></>
                }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { toAmount } from "../../utils/formatter";

export default function Card({ title, icon, value, rotate, total }) {
  // DvlprChris
  const [config, setConfig] = useState(null);
  const [storedColor, setStoredColor] = useState(null);
  useEffect(() => {
    try {
      const cachedConfig = localStorage.getItem("appConfig");
      setConfig(JSON.parse(cachedConfig));
      //obtener color de la API
      const configObject = JSON.parse(cachedConfig);
      const colorFromAPI = String(configObject.color);
      setStoredColor(colorFromAPI);
    } catch (error) {
      console.error(
        "Error al obtener la configuración del localStorage:",
        error
      );
    }
  }, []);
  // DvlprChris

  return (
    // <div className="d-flex" style={mainCardStyle}>
    <div
      className="main-card-style d-flex"
      style={{ "--color-dinamico": storedColor, width: `calc(97% / ${total})` }}
    >
      <div style={{ minWidth: "130px" }}>
        <div className="main-card-style__title">{title}</div>
        <div className="main-card-style__value">{toAmount(value)}</div>
      </div>
      <div className="w-100 d-flex justify-content-end">
        <div className="img-container">
          <img
            className={`main-card-style__icon ${rotate && "rotate-image"}`}
            src={icon}
            alt="icon"
          />
        </div>
      </div>
    </div>
  );
}

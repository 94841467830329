import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Col,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { generateInformApi } from "../../../api/inform";
import { iconList } from "../../../utils/iconList";
import SearchBar from "../../../components/searchBar";
import useAuth from "../../../hooks/useAuth";
import moment from "moment/moment";

export default function ClientInform() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [selectedDate, setSelectedDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [loading, setLoading] = useState(false);

  const isDisabled = !selectedDate?.endDate || !selectedDate?.startDate;

  const generateInform = () => {
    const values = {
      startDate: selectedDate?.startDate,
      endDate: selectedDate?.endDate,
      clientId: user?.id,
    };

    setLoading(true);
    generateInformApi(values, "client").then(() => setLoading(false));
  };


//   const [executed, setExecuted] = useState(false);

//   useEffect(() => {
//     verifyMLCode();
// }, [executed]);


//   const  verifyMLCode = () => {
//     setExecuted(true);
//     if(executed)
//       console.log("entre aca, estado de executed is:::", executed);
//   };

  return (
         // se cambia componente section por div con estilos segun standar - ksandoval - 14-05
         <div className="w-100 all-orders-container mt-6">
      {/* <SearchBar
        message={"Buscar por número de orden, destino o quién recibe"}
      /> */}
      <Breadcrumb className="custom-breadcrumb-container">
        <BreadcrumbItem
          className="d-flex align-items-center cursor-pointer"
          onClick={() => navigate("/admin")}
        >
          <img src={iconList.home} alt="home" className="mr-2" />
          <span>Inicio</span>
        </BreadcrumbItem>
        <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
          <span>Informes</span>
        </BreadcrumbItem>
        <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
          <span>Crear informe</span>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="d-flex justify-content-between">
        <span className="w-100 d-flex justify-content-between">
          <h2 className="h2-custom-size all-users-container__title">
            Informes
          </h2>
        </span>
      </div>
      <Card className="custom-inform-card">
        <div className="d-flex justify-content-between mt-4">
          <Col xs="6" md="6" xl="6" className="pr-3">
            <Label className="mb-2 custom-label-inform">Fecha</Label>
            <Input
              type="date"
              max={moment(selectedDate?.endDate)
                .subtract(1, "days")
                .format("YYYY-MM-DD")}
              onChange={(e) =>
                setSelectedDate({
                  ...selectedDate,
                  startDate: moment(e.target.value).format("MM/DD/YYYY"),
                })
              }
            />
          </Col>
          <Col xs="6" md="6" xl="6" className="pl-3">
            <Label className="mb-2 custom-label-inform">Hasta</Label>
            <Input
              type="date"
              min={moment(selectedDate?.startDate)
                .add(1, "days")
                .format("YYYY-MM-DD")}
              onChange={(e) =>
                setSelectedDate({
                  ...selectedDate,
                  endDate: moment(e.target.value).format("MM/DD/YYYY"),
                })
              }
            />
          </Col>
        </div>
        <div className="w-100 d-flex align-items-center">
          <Button
            color="dark"
            className="mt-4 custom-inform-button bg-black"
            disabled={isDisabled || loading}
            onClick={() => generateInform()}
          >
            Descargar informe
          </Button>
          {loading && <Spinner className="ml-3 mt-4" />}
        </div>
      </Card>
    </div>
  );
}

import Axios from "../config/axios-config";
import { downloadExcel } from "../utils/downloadExcel";

export const generateInformApi = async (values, type) => {
  const getInformData = await Axios.post("generate-inform", values, {timeout: 0}).catch(
    (err) => {
      return { error: err.response.data };
    }
  );

  const filename =
    type === "all"
      ? "Informe Consolidado"
      : type === "client"
      ? "Informe por Cliente"
      : "Informe por Repartidor";

  downloadExcel({ dataToExcel: getInformData, fileName: filename });
};

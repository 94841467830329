"use strict";
import React, { useState, useEffect } from "react";
import { Input, FormGroup, Label, Button } from "reactstrap";
import { posibleComs } from "../../utils/coms";
import { getDeliveryUsers, getActiveClientsApi } from "../../api/user";
import SearchDropdown from "../searchDropdown";
import { useRef } from 'react';


const FilterModal = ({ open, activeFilters, callback, closeModal, statusDisabled, admin }) => {
  const [filters, setFilters] = useState({});
  const [deliveryList, setDeliveryList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [isLoadingDelivery, setIsLoadingDelivery] = useState(false);
  const [isLoadingClient, setIsLoadingClient] = useState(false);


  const handleArrayListDelivery = async () => {
    setIsLoadingDelivery(true);
    try {
      const data = await getDeliveryUsers();
      // console.log("data from getDeliveryUsers in modal: ", data);

      if (data.status === 200) {
        const parsedDeliveryData = data.data?.deliveryList?.map((del) => {
          return { value: del?._id, label: `${del?.name} ${del?.lastName}` };
        });
        // console.log("before status 200 parsedDeliveryData", parsedDeliveryData);
        setDeliveryList(parsedDeliveryData);
        // console.log("after status 200 parsedDeliveryData to deliveryList", deliveryList);
      }
    } catch (error) {
      setIsLoadingDelivery(false);
    } finally {
      setIsLoadingDelivery(false);
    }
  };

  // useEffect(() => {
  //   return () => {
  //     if (open) {
  //       handleArrayListDelivery().then();
  //     }
  //   };
  // }, [open]);

  //ksandoval - palitos de fosforo
  useEffect(() => {
    if (prevOpen.current === false && open === true) {
      handleArrayListDelivery();
    }
    prevOpen.current = open;
  }, [open]);

  const applyFilters = () => {
    callback(filters);
    closeModal(false);
  };

  const clearFilters = () => {
    setFilters({});
    callback({});
    closeModal(false);
  };

  useEffect(() => {
    return () => {
      setFilters(activeFilters);
    };
  }, [activeFilters]);

  const handleArrayListClient = async () => {
    setIsLoadingClient(true);
    try {
      const data = await getActiveClientsApi();
      // console.log("data from getActiveClientsApi in modal: ", data);

      if (data.status === 200) {
        console.log("data from getActiveClientsApi in modal: ", data.data?.clients[0]);
        const parsedClientsData = data.data?.clients?.map((client) => {
          return { value: client?._id, label: client?.businessName ? client?.businessName : `${client?.name} ${client?.lastName}`};
        })
        // console.log("before status 200 parsedClientsData", parsedClientsData);
        setClientList(parsedClientsData)
        // console.log("after status 200 parsedClientsData to clientList", clientList);
      }
    } catch (error) {
      setIsLoadingClient(false);
    } finally {
      setIsLoadingClient(false);
    }
  };

  const prevOpen = useRef(false);

  // useEffect(() => {
  //   return () => {
  //     if (admin) {
  //       // console.log("if admin in useEffect: ", admin);
  //       handleArrayListClient().then();
  //     }
  //   };
  // }, [admin]);

  //palitos de fosforo aca
  useEffect(() => {
    if (open) {
      handleArrayListClient().then();
    }
  }, [open]);




  const orderBy = [
    { value: "createdAt", label: "Fecha de ingreso" },
    { value: "updatedAt", label: "Última actualización" },
  ];

  const selectedOrder = orderBy?.find((f) => f.value === filters?.orderBy);
  const selectedCom = posibleComs?.find((f) => f.value === filters?.coms);
  const selectedDelivery = deliveryList?.find(
    (f) => f.value === filters?.delivery
  );
  const selectedClient = clientList?.find(
    (f) => f.value === filters?.clientId
  );

  return (
    <React.StrictMode>

      <div>
        <div className="filter-modal">
          <div className="filter-modal__title-container">
            <span className="filter-modal__title">Filtrar y ordenar</span>
          </div>
          <div className="filter-modal__divider"></div>
          <div className="filter-modal__order-by-container d-flex align-items-center">
            <span className="w-30 filter-modal__order-by-title ml-5">
              Ordenar por
            </span>
            <SearchDropdown
              onChange={(value) => setFilters({ ...filters, orderBy: value })}
              options={orderBy}
              placeholder="Seleccione..."
              selected={selectedOrder?.label}
              className="w-50"
            />
          </div>
          <div className="filter-modal__divider"></div>
          <div className="filter-modal__state-container d-flex">
            <span className="w-30 filter-modal__state-title ml-5">Estado</span>
            <div className="w-70 ml-4">
              <div className="d-flex">
                <FormGroup
                  check
                  className="mr-3 filter-modal__state-statue-option"
                >
                  <Input
                    disabled={statusDisabled}
                    type="radio"
                    name="status"
                    className={`${
                      parseInt(filters?.status) === 0 && "custom-selected-radio"
                    }`}
                    value={0}
                    checked={parseInt(filters?.status) === 0}
                    onClick={(e) =>
                      setFilters({ ...filters, status: e.target.value })
                    }
                  />
                  <Label check>Pendiente</Label>
                </FormGroup>
                <FormGroup
                  check
                  className="mr-3 filter-modal__state-statue-option"
                >
                  <Input
                    disabled={statusDisabled}
                    type="radio"
                    name="status"
                    className={`${
                      parseInt(filters?.status) === 1 && "custom-selected-radio"
                    }`}
                    value={1}
                    checked={parseInt(filters?.status) === 1}
                    onClick={(e) =>
                      setFilters({ ...filters, status: e.target.value })
                    }
                  />
                  <Label check>Recolectado</Label>
                </FormGroup>
                <FormGroup check className="filter-modal__state-statue-option">
                  <Input
                    disabled={statusDisabled}
                    type="radio"
                    name="status"
                    className={`${
                      parseInt(filters?.status) === 2 && "custom-selected-radio"
                    }`}
                    value={2}
                    checked={parseInt(filters?.status) === 2}
                    onClick={(e) =>
                      setFilters({ ...filters, status: e.target.value })
                    }
                  />
                  <Label check>En bodega</Label>
                </FormGroup>
              </div>
              <div className="d-flex mt-3">
                <FormGroup
                  check
                  className="mr-3 filter-modal__state-statue-option"
                >
                  <Input
                    disabled={statusDisabled}
                    type="radio"
                    name="status"
                    className={`${
                      parseInt(filters?.status) === 3 && "custom-selected-radio"
                    }`}
                    value={3}
                    checked={parseInt(filters?.status) === 3}
                    onClick={(e) =>
                      setFilters({ ...filters, status: e.target.value })
                    }
                  />
                  <Label check>En ruta</Label>
                </FormGroup>
                <FormGroup
                  check
                  className="mr-3 filter-modal__state-statue-option"
                >
                  <Input
                    disabled={statusDisabled}
                    type="radio"
                    name="status"
                    className={`${
                      parseInt(filters?.status) === 4 && "custom-selected-radio"
                    }`}
                    value={4}
                    checked={parseInt(filters?.status) === 4}
                    onClick={(e) =>
                      setFilters({ ...filters, status: e.target.value })
                    }
                  />
                  <Label check>Entregado</Label>
                </FormGroup>
                <FormGroup check className="filter-modal__state-statue-option">
                  <Input
                    disabled={statusDisabled}
                    type="radio"
                    name="status"
                    className={`${
                      parseInt(filters?.status) === 5 && "custom-selected-radio"
                    }`}
                    value={5}
                    checked={parseInt(filters?.status) === 5}
                    onClick={(e) =>
                      setFilters({ ...filters, status: e.target.value })
                    }
                  />
                  <Label check>Cancelado</Label>
                </FormGroup>
                <FormGroup check className="filter-modal__state-statue-option">
                  <Input
                    disabled={statusDisabled}
                    type="radio"
                    name="status"
                    className={`${
                      parseInt(filters?.status) === 6 && "custom-selected-radio"
                    }`}
                    value={6}
                    checked={parseInt(filters?.status) === 6}
                    onClick={(e) =>
                      setFilters({ ...filters, status: e.target.value })
                    }
                  />
                  <Label check>Entrega Fallida</Label>
                </FormGroup>
              </div>
            </div>
          </div>
          <div className="filter-modal__divider"></div>
          <div className="filter-modal__coms-container d-flex align-items-center">
            <span className="w-30 filter-modal__order-by-title ml-5">
              Comuna de entrega
            </span>
            <SearchDropdown
              onChange={(value) => setFilters({ ...filters, coms: value })}
              options={posibleComs}
              placeholder="Seleccione..."
              selected={selectedCom?.label}
              className="w-50"
            />
          </div>

            <div className="filter-modal__divider"></div>

            <div className="filter-modal__coms-container d-flex align-items-center">
              <span className="w-30 filter-modal__order-by-title ml-5">
                Repartidor
              </span>
              <SearchDropdown
                onChange={(value) =>
                  setFilters({ ...filters, delivery: value })
                }
                options={deliveryList}
                placeholder={isLoadingDelivery ? 'Cargando repartidores...' : 'Seleccione...'}
                selected={selectedDelivery?.label}
                className="w-50"
              />
            </div>

          <div className="filter-modal__divider"></div>
          {admin && (
            <>
              <div className="filter-modal__coms-container d-flex align-items-center">
                <span className="w-30 filter-modal__order-by-title ml-5">
                  Cliente
                </span>
                <SearchDropdown
                  onChange={(value) =>
                    {
                      console.log(value)
                      setFilters({ ...filters, clientId: value })
                    }

                  }
                  options={clientList}
                  placeholder={isLoadingClient ? 'Cargando clientes...' : 'Seleccione...'}
                  selected={selectedClient?.label}
                  className="w-50"
                  tooltip
                />
              </div>
              <div className="filter-modal__divider"></div>
            </>
          )}
          <div className="filter-modal__date-container d-flex align-items-center">
            <span className="w-30 filter-modal__order-by-title ml-5">
              Fecha de ingreso
            </span>
            <div className="w-50">
              <Input
                className="w-100"
                type="date"
                placeholder="Elige una fecha"
                onChange={(e) => setFilters({ ...filters, date: e.target.value })}
                value={filters?.date}
              />
            </div>
          </div>
        </div>
        <div className="filter-modal__divider"></div>
        <div className="filter-modal__footer-container">
          <Button
            color="dark"
            className="mr-5 filter-modal__apply-btn bg-black"
            onClick={() => applyFilters()}
          >
            Aplicar
          </Button>
          <span
            className="filter-modal__clear-btn"
            onClick={() => clearFilters()}
          >
            Limpiar filtros
          </span>
        </div>
      </div>
    </React.StrictMode>
  );
};

export default FilterModal;

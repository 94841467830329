export const ZplTemplate = `
^XA

^FO10,22^GFA,2117,2117,29,,{{logoCliente}}^FS

^FO460,22^GFA,2117,2117,29,,{{logoApp}}^FS

^CI28
^LH5,15

^FO40,150^A0N,32,32^FH^FD{{emitter.name}}^FS
^FO40,195^A0N,24,24^FB660,2,0,L^FH^FD{{address}}^FS
^FO40,230^A0N,24,24^FB660,2,0,L^FH^FD{{address2}}^FS
^FO40,265^A0N,24,24^FDVenta: {{order_id}}^FS
^FO500,160^A0N,24,24^FH^FDEnvio: {{followingNumber}}^FS

^FX 1 Horizontal Line ^FS
^FO0,330^GB850,0,2^FS

^FO20,355^A0N,48,48^FB800,1,0,C^FR^FH^FDEnvio Sameday^FS
^FX 2 Horizontal Line ^FS
^FO0,415^GB850,0,2^FS

^FO135,450^A0N,32,32^FB370,1,0,C^FDEntrega:^FS
^FO265,450^A0N,32,32^FB465,1,0,C^FD{{fecha}}^FS
^FO250,451^A0N,32,32^FB420,1,0,C^FD^FS

^FO0,500^GB850,0,2^FS

^FX QR Code ^FS
^FO265,550^CZ4,4,0^BQN,4,11^FDLA,{"id":"{{packageKey}}"}^FS

^FO209,870^A0N,52,52^FB400,2,0,C^FH^FD{{city}}^FS
^FO210,736^A0N,52,52^FB400,2,0,C^FH^FD^FS
^FO0,980^GB850,0,2^FS

^FO40,1000^A0N,28,28^FB710,2,0,L^FH^FDDestinatario: {{receiver.name}}^FS
^FO39,1000^A0N,28,28^FH^FDDestinatario:^FS

^FO40,1060^A0N,28,28^FB710,4,0,L^FH^FDDirección: {{receiver.address_line}}^FS
^FO39,1060^A0N,28,28^FH^FDDirección:^FS

^FO40,1120^A0N,28,28^FB720,2,0,L^FH^FDReferencia: {{receiver.comment}}^FS
^FO39,1120^A0N,28,28^FH^FDReferencia:^FS

^XZ
^XA^MCY^XZ
                           
`

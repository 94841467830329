import React, {useState} from 'react'
import {
    Breadcrumb,
    BreadcrumbItem, Button, ButtonGroup
  } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { iconList } from "../../utils/iconList";
import PickupsContent from './content';


const Title = () => {
  return(
    <h2 className="h2-custom-size all-orders-container__title d-flex justify-content-between">Retiros realizados</h2>
    )
}

const PickupsBreadcrumb = () =>{
  const navigate = useNavigate()

  return (
      <Breadcrumb className="custom-breadcrumb-container">
        <BreadcrumbItem
          className="d-flex align-items-center cursor-pointer"
          onClick={() => navigate("/admin")}
        >
          <img src={iconList.home} alt="home" className="mr-2" />
          <span>Inicio</span>
        </BreadcrumbItem>
        <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
          <span>Retiros</span>
        </BreadcrumbItem>
        <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
          <span>Retiros realizados</span>
        </BreadcrumbItem>
        
      </Breadcrumb>
  )
}


export default function index() {
  return (
     // se cambia componente section por div con estilos segun standar - ksandoval - 10-05
     <div className="w-100 all-orders-container mt-6">
      <PickupsBreadcrumb/>
      <Title/>
      <PickupsContent/>
    </div>
  )
}

import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { iconList } from '../../utils/iconList'
import useAuth from "../../hooks/useAuth";

export default function Error() {
  const [link, setLink] = useState('/home')
  const { user } = useAuth();

  useEffect(() => {
    if(user?.role === 'client') setLink('/home')
    if(user?.role === 'admin') setLink('/admin')
  }, [user])

  return (
    <Row>
      <Col xs="12" className="d-flex justify-content-center vh-100 align-items-center">
        <div className="text-center">
          <h4 className="error-message">
            Lo sentimos, pero parece que la página que estás buscando no existe.
          </h4>
          <img src={iconList["error404"]} alt="error404" />
          <br />
          <Link to={link}>
            <Button className="error-button">Volver al inicio</Button>
          </Link>
        </div>
      </Col>
    </Row>
  );
}

import React, { useState, useEffect } from "react";
import { iconList } from "../../utils/iconList";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Divider from "../divider";
import { useNavigate } from "react-router-dom";
import { useSelectedItem } from "./SelectedItemContext";
import useAuth from "../../hooks/useAuth";

export default function NewAdminMenu({ fetch }) {
  const { setSelectedItem } = useSelectedItem();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [initialSelection, setInitialSelection] = useState(true); // Estado para controlar la selección inicial
  const { user } = useAuth();

  const appList = [
    {
      title: "Envíos",
      subtitle: "Gestiona los envios y sus rutas de retiro.",
      className: "menu-item",
      value: "envios",
      // pathname: `{pathname === "/home"}`,
      // navigating: "/home",
      icon: "package_2",
    },
    {
      title: "Usuarios y flota",
      subtitle: "Administra el control de los usuarios y tu flota.",
      className: "menu-item",
      value: "usuarios_flota",
      // pathname: `{pathname.includes("/integrations")}`,
      // navigating: "/home/integrations",
      icon: "local_shipping",
    },
    {
      title: "Rutas",
      subtitle: "Crea y administra las rutas de reparto.",
      className: "menu-item",
      value: "rutas",
      // pathname: `{pathname.includes("/delivered-list")}`,
      // navigating: "/home/delivered-list",
      icon: "map",
    },
  ];




  useEffect(() => {
    // Seleccionar el primer elemento solo la primera vez
    if (initialSelection && appList.length > 0) {
      setSelectedItem("envios");
      setInitialSelection(false); // Cambiar el estado para que no se vuelva a seleccionar automáticamente
    }
  }, [initialSelection, appList, setSelectedItem]);


  const [selectedMenuItem, setSelectedMenuItem] = useState(appList[0]); // Seleccionar el primer elemento por defecto

  const handleMenuItemClick = (item) => {
    setSelectedMenuItem(item);
    setMenuVisible(false);
    setSelectedItem(item.value); // Enviar el valor al componente AdminMenu
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const [hoveredIcon, setHoveredIcon] = useState(null);
  //dvlprchris_02_05_2024
  const [selectedValue, setSelectedValue] = useState(null);

const renderAppList = () => {
    if (user?.role === "admin") {
      return appList.map((item, index) => (
        <React.Fragment key={index}>
          <DropdownItem
            onClick={() => handleMenuItemClick(item)}
            onMouseEnter={() => setHoveredIcon(item.icon)}
            onMouseLeave={() => setHoveredIcon(null)}
          >
            <div className="flex items-center gap-1 px-2">
              <span
                className="material-symbols-outlined"
                style={{
                  color: hoveredIcon === item.icon ? "#F8612C" : "#000",
                  transition: "color 0.1s",
                  fontSize: "2rem",
                }}
              >
                {item.icon}
              </span>
              <div>
                <div className="font-bold">{item.title}</div>
                <div className="text-sm">{item.subtitle}</div>
              </div>
            </div>
          </DropdownItem>
          {index < appList.length - 1 && <DropdownItem divider />}
        </React.Fragment>
      ));
    } else if (user?.role === "client") {
      return (
        <DropdownItem onClick={() => handleMenuItemClick(appList[0])}>
          <div className="flex items-center gap-1 px-2">
            <span
              className="material-symbols-outlined"
              style={{
                color: hoveredIcon === appList[0].icon ? "#F8612C" : "#000",
                transition: "color 0.1s",
                fontSize: "2rem",
              }}
            >
              {appList[0].icon}
            </span>
            <div>
              <div className="font-bold">{appList[0].title}</div>
              <div className="text-sm">{appList[0].subtitle}</div>
            </div>
          </div>
        </DropdownItem>
      );
    }
  };

  return (
    <div>
      <Navbar color="none" light expand="md" className="borderClass">
        <NavbarBrand href="/">
          {selectedMenuItem && (
            <span style={{ display: "flex", alignItems: "center" }}>
              <span
                className="material-symbols-outlined"
                style={{
                  paddingLeft: "1rem",
                  marginRight: "5px",
                  fontSize: "2rem",
                  color: "#F8612C",
                }}
              >
                {selectedMenuItem.icon}
              </span>
              <span
                style={{ fontSize: "2rem" }}
                className="text-black font-bold"
              >
                {selectedMenuItem.title}
              </span>
            </span>
          )}
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />

        {/* contenido de la derecha */}
        {/* app + logo */}
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>
                <span
                  className="material-symbols-outlined"
                  style={{
                    border: "none",
                    marginRight: "0",
                    fontSize: "2rem",
                    color: "black",
                    backgroundColor: "transparent !important",
                  }}
                >
                  apps
                </span>
              </DropdownToggle>

              <DropdownMenu
                end
                style={{
                  marginTop: "-10px",
                  width: "26rem",
                  minWidth: "200px",
                  marginLeft: "-375px",
                }}
              >
                <div className="flex items-center my-2 mx-2">
                  <h1 className="text-xl font-bold text-center">
                    Aplicaciones
                  </h1>
                </div>
                <DropdownItem divider />
                {renderAppList()}
                {/* {appList.map((item, index) => (
                  <React.Fragment key={index}>
                    <DropdownItem
                      onClick={() => {
                        handleMenuItemClick(item);
                        // navigate(item.navigating);
                      }}
                      onMouseEnter={() => setHoveredIcon(item.icon)}
                      onMouseLeave={() => setHoveredIcon(null)}
                    >
                      <div className="flex items-center gap-1 px-2">
                        <span
                          className="material-symbols-outlined"
                          style={{
                            color:
                              hoveredIcon === item.icon ? "#F8612C" : "#000",
                              transition: "color 0.1s",
                              fontSize: "2rem", // Tamaño de fuente más grande para el icono
                          }}
                        >
                          {item.icon}
                        </span>
                        <div>
                          <div className="font-bold">{item.title}</div>
                          <div className="text-sm">{item.subtitle}</div>
                        </div>
                      </div>
                    </DropdownItem>
                    {index < appList.length - 1 && <DropdownItem divider />}
                  </React.Fragment>
                ))} */}
              </DropdownMenu>
            </UncontrolledDropdown>

            <NavItem>
              <NavLink>
                <img
                  src={iconList.logoBeartrack}
                  alt="logo"
                  width="170"
                  height="50"
                  className=""
                />
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

//Layouts
import MainLayout from "../layouts/mainLayout";
import LoginLayout from "../layouts/loginLayout";

//Pages
import LoginView from '../pages/login'
import ForgotPassword from '../pages/login/recoveryPassword'
import SetNewPassword from '../pages/login/setNewPassword'
import DeliveredView from '../pages/delivered'
import Error from '../pages/error'
import AllOrders from '../pages/allOrders'
import Resume from '../pages/resume'
import Users from '../pages/users'
import Integrations from '../pages/integrations'
import Faqs from '../pages/faqs'
import AdminFaqs from '../pages/adminFaqs'
import Informs from '../pages/informs'
import LockUsers from '../pages/bloquedUsers'
import MyAcountClient from '../pages/myAcountClient'
import ClientInform from '../pages/clientInform'
import Routes from '../pages/routes'
import Pickups from '../pages/pickups'

// Dvlprchris
import CreateRoute from '../pages/CreateRoute'
import AdminFleet from '../pages/AdminFleet'
import AdminRoute from '../pages/AdminRoute'
import AdminWithdrawals from "../pages/adminWithdrawals";


//Routes

const routes = [
  {
    path: "/home/*",
    element: MainLayout,
    exact: false,
    children: [
      {
        path: "/",
        exact: true,
        element: AllOrders
      },
      {
        path: "/delivered-list",
        exact: true,
        element: DeliveredView
      },
      {
        path: "/integrations",
        exact: true,
        element: Integrations
      },
      {
        path: "/faqs",
        exact: true,
        element: Faqs
      },
      {
        path: "/my-account",
        exact: true,
        element: MyAcountClient
      },
      {
        path: "/my-report",
        exact: true,
        element: ClientInform
      },
      {
        path: "*",
        exact: true,
        element: Error
      }
    ]
  },
  {
    path: "/admin/*",
    element: MainLayout,
    exact: false,
    adminOnly: true,
    children: [
      {
        path: "/",
        exact: true,
        element: Resume
      },
      {
        path: "/inform",
        exact: true,
        element: Informs
      },
      {
        path: "/users",
        exact: true,
        element: Users
      },
      {
        path: "/lock",
        exact: true,
        element: LockUsers
      },
      {
        path: "/createRoute",
        exact: true,
        element: CreateRoute
      },
      {
        path: "/adminFleet",
        exact: true,
        element: AdminFleet
      },
      {
        path: "/adminRoute",
        exact: true,
        element: AdminRoute
      },
      {
        path: "/adminWithdrawals",
        exact: true,
        element: AdminWithdrawals
      },
      {
        path: "/routes",
        exact: true,
        element: Routes
      },
      {
        path: "/pickups",
        exact: true,
        element: Pickups
      },
      {
        path: "/faqs",
        exact: true,
        element: AdminFaqs
      },
    ]
  },
  {
    path: "/login/*",
    element: LoginLayout,
    exact: false,
    children: [
      {
        path: "/",
        exact: true,
        element: LoginView,
      },
      {
        path: "/recovery",
        exact: true,
        element: ForgotPassword,
      },
      {
        path: "/recovery/:token",
        exact: true,
        element: SetNewPassword,
      },
    ],
  },
  {
    path: "*",
    element: LoginLayout,
    exact: false,
    children: [
      {
        path: "*",
        exact: true,
        element: Error,
      },
    ],
  },
]

export default routes;
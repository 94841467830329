import React, { useCallback, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Form, Button } from "reactstrap";
import { iconList } from "../../utils/iconList";
import SearchDropdown from "../searchDropdown";
import ErrorMessage from "../errorMessage";
import { getDeliveryUsers } from "../../api/user";
import { updatePackage } from "../../api/packages";
import { showToast } from "../../utils/showToast";

export default function ReasignModal({ data, closeModal, updateData }) {
  const methods = useForm({ mode: "onSubmit" });
  const [deliveryList, setDeliveryList] = useState([]);

  useEffect(() => {
    getDeliveryUsers().then((result) => {
      const newArr = result?.data?.deliveryList?.map((del) => {
        return { label: `${del?.name} ${del?.lastName}`, value: del?._id };
      });
      setDeliveryList(newArr);
    });
  }, []);

  const submitValues = useCallback(
    async (values) => {
      const packageUpdated = await updatePackage({
        id: data?._id,
        values: values,
      });

      if (packageUpdated?.error) {
        showToast("Error al actualizar el paquete", false);
      }

      if (packageUpdated?.data) {
        showToast("Paquete actualizado correctamente.", true);
        closeModal(false);
        updateData(true);
      }
    },
    [data, closeModal, updateData]
  );

  return (
    <div className="p-4">
      <div className="d-flex align-items-center">
        <span className="custom-asign-icon-container d-flex justify-content-center align-items-center">
          <img src={iconList.account_circle} alt="account_circle" />
        </span>
        <span className="ml-2 custom-asign-title">Reasignar repartidor</span>
      </div>
      <div className="custom-asign-divider" />
      <Form className="mt-3" onSubmit={methods.handleSubmit(submitValues)}>
        <Controller
          name="assignTo"
          control={methods.control}
          render={({ field }) => (
            <SearchDropdown
              {...field}
              options={deliveryList}
              selected={
                deliveryList?.find(
                  (f) => f?.value === methods?.getValues("assignTo")
                )?.label
              }
              placeholder="Selecciona al repartidor"
              onChange={field.onChange}
            />
          )}
          rules={{ required: "Seleccione al repartidor" }}
        />
        {methods.formState.errors?.assignTo && (
          <ErrorMessage msg={methods.formState.errors?.assignTo?.message} />
        )}
        <Button block className="custom-asign-btn">
          Reasignar
        </Button>
      </Form>
    </div>
  );
}

import React from "react";
import { Col } from "reactstrap";

export default function FormsDivider({ message }) {
  return (
    <Col xs="12" md="12" xl="12">
      <span className="custom-form-subtitle">{message}</span>
      <span className="custom-form-divider"></span>
    </Col>
  );
}

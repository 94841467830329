import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default function ModalComponent({ header, body, footer, open, setOpen, centered, size, bodyClassName }) {
  return (
    <Modal isOpen={open} toggle={setOpen} size={size || "lg"} centered={centered}>
      {header && <ModalHeader>{header}</ModalHeader>}
      {body && <ModalBody className={bodyClassName}>{body}</ModalBody>}
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </Modal>
  );
}

import React, { useState } from "react";
import useModal from "../../store/modal";
import useRoutes from "../../store/routes";
import { toast } from "react-toastify";
import { deleteRoutesApi } from "../../api/routes";
import Assign from './modalModules/assign'
import PreferedHour from './modalModules/preferedHour'
import CreateCustomTable from './modalModules/createCustomTable'
import {FullInput} from '../inputs/index'

import { hourList, modalInfo } from "./constants";
import {addFalabella, addSendu, addWoocommerce, changeAliasApi, deleteIntegrationApi} from "../../api/user";

export const ActionButton = ({ text, primary, secondary, onClick }) => {
  return (
    <div
      style={{
        color: secondary,
        backgroundColor: primary,
        borderColor: secondary,
      }}
      onKeyUp={onClick}
      onClick={onClick}
      className="w-full my-2 py-1 rounded-sm text-center font-semibold border-2 cursor-pointer hover:scale-[1.02] hover:shadow-md transition"
    >
      {text}
    </div>
  );
};

const DeleteRoute = () => {
  const fetchRoutes = useRoutes((state) => state.fetchRoutes);
  const deselectAllRoutes = useRoutes((state) => state.deselectAllRoutes);
  const selectedRoutes = useRoutes((state) => state.selectedRoutes);
  const closeModal = useModal((state) => state.closeModal);

  const deleteSelectedRoutes = () => {
    return new Promise((resolve, reject) => {
      if (selectedRoutes.length === 0) {
        toast.error("No hay rutas seleccionadas");
        reject(false);
      }
      if (selectedRoutes.length === 1) {
        toast
          .promise(deleteRoutesApi(selectedRoutes[0]), {
            pending: "Eliminando ruta",
            success: "Ruta eliminada",
            error: "Error al eliminar ruta",
          })
          .then(() => {
            fetchRoutes().then(() => {
              deselectAllRoutes();
              resolve(true);
            });
          });
      } else {
        toast
          .promise(deleteRoutesApi(selectedRoutes), {
            pending: "Eliminando ruta",
            success: "Ruta eliminada",
            error: "Error al eliminar ruta",
          })
          .then(() => {
            fetchRoutes().then(() => {
              deselectAllRoutes();
              resolve(true);
            });
          });
      }
    });
  };

  return (
    <>
      <div className="text-sm">
        Esta acción eliminará la/s ruta/s. Todos los clientes que estaban
        asignados quedarán libres para asignar a una nueva ruta.
      </div>
      <ActionButton
        text={"Eliminar Ruta"}
        primary="#FFEFED"
        secondary="#E11900"
        onClick={() => {
          deleteSelectedRoutes().then(() => {
            closeModal();
          });
        }}
      />
    </>
  );
};

const SelectDates = () => {
  const closeModal = useModal((state) => state.closeModal);
  const { clientId, alias, integrationName, deleteIntegration } = useModal((state) => state.props);

  const handleClick = async () => {
    closeModal()
  };

  return (
    <div className="p-2">
      <div className="flex flex-col gap-2 ">
      <FullInput type="date" />
      <FullInput type="date" />
      </div>
      <ActionButton
        text={"Cambiar Fechas"}
        primary="#FFEFED"
        secondary="#E11900"
        onClick={handleClick}
      />
    </div>
  );
};

const DeleteIntegration = () => {
  const closeModal = useModal((state) => state.closeModal);
  const { clientId, alias, integrationName, deleteIntegration, handleModalClose } = useModal((state) => state.props);

  const handleClick = async () => {
    try {
      await toast.promise(deleteIntegrationApi({ clientId, alias, integrationName }), {
        pending: 'Eliminando...',
        success: '¡Integración eliminada correctamente!',
        error: 'Ha ocurrido un error al eliminar la integración.',
      });

      // deleteIntegration(alias, integrationName);

      handleModalClose();

      closeModal();
    } catch (error) {
      console.error(error);

      if (error?.response?.status === 404) {
        toast.error(error?.response?.data?.error || 'Usuario no encontrado');
      } else {
        toast.error('Ha ocurrido un error al eliminar la integración. Por favor, intenta nuevamente.');
      }
    }
  };

  return (
    <>
      <div className="text-sm">
        Esta acción eliminará la integración con el alias <b>{alias}</b> de {integrationName}, no es reversible.
      </div>
      <ActionButton
        text={"Eliminar Integración"}
        primary="#FFEFED"
        secondary="#E11900"
        onClick={handleClick}
      />
    </>
  );
};

const AddFalabella = ({ alias }) => {
  const closeModal = useModal((state) => state.closeModal);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { handleModalClose } = useModal((state) => state.props);

  const handleClick = async () => {
    // toast.success('Guardado correctamente.');
    setIsLoading(true);

    try {
      const falabella = await addFalabella({ sender_id: input, integrationName: 'Falabella' });

      // console.log(falabella);

      if(falabella?.error?.statusCode === 400) {
        toast.error('Código Falabella ya existe.');
      } else {
        toast.success('Código registrado correctamente')
        closeModal();
        handleModalClose();
        setIsLoading(false);
      }

    } catch (error) {
      console.error(error.response);
      setIsLoading(false);
      toast.error('Ha ocurrido un error al agregar el código de falabella.');
    }

  };

  return (
      <div>
        <input type="text"
               onChange={(e) => {
                 setInput(e.target.value)
               }} className="py-1 px-2 my-2 rounded-md text-lg border-[1.5px] border-gray-400 w-100"/>
        <ActionButton
            text={isLoading ? "Guardando..." : "Guardar"}
            primary="#FEF3D4"
            secondary="#FFC107"
            onClick={handleClick}
        />
      </div>
  );
};

const AddSendu = ({ alias }) => {
  const closeModal = useModal((state) => state.closeModal);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { handleModalClose } = useModal((state) => state.props);

  const handleClick = async () => {
    // toast.success('Guardado correctamente.');
    setIsLoading(true);

    try {
      const sendu = await addSendu({ sender_id: input, integrationName: 'Sendu' });

      // console.log(falabella);

      if(sendu?.error?.statusCode === 400) {
        toast.error('Código Sendu ya existe.');
      } else {
        toast.success('Código registrado correctamente')
        closeModal();
        handleModalClose();
        setIsLoading(false);
      }

    } catch (error) {
      console.error(error.response);
      setIsLoading(false);
      toast.error('Ha ocurrido un error al agregar el código de falabella.');
    }

  };

  return (
      <div>
        <input type="text"
               onChange={(e) => {
                 setInput(e.target.value)
               }} className="py-1 px-2 my-2 rounded-md text-lg border-[1.5px] border-gray-400 w-100"/>
        <ActionButton
            text={isLoading ? "Guardando..." : "Guardar"}
            primary="#FEF3D4"
            secondary="#FFC107"
            onClick={handleClick}
        />
      </div>
  );
};

const AddWoocommerce = ({ alias }) => {
  const closeModal = useModal((state) => state.closeModal);
  const [input, setInput] = useState('');
  const [inputSecret, setInputSecret] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { handleModalClose } = useModal((state) => state.props);
  const [errorMessage, setErrorMessage] = useState('');

  const handleClick = async () => {
    // toast.success('Guardado correctamente.');
    setIsLoading(true);

    if (!input.includes('https://')) {
      setErrorMessage('La URL debe comenzar con https://');
      return;
    }

    try {
      const woocommerce = await addWoocommerce({ url: input, secret: inputSecret, integrationName: 'WooCommerce' });

      if(woocommerce?.error?.statusCode === 400) {
        toast.error('URL Woocommerce ya existe.');
      } else {
        toast.success('URL Woocommerce registrado correctamente')
        closeModal();
        handleModalClose();
        setIsLoading(false);
      }

    } catch (error) {
      console.error(error.response);
      setIsLoading(false);
      toast.error('Ha ocurrido un error al agregar el código de falabella.');
    }

  };

  return (
      <div>
        <label>URL</label>
        <input type="text"
               onChange={(e) => {
                 setInput(e.target.value)
               }} className="py-1 px-2 my-2 rounded-md text-lg border-[1.5px] border-gray-400 w-100"/>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <label>SECRET</label>
        <input type="text"
               onChange={(e) => {
                 setInputSecret(e.target.value)
               }} className="py-1 px-2 my-2 rounded-md text-lg border-[1.5px] border-gray-400 w-100"/>
        <ActionButton
            text={isLoading ? "Guardando..." : "Guardar"}
            primary="#FEF3D4"
            secondary="#FFC107"
            onClick={handleClick}
        />
      </div>
  );
};
const ChangeAlias = () => {
  const closeModal = useModal((state) => state.closeModal);
  const {clientId, alias, integrationName, changeAlias} = useModal((state) => state.props);
  const [newAlias, setNewAlias] = useState(alias)

  const handleClick = async () => {
    if (newAlias == alias || newAlias == "") {
      toast.error("El alias no puede ser igual al anterior o estar vacío")
      return
    }
    try {
      await toast.promise(changeAliasApi({ clientId, alias, integrationName, newAlias }), {
        pending: 'Modificando...',
        success: '¡Alias modificado correctamente!',
        error: 'Ha ocurrido un error al modificar el Alias.',
      });

      changeAlias(alias, newAlias)
      closeModal();
    } catch (error) {
      console.error(error);

      if (error?.response?.status === 404) {
        toast.error(error?.response?.data?.error || 'Usuario no encontrado');
      } else {
        toast.error('Ha ocurrido un error al eliminar la integración. Por favor, intenta nuevamente.');
      }
    }
  };

  return (
    <>
      <div className="text-sm">
        Elija en nuevo alias para la integración con {integrationName}
      </div>
      <input type="text"
        value={newAlias}
        onChange={(e) => {
          setNewAlias(e.target.value)
        }} className="py-1 px-2 my-2 rounded-md text-lg border-[1.5px] border-gray-400" />
      <ActionButton
        text={"Modificar"}
        primary="#FEF3D4"
        secondary="#FFC107"
        onClick={handleClick}
      />
    </>
  );
};

export default function ModalProvider() {
  const name = useModal((state) => state.modalName);
  const closeModal = useModal((state) => state.closeModal);
  const props = useModal((state) => state.props);

  return (
    <>
      {name !== "" && (
        <section>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-lg">
              {/*content*/}
              <div className="border-0 px-4 py-3 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div
                  className="cursor-pointer hover:scale-105 transition w-5 h-5 text-center flex items-center justify-center absolute -top-2 -right-1 rounded-xl bg-[#CCCCCC] outline-none focus:outline-none"
                  onClick={closeModal}
                  onKeyUp={closeModal}
                >
                  <span className="font-bold -translate-y-[1px]">x</span>
                </div>
                <h3 className="text-xl py-1 font-semibold">
                  {modalInfo[name].title}
                </h3>
                <div className="w-full h-[1px] my-1 bg-slate-300" />

                <>
                  {(() => {
                    switch (name) {
                      case "deleteRoute":
                        return <DeleteRoute />;
                      case "deleteIntegration":
                        return <DeleteIntegration />;
                      case "changeAlias":
                        return <ChangeAlias />;
                      case "AddFalabella":
                        return <AddFalabella />;
                      case "AddSendu":
                        return <AddSendu />;
                      case "AddWoocommerce":
                        return <AddWoocommerce />;
                      case "preferedHour":
                        return <PreferedHour clientId={props.id} />;
                      case "assignTo":
                        return <Assign />;
                      case "createCustomTable":
                        return <CreateCustomTable />;
                      case 'selectDates':
                        return <SelectDates />;
                    }
                  })()}
                </>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </section>
      )}
    </>
  );
}

export const modalInfo = {
  deleteRoute: {
    title: "Eliminar ruta",
    icon: "",
  },
  AddFalabella: {
    title: "Agregar Código de Falabella",
    icon: "",
  },
  AddSendu: {
    title: "Agregar Código de Sendu",
    icon: "",
  },
  AddWoocommerce: {
    title: "Vincular Woocommerce",
    icon: "",
  },
  changeAlias: {
    title: "Modificar Alias",
    icon: "",
  },
  deleteIntegration: {
    title: "Eliminar integracion",
    icon: "",
  },
  assignTo: {
    title: "Asignar a repartidor",
    icon: "",
    color: {
      primary: "#F2F2F2",
      secondary: "#000000",
    },
  },
  createCustomTable: {
    title: "Crear tabla personalizada",
    icon: "",
    buttonText: "Crear"
  },

  preferedHour: {
    title: "Cambiar hora preferida",
    color: {
      primary: "#F2F2F2",
      secondary: "#000000",
    },
    icon: "",
    buttonText: "",
  },
  selectDates: {
    title: "Cambiar fechas",
    color: {
      primary: "#F2F2F2",
      secondary: "#000000",
    },
    icon: "",
    buttonText: "",
  },

};

export const hourList = [
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
]
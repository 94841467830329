import React, { useState } from "react";
import { FunctionButton } from "../../components/buttons/buttons";
import { FullInput } from "../../components/inputs";
import useRoutes from "../../store/routes";
import useModal from "../../store/modal";
import { useEffect } from "react";
import { toast } from "react-toastify";

//IMGS
import Expand from "../../assets/expand.svg";
import Unexpand from "../../assets/unexpand.svg";
import RouteIcon from "../../assets/route.svg";
import Trash from "../../assets/delete.svg";
import Handle from "../../assets/drag_handle.svg";
import Person from "../../assets/person.svg";
import { deleteClientFromRouteApi } from "../../api/routes";


const Controls = () => {
    const selectedRoutes = useRoutes(state => state.selectedRoutes)
    const openModal = useModal(state => state.openModal)

    return (
        <>
          <div className="flex justify-between mb-2">
            <div>
              <FunctionButton content="Seleccionar todos" onClick={() => {}} />
            </div>
            <div className="flex gap-1">

              <FunctionButton
                icon={RouteIcon}
                content="Asignar repartidor"
                onClick={() => {
                  selectedRoutes.length === 1 ? openModal("assignTo", {id: selectedRoutes[0]}) : toast.error("Seleccione una sola ruta.")
                }}
              />
              <FunctionButton
                icon={Trash}
                content="Eliminar ruta"
                onClick={() => {
                  selectedRoutes.length > 0 ? openModal("deleteRoute") : toast.error("Seleccione al menos una ruta.")
                }}
              />
            </div>
          </div>
          <div className="mb-2">
          <FullInput placeholder="Buscar por cliente, dirección o comuna" />
          </div>
        </>
      )
};

const InRouteClient = ({clientId, routeId, name, address}) => {
  const fetchRoutes = useRoutes(state => state.fetchRoutes)
    return (
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
        <img src={Handle} className="w-4 h-4" alt="" />
        <span className="uppercase text-sm">{name}</span>
        </div>
        <div className="flex items-center gap-2">
            <span className=" text-sm">{address}</span>
            <img src={Trash} onClick={() => {
              toast.promise(deleteClientFromRouteApi(routeId, clientId),
              {
                pending: "Eliminando",
                success: "Cliente eliminado de la ruta",
                error: "Error al eliminar cliente de la ruta"
                }).then(() => {
                  fetchRoutes()
                })
            }} onKeyDown className="brightness-0 hover:scale-110 cursor-pointer transition" alt="" />
        </div>
      </div>
        )
}

const Route = ({id, number, delivery, clients}) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const toggleRoute = useRoutes(state => state.toggleRoute)
  const selectedRoutes = useRoutes(state => state.selectedRoutes)

  return (
    <div className="py-3 px-3 border border-gray-400 rounded-md">
      <div className="flex justify-between items-center">
        <div className="flex gap-2">
          <input
            type="checkbox"
            onChange={(e) => {
              
              toggleRoute(id, e.target.checked)
            }}
            value={selectedRoutes.includes(id)}
            className="clickable-correlative checkbox-custom-style form-check-input "
          />
          <div className="text-lg font-semibold">RUTA {number}</div>
        </div>
        <img
          src={expanded ? Unexpand : Expand}
          alt=""
          className="w-5 h-5 cursor-pointer"
          onClick={toggleExpand}
          onKeyDown={toggleExpand}
        />
      </div>
      {expanded && <div className="pl-4 mt-2">
        {delivery && <span className="flex gap-1 mb-2 items-center">
            <img src={Person} className="w-4 h-4"alt="" />
            <span className="text-md font-bold">{`${delivery.name} ${delivery.lastName}`}</span>
        </span>}
        <div className="flex flex-col gap-1">
        {
            clients.map((client) => {
                return <>
                <InRouteClient clientId={client._id} routeId={id} name={`${client.name} ${client.lastName}`} address={client.address} />
                <div className="w-full my-[2px] h-[1px] bg-gray-300"/>
                </>
            })
        }
        </div>
      </div>}
    </div>
  );
};

const Content = () => {
    
  const routes = useRoutes(state => state.routes)
  const fetchRoutes = useRoutes(state => state.fetchRoutes)
    useEffect(() => {
      fetchRoutes()
    }, [])
    return(
        <div className="h-[485px] overflow-y-scroll flex flex-col gap-2">
{
    routes.map((route, index) => {
        return <Route id={route._id} number={index + 1} delivery={route.deliveryMan} clients={route.clients}/>
    })
}
            </div>
    )
};

export default function RoutesList() {
  return (
    <div className="w-full border rounded-xl mt-2 p-2 border-gray-400 h-[585px]">
      <Controls />
      <Content />
    </div>
  );
}

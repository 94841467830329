//dvlprChris
import Axios from "../config/axios-config";

const API_URL = `${process.env.REACT_APP_API_CONNECTION}/configuration/logo`;
const API_URL_BEARTRACK = `${process.env.REACT_APP_API_CONNECTION}/configuration/logo`;

export const getConfig = async () => {
  try {

    const response = await Axios.post(API_URL_BEARTRACK, {
      apikey: process.env.REACT_APP_API_KEY_LOGO,
    });

    return response.data;
  } catch (error) {
    console.error('Error al obtener la configuración:', error);
    throw error;
  }
};